import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import confirm from "../../shared/modal/confirm";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import Loader from "./../../shared/loader";
import {
  clearRedirect,
  deleteItem,
  fetchItem,
  ITEM_FRONEND_URL,
  updateItem,
  uploadImagesAndSaveItem
} from "./ducks/actions";
import Form from "./form";

function EditItem(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const itemId = props.itemId || props.match.params.itemId;

  const {
    fetchItem,
    updateItem,
    uploadImagesAndSaveItem,
    deleteItem,
    clearRedirect,
    loadingProp,
    item,
    errors,
    embedded
  } = props;

  const invalidIds = ["add", "overview"];

  useEffect(() => {
    if (invalidIds.indexOf(itemId) === -1) {
      fetchItem(filmId, itemId);
    }
  }, [filmId, itemId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      clearRedirect();
      const url = `/films/${filmId}/${ITEM_FRONEND_URL}/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "set-dressing") {
      props.clearRedirect();
      const url = `/films/${filmId}/set-dressings/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "costume") {
      props.clearRedirect();
      const url = `/films/${filmId}/costumes/${props.redirect.id}/edit`;
      return <Redirect to={url} />;
    }
  }

  if (invalidIds.indexOf(itemId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderProp(prop) {
    return (
      <ScrollIntoView
        className="blade-content wide"
        path={`/films/:filmId/${ITEM_FRONEND_URL}/:itemId/edit`}
        loading={loadingProp}>
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          propId={prop.id}
          initialValues={prop}
          loading={loadingProp}
          onSubmit={onUpdateItem}
          handleDelete={onDeleteItem}
          reduxFormValues={props.reduxFormValues}
          acquisitionMethodEnum={props.acquisitionMethodEnum}
          {...props}
        />
      </ScrollIntoView>
    );
  }

  function onUpdateItem(item) {
    const files = item.pictures || [];

    if ((files && files.length) > 0 || (item.images && item.images.length > 0)) {
      uploadImagesAndSaveItem(item, files, false, filmId);
    } else {
      updateItem(filmId, item);
    }
  }

  function onDeleteItem(item) {
    confirm(() => deleteItem(filmId, item), item?.name);
  }

  return item && !loadingProp ? renderProp(item) : renderLoading();
}

function mapStateToProps(state) {
  const { soundEffectsState, commonState } = state;
  return {
    item: soundEffectsState.item,
    errors: soundEffectsState.errors,
    redirect: soundEffectsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod
  };
}

export default connect(mapStateToProps, {
  fetchItem,
  updateItem,
  deleteItem,
  clearRedirect,
  uploadImagesAndSaveVisualEffect: uploadImagesAndSaveItem
})(EditItem);
