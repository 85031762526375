import getApiClient from "../../../../shared/api";

export const FETCH_SHOOTING_DAY_PROPS = "FETCH_SHOOTING_DAY_PROPS";

export function fetchShootingDayProps(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SHOOTING_DAY_PROPS,
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Props`
      )
    });
  };
}