import mapPictures from "../../../../shared/image-mapper";
import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { sortByName } from "../../../../shared/utility";
import {
  SEARCH_SCENES_FOR_MAKEUP
} from "../../../scenes/search/ducks/actions";
import { MAKEUP_SET_RETURN_URL } from "./actions";

interface ActionState {
  makeups: any[];
  loading: boolean;
  loadingSceneSettings: boolean;
  makeup: any;
  imageFiles: any[];
  readOnly: boolean;
  scenes: any[];
  onboardingSteps: OnboardingType[];
  findingInScript: boolean;
  searchingScenes: boolean;
  returnUrl: string | null;
}

export const OnboardingStep1ClassName = 'makeup-scenes-onboarding-step-1';
export const OnboardingStep2ClassName = 'makeup-scenes-onboarding-step-2';
export const OnboardingStep3ClassName = 'makeup-scenes-onboarding-step-3';
export const OnboardingStep4ClassName = 'makeup-scenes-onboarding-step-4';
export const OnboardingStep5ClassName = 'makeup-scenes-onboarding-step-5';

const defaultState: ActionState = {
  makeups: [],
  loading: true,
  loadingSceneSettings: false,
  makeup: {},
  imageFiles: [],
  readOnly: false,
  scenes: [],
  findingInScript: false,
  searchingScenes: false,
  returnUrl: null,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scene Search',
      content: `        
        Use this to quickly select the scenes that contain this makeup.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Script Search',
      content: `Or use this to search the script for the name of the makeup, and select any scenes that match. You can chose which parts of the script to select.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Select',
      content: `Or just select or unselect all scenes.`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Manual',
      content: `<p>If any scenes have not been selected using any of the above methods, then you can manually select or unselect a scene.</p>
                <p>This list only includes scenes which the selected character or background characters are in.</p>`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Save',
      content: `Once you have the desired scenes selected, don't forget to click Save.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_MAKEUPS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "CLEAR_MAKEUP": {
      return {
        ...state,
        loading: false,
        makeup: null,
        errors: null
      };
    }

    case "MAKEUP_SET_NAME": {
      const makeup = { name: action.meta.name, nameInScript: action.meta.name }
      return {
        ...state,
        makeup: makeup
      };
    }

    case "FETCH_NEXT_MAKEUP_NUMBER": {
      let nextNumber = 1;
      if (state.makeups && state.makeups.length > 0) {
        const maxNumber = Math.max.apply(
          null,
          state.makeups.map((makeup) => {
            return makeup.number;
          })
        );
        nextNumber = maxNumber + 1;
      }
      return {
        ...state,
        makeup: { number: nextNumber }
      };
    }

    case "FETCH_MAKEUPS_FULFILLED": {
      return {
        ...state,
        makeups: action.payload.data.makeups,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_MAKEUP_PENDING": {
      return {
        ...state,
        makeup: null,
        loadingMakeup: true
      };
    }

    case "FETCH_SINGLE_MAKEUP_FULFILLED": {
      let makeup = action.payload.data;

      mapPictures(makeup);

      return {
        ...state,
        makeup,
        loadingMakeup: false,
        errors: null
      };
    }

    case "UPDATE_SINGLE_MAKEUP_PENDING": {
      return {
        ...state,
        makeup: action.payload,
        loadingMakeup: true,
        errors: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingMakeup: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingMakeup: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingMakeup: false
      };
    }

    case "UPDATE_SINGLE_MAKEUP_FULFILLED": {
      const makeup = {
        ...action.meta.makeup,
        ...action.payload.data.makeup
      };
      const makeups: any = state.makeups.map((c: any, index: number) => {
        if (c.id === makeup.id) {
          return { ...c, ...makeup };
        } else {
          return c;
        }
      });
      sortByName(makeups);
      return {
        ...state,
        makeup,
        makeups,
        loadingMakeup: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_MAKEUP_REJECTED": {
      return {
        ...state,
        makeup: action.meta.makeup,
        errors: action.payload.response.data.errors,
        loadingMakeup: false
      };
    }

    case "ADD_SINGLE_MAKEUP_PENDING": {
      return {
        ...state,
        errors: null,
        loadingMakeup: true
      };
    }

    case "ADD_SINGLE_MAKEUP_FULFILLED": {
      let makeup: any = action.payload.data.makeup;
      let makeups = [makeup, ...state.makeups];
      sortByName(makeups);
      return {
        ...state,
        makeup,
        makeups,
        loadingMakeup: false,
        errors: null,
        redirect: { to: "scenes", id: makeup.id }
      };
    }

    case "ADD_SINGLE_MAKEUP_REJECTED": {
      return {
        ...state,
        makeup: action.meta.makeup,
        errors: action.payload.response.data.errors,
        loadingMakeup: false
      };
    }

    case "DELETE_SINGLE_MAKEUP_PENDING": {
      return {
        ...state,
        makeup: action.payload,
        loadingMakeup: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_MAKEUP_FULFILLED": {
      const makeup = action.meta.makeup;
      const makeups: any = state.makeups.filter((c: any, index: number) => {
        return c.id !== makeup.id;
      });

      return {
        ...state,
        makeup: null,
        makeups,
        loadingMakeup: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_MAKEUP_REJECTED": {
      return {
        ...state,
        makeup: action.meta.makeup,
        errors: action.payload.response.data.errors,
        loadingMakeup: false
      };
    }

    case "ADD_MAKEUP_IMAGE_FILES": {
      let mainImageUrl = state.makeup.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.imageFiles[0].name;
      }

      return {
        ...state,
        imageFiles: [...state.imageFiles, ...action.meta.imageFiles],
        makeup: { ...state.makeup, mainImageUrl: mainImageUrl }
      };
    }

    case "ADD_MAKEUP_IMAGE_URL": {
      let makeup: any = { ...state.makeup };
      let mainImageUrl = state.makeup.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.url;
      }

      if (makeup?.imageUrls) {
        makeup = {
          ...makeup,
          imageUrls: [...makeup?.imageUrls, action.meta.url],
          mainImageUrl: mainImageUrl
        };
      } else {
        makeup = {
          ...makeup,
          imageUrls: [action.meta.url],
          mainImageUrl: mainImageUrl
        };
      }
      return {
        ...state,
        makeup: makeup
      };
    }

    case "DELETE_MAKEUP_IMAGE_URL": {
      let imageUrls: any = state.makeup.imageUrls;
      imageUrls = imageUrls.filter((item) => {
        return item !== action.meta.url;
      });
      return {
        ...state,
        makeup: { ...state.makeup, imageUrls: imageUrls }
      };
    }

    case "DELETE_MAKEUP_IMAGE_FILE": {
      let imageFiles = state.imageFiles.filter((file) => {
        return file.name !== action.meta.fileName;
      });
      return {
        ...state,
        imageFiles: imageFiles
      };
    }

    case "MAKEUP_MAIN_IMAGE_CHANGED": {
      return {
        ...state,
        makeup: { ...state.makeup, mainImageUrl: action.meta.url }
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        makeup: null,
        redirect: null
      };
    }

    case "FETCH_ALL_SCENES_FOR_MAKEUPS_PENDING": {
      return {
        ...state,
        loadingScenes: true
      };
    }

    case "FETCH_ALL_SCENES_FOR_MAKEUPS_FULFILLED": {
      const scenes = action.payload.data.scenes.map((scene) => {
        return { ...scene, name: `${scene.number}. ${scene.settingName}` };
      });
      return {
        ...state,
        scenes,
        loadingScenes: false
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_MAKEUPS_PENDING": {
      return {
        ...state,
        loadingSceneSettings: true
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_MAKEUPS_FULFILLED": {
      return {
        ...state,
        selectedSceneIds: action.payload.data.sceneIds,
        loadingSceneSettings: false,
        errors: null
      };
    }

    case "SET_SCENE_ASSIGNED_TO_MAKEUP": {
      return {
        ...state,
        selectedSceneIds: [action.meta.sceneId]
      };
    }

    case "ASSIGN_SCENES_TO_MAKEUP_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        redirect: { to: state.returnUrl || "list" }
      };
    }

    case "FETCH_ALL_CHARACTERS_FOR_MAKEUPS_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        loading: false,
        errors: null
      };
    }

    case "FETCH_ALL_CHARACTERS_FOR_MAKEUPS_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "FETCH_ALL_BACKGROUND_CHARACTERS_FOR_MAKEUPS_FULFILLED": {
      return {
        ...state,
        backgroundCharacters: action.payload.data.backgroundCharacters,
        loading: false,
        errors: null
      };
    }

    case "FETCH_ALL_BACKGROUND_CHARACTERS_FOR_MAKEUPS_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "FETCH_ALL_SCENE_SETTINGS_FOR_MAKEUPS_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case SEARCH_SCENES_FOR_MAKEUP + "_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case SEARCH_SCENES_FOR_MAKEUP + "_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        searchingScenes: false,
      };
    }

    case SEARCH_SCENES_FOR_MAKEUP + "_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "MAKEUP_SEARCH_SCRIPT_PENDING": {
      return {
        ...state,
        findingInScript: true
      }
    }

    case "MAKEUP_SEARCH_SCRIPT_FULFILLED": {
      let searchedScenes = action.payload.data.scenes;
      const sceneIds = state.scenes.map(s => s.id);
      const filteredScenes = searchedScenes.filter(s => sceneIds.indexOf(s) > -1);
      return {
        ...state,
        searchedScenes: filteredScenes.map((id) => { return { id } }),
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        findingInScript: false
      };
    }

    case "MAKEUP_SEARCH_SCRIPT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
        findingInScript: false
      };
    }

    case "FETCH_SCRIPT_DAYS_FOR_MAKEUPS_FULFILLED": {
      return {
        ...state,
        scriptDays: action.payload.data.days,
        loading: false,
        errors: null
      };
    }

    case "CLEAR_SEARCH_SCENES_FOR_MAKEUPS": {
      return {
        ...state,
        searchedScenes: [],
        loading: false,
        errors: null
      };
    }

    case MAKEUP_SET_RETURN_URL: {
      return {
        ...state,
        returnUrl: action.meta.returnUrl
      };
    }

    default:
      return state;
  }
};

export default reducer;
