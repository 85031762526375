import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Moment from "react-moment";

import {
  SmartDaysOnboardingStep2ClassName,
} from "./shooting-order/ducks/reducer";
import SmallLoader from "../../shared/small-loader/small-loader";

const ShootingDaysList = ({ shootingdays, filmId, hasDays, loadingShootingDayList, canAccessMessages }) => {

  const location = useLocation();
  const path = location.pathname;
  var lastSlashLocation = path.lastIndexOf("/");
  const page = path.substring(lastSlashLocation);

  return (
    <aside className="menu">
      <p className="menu-label">Shooting Days</p>
      <ul className="menu-list">
        <NavLink
          to={`/films/${filmId}/shooting-days/shooting-order`}
          activeClassName="is-active"
          isActive={(match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/shooting-days/shooting-order`
            );
          }}
        >
          Shooting Order
        </NavLink>
        {!hasDays &&
          <NavLink
            to={`/films/${filmId}/shooting-days/import`}
            activeClassName="is-active"
            isActive={(match, location) => {
              return location.pathname.startsWith(
                `/films/${filmId}/shooting-days/import`
              );
            }}
          >
            Import
          </NavLink>}
        <NavLink
          to={`/films/${filmId}/shooting-days/settings`}
          activeClassName="is-active"
          className={SmartDaysOnboardingStep2ClassName}
          isActive={(match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/shooting-days/settings`
            );
          }}
        >
          Settings
        </NavLink>

        {hasDays &&
          <NavLink
            to={`/films/${filmId}/shooting-days/calendar`}
            activeClassName="is-active"
            isActive={(match, location) => {
              return location.pathname.startsWith(
                `/films/${filmId}/shooting-days/calendar`
              );
            }}
          >
            Calendar
          </NavLink>
        }

        {hasDays &&
          <NavLink
            to={`/films/${filmId}/shooting-days/day-out-of-days`}
            activeClassName="is-active"
            isActive={(match, location) => {
              return location.pathname.startsWith(
                `/films/${filmId}/shooting-days/day-out-of-days`
              );
            }}
          >
            Day out of Days
          </NavLink>
        }

        {canAccessMessages && <NavLink
          to={`/films/${filmId}/shooting-days/messages`}
          activeClassName="is-active"
          isActive={(match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/shooting-days/messages`
            );
          }}
        >
          Messages
        </NavLink>}

        {loadingShootingDayList && <li className="mt-1"><SmallLoader message="Loading days..." /></li>}
        {!loadingShootingDayList && hasDays && (shootingdays || []).map((shootingday, idx) => {
          var title;
          if (shootingday.date) {
            title = (<>
              {idx + 1}.&nbsp;&nbsp;

              <Moment format="Do MMMM YYYY">{shootingday.date}</Moment>
            </>);
          } else {
            title = "Day " + (idx + 1);
          }
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/shooting-days/${shootingday.id}${page}`}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/shooting-days/${shootingday.id}/`
                  );
                }}
              >
                {title}
              </NavLink>

            </li>
          );
        })}

      </ul>
    </aside>
  );
};

export default ShootingDaysList;
