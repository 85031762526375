import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { fetchItems } from "./../../generic-scene-item/ducks/actions";
import {
  addItemToScene,
  clearRedirect,
  ITEM_FRONEND_URL
} from "./ducks/actions";
import Form from "./form";
import Loader from "../../../shared/loader";

function AddSoundEffectToScene(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    fetchItems,
    addItemToScene,
    clearRedirect,
    errors,
    items,
    sceneItems,
    visualEffect,
    saving
  } = props;

  const [fromBreakdown, setFromBreakdown] = useState<boolean>(false);
  useEffect(() => {
    setFromBreakdown(props.location?.state?.fromBreakdown);
  }, [props.location?.state?.fromBreakdown]);

  useEffect(() => {
    fetchItems(filmId);
  }, []);

  if (props.redirect) {
    clearRedirect();
    if (props.redirect.to == "list") {
      const url = `/films/${filmId}/scenes/${sceneId}/${ITEM_FRONEND_URL}`;
      return <Redirect to={url} />;
    } else if (props.redirect.to == "breakdown") {
      const url = `/films/${filmId}/breakdown/scenes/${sceneId}`;
      return <Redirect to={url} />;
    }
  }

  function onAddItem(item) {
    sanitiseDataTypes(item);
    addItemToScene(filmId, sceneId, item, fromBreakdown);
  }

  function sanitiseDataTypes(item) {
  }

  const sceneItemsIds = sceneItems.map(p => p.id);
  const nonSceneItems = items.filter((item: any) => !sceneItemsIds.includes(item.id));
  const noItemsForFilm = items.length === 0;

  return (
    <ScrollIntoView
      className="blade-content"
      path={`/films/:filmId/scenes/:sceneId/${ITEM_FRONEND_URL}/add`}
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        characterId={null}
        initialValues={visualEffect}
        items={nonSceneItems}
        onSubmit={onAddItem}
        noItemsForFilm={noItemsForFilm}
      />
      {saving && <Loader />}
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { soundEffectState } = state.scenesState;
  return {
    sceneItems: soundEffectState.items,
    items: state.soundEffectsState.items,
    item: soundEffectState.item,
    errors: soundEffectState.errors,
    redirect: soundEffectState.redirect,
    saving: soundEffectState.saving
  };
}

export default connect(mapStateToProps, {
  addItemToScene,
  fetchItems,
  clearRedirect
})(AddSoundEffectToScene);
