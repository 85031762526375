import { Link } from "react-router-dom";

const UserTasks = (props) => {
    const { tasks } = props;
    return (<div className="mb-2">
        <h2 className="menu-label">Your Uncompleted Tasks</h2>
        <table className="table">
            <thead>
                <tr>
                    <th>Task</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {tasks?.map((task) => {
                    return (<tr>
                        <td><Link to="tasks">{task.title}</Link></td>
                        <td>{task.status}</td>
                    </tr>)
                })}
            </tbody>
        </table>
    </div>);
}

export default UserTasks;