import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../../shared/loader";
import InvlidShootingDayNotification from "../invalid-shooting-day-notification";
import { fetchShootingDayProps } from "./ducks/actions";
import EditPropsForm from "./edit-form";

function ListShootingDayCrewContainer(props) {
  const { filmId, shootingDayId } = props.match.params;
  const {
    fetchShootingDayProps,
    loading,
    shootingDayProps,
    errors,
    readOnly
  } = props;

  useEffect(() => {
    fetchShootingDayProps(filmId, shootingDayId);
  }, [filmId, shootingDayId]);

  function renderLoading() {
    return <Loader />;
  }

  const anyProps = shootingDayProps?.length > 0;

  function renderShootingDays(shootingDayProps, filmId) {
    return (
      <div className="blade-content wide">
        <p className="menu-label">Props</p>
        {!anyProps && <div>None required on this day</div>}
        {anyProps && <EditPropsForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ shootingDayProps }}
          loading={loading}
          readOnly={readOnly}
          {...props}
        />}
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(shootingDayProps, filmId);
}

function mapStateToProps(state) {
  const { rootState, propsState } = state.shootingdaysState;
  return {
    shootingDay: rootState.shootingday,
    readOnly: propsState.readOnly,
    shootingDayProps: propsState.props,
    loading: propsState.loading,
    errors: propsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayProps
})(ListShootingDayCrewContainer);
