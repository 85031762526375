import mapPictures from "../../../shared/image-mapper";
import OnboardingType from "../../../shared/onboarding/onboarding-type";
import { SEARCH_SCENES_FOR_ITEMS } from "../../scenes/search/ducks/actions";
import { sortByName } from "./../../../shared/utility";
import {
  ADD_SINGLE_ITEM,
  ASSIGN_SCENES_TO_ITEM,
  DELETE_SINGLE_ITEM,
  UPDATE_SINGLE_ITEM,
  ITEM_SET_RETURN_URL,
  CLEAR_ITEM,
  ITEM_SET_NAME,
  FETCH_ITEMS,
  FETCH_SINGLE_ITEM,
  ADD_ITEM_IMAGE_FILES,
  ADD_ITEM_IMAGE_URL,
  DELETE_ITEM_IMAGE_URL,
  DELETE_ITEM_IMAGE_FILE,
  ITEM_MAIN_IMAGE_CHANGED,
  CLEAR_ITEM_REDIRECT,
  FETCH_ALL_SCENES_FOR_ITEM,
  FETCH_SCENES_ASSIGNED_TO_ITEM,
  SET_SCENE_ASSIGNED_TO_ITEM,
  FETCH_ALL_CHARACTERS_FOR_ITEM,
  FETCH_ALL_SCENE_SETTINGS_FOR_ITEM,
  SEARCH_SCENES_FOR_ITEM,
  ITEM_SEARCH_SCRIPT,
  CLEAR_SEARCH_SCENES_FOR_ITEM
} from "./actions";

interface ActionState {
  items: any[];
  loading: boolean;
  loadingSceneSettings: boolean;
  item: any;
  imageFiles: any[];
  readOnly: boolean;
  scenes: any[];
  onboardingSteps: OnboardingType[];
  findingInScript: boolean;
  searchingScenes: boolean;
  enableSave: boolean;
  returnUrl: string | null;
}

export const OnboardingStep1ClassName = 'visual-effects-scenes-onboarding-step-1';
export const OnboardingStep2ClassName = 'visual-effects-scenes-onboarding-step-2';
export const OnboardingStep3ClassName = 'visual-effects-scenes-onboarding-step-3';
export const OnboardingStep4ClassName = 'visual-effects-scenes-onboarding-step-4';
export const OnboardingStep5ClassName = 'visual-effects-scenes-onboarding-step-5';

const defaultState: ActionState = {
  items: [],
  loading: true,
  loadingSceneSettings: false,
  item: {},
  imageFiles: [],
  readOnly: false,
  scenes: [],
  findingInScript: false,
  searchingScenes: false,
  enableSave: false,
  returnUrl: null,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scene Search',
      content: `        
        Use this to quickly select the scenes that match the selected criteria.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Script Search',
      content: `Or use this to search the script for the name of the visual effect, and select any scenes that match. You can chose which parts of the script to select.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Select',
      content: `Or just select or unselect all scenes.`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Manual',
      content: `If any scenes have not been selected using any of the above methods, then you can manually select or unselect a scene.`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Save',
      content: `Once you have the desired scenes selected, don't forget to click Save.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case CLEAR_ITEM: {
      return {
        ...state,
        loading: false,
        item: null,
        errors: null
      };
    }

    case ITEM_SET_NAME: {
      const item = { name: action.meta.name, nameInScript: action.meta.name, enableSave: true }
      return {
        ...state,
        item: item
      };
    }

    case FETCH_ITEMS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_ITEMS + "_FULFILLED": {
      return {
        ...state,
        items: action.payload.data.items,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_SINGLE_ITEM + "_PENDING": {
      return {
        ...state,
        item: null,
        loadingitem: true
      };
    }

    case FETCH_SINGLE_ITEM + "_FULFILLED": {
      let item = action.payload.data;

      mapPictures(item);

      return {
        ...state,
        item,
        loadingitem: false,
        errors: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingitem: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingitem: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingitem: false
      };
    }

    case UPDATE_SINGLE_ITEM + "_PENDING": {
      return {
        ...state,
        item: action.payload,
        loadingitem: true,
        errors: null
      };
    }

    case UPDATE_SINGLE_ITEM + "_FULFILLED": {
      const item = { ...action.meta.item, ...action.payload.data.item };
      const items: any = state.items.map((c: any, index: number) => {
        if (c.id === item.id) {
          return { ...c, ...item };
        } else {
          return c;
        }
      });
      sortByName(items);
      return {
        ...state,
        item,
        items,
        loadingitem: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case UPDATE_SINGLE_ITEM + "_REJECTED": {
      return {
        ...state,
        item: action.meta.item,
        errors: action.payload.response.data.errors,
        loadingitem: false
      };
    }

    case ADD_SINGLE_ITEM + "_PENDING": {
      return {
        ...state,
        errors: null,
        loadingitem: true
      };
    }

    case ADD_SINGLE_ITEM + "_FULFILLED": {
      const item: any = action.payload.data.item;

      let items = [item, ...state.items];
      sortByName(items);
      return {
        ...state,
        item,
        items,
        loadingitem: false,
        errors: null,
        redirect: { to: "scenes", id: item.id }
      };
    }

    case ADD_SINGLE_ITEM + "_REJECTED": {
      return {
        ...state,
        item: action.meta.item,
        errors: action.payload.response.data.errors,
        loadingitem: false
      };
    }

    case DELETE_SINGLE_ITEM + "_PENDING": {
      return {
        ...state,
        item: action.payload,
        loadingitem: true,
        errors: null
      };
    }

    case DELETE_SINGLE_ITEM + "_FULFILLED": {
      const item = action.meta.item;
      const items: any = state.items.filter((c: any, index: number) => {
        return c.id !== item.id;
      });

      return {
        ...state,
        item: null,
        items,
        loadingitem: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case DELETE_SINGLE_ITEM + "_REJECTED": {
      return {
        ...state,
        item: action.meta.item,
        errors: action.payload.response.data.errors,
        loadingitem: false
      };
    }

    case ADD_ITEM_IMAGE_FILES: {
      let mainImageUrl = state.item.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.imageFiles[0].name;
      }

      return {
        ...state,
        imageFiles: [...state.imageFiles, ...action.meta.imageFiles],
        item: { ...state.item, mainImageUrl: mainImageUrl }
      };
    }

    case ADD_ITEM_IMAGE_URL: {
      var item: any = { ...state.item };
      let mainImageUrl = state.item.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.url;
      }

      if (item?.imageUrls) {
        item = {
          ...item,
          imageUrls: [...item?.imageUrls, action.meta.url],
          mainImageUrl: mainImageUrl
        };
      } else {
        item = {
          ...item,
          imageUrls: [action.meta.url],
          mainImageUrl: mainImageUrl
        };
      }
      return {
        ...state,
        item: item
      };
    }

    case DELETE_ITEM_IMAGE_URL: {
      let imageUrls: any = state.item.imageUrls;
      imageUrls = imageUrls.filter((item) => {
        return item !== action.meta.url;
      });
      return {
        ...state,
        item: { ...state.item, imageUrls: imageUrls }
      };
    }

    case DELETE_ITEM_IMAGE_FILE: {
      let imageFiles = state.imageFiles.filter((file) => {
        return file.name !== action.meta.fileName;
      });
      return {
        ...state,
        imageFiles: imageFiles
      };
    }

    case ITEM_MAIN_IMAGE_CHANGED: {
      return {
        ...state,
        item: { ...state.item, mainImageUrl: action.meta.url }
      };
    }

    case CLEAR_ITEM_REDIRECT: {
      return {
        ...state,
        item: null,
        redirect: null
      };
    }

    case FETCH_ALL_SCENES_FOR_ITEM + "_FULFILLED": {
      const scenes = action.payload.data.scenes.map((scene) => {
        return { ...scene, name: `${scene.number}. ${scene.settingName}` };
      });
      return {
        ...state,
        scenes,
        loading: false,
        errors: null
      };
    }

    case FETCH_SCENES_ASSIGNED_TO_ITEM + "_PENDING": {
      return {
        ...state,
        loadingSceneSettings: true
      };
    }

    case FETCH_SCENES_ASSIGNED_TO_ITEM + "_FULFILLED": {
      return {
        ...state,
        selectedSceneIds: action.payload.data.sceneIds,
        readOnly: action.payload.data.readOnly,
        loadingSceneSettings: false,
        loading: false,
        errors: null
      };
    }

    case SET_SCENE_ASSIGNED_TO_ITEM: {
      return {
        ...state,
        selectedSceneIds: [action.meta.sceneId]
      };
    }

    case ASSIGN_SCENES_TO_ITEM + "_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        redirect: { to: state.returnUrl || "list" }
      };
    }

    case FETCH_ALL_CHARACTERS_FOR_ITEM + "_FULFILLED": {
      return {
        ...state,
        characters: action.payload.data.characters,
        loading: false,
        errors: null
      };
    }

    case FETCH_ALL_SCENE_SETTINGS_FOR_ITEM + "_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case SEARCH_SCENES_FOR_ITEM + "_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case SEARCH_SCENES_FOR_ITEM + "_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        searchingScenes: false,
      };
    }

    case SEARCH_SCENES_FOR_ITEM + "_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case ITEM_SEARCH_SCRIPT + "_PENDING": {
      return {
        ...state,
        findingInScript: true
      }
    }

    case SEARCH_SCENES_FOR_ITEMS + "_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case SEARCH_SCENES_FOR_ITEMS + "_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        searchingScenes: false,
      };
    }

    case SEARCH_SCENES_FOR_ITEMS + "_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case ITEM_SEARCH_SCRIPT + "_FULFILLED": {
      let searchedScenes = action.payload.data.scenes;
      const sceneIds = state.scenes.map(s => s.id);
      const filteredScenes = searchedScenes.filter(s => sceneIds.indexOf(s) > -1);
      return {
        ...state,
        searchedScenes: filteredScenes.map((id) => { return { id } }),
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        findingInScript: false
      };
    }

    case ITEM_SEARCH_SCRIPT + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
        findingInScript: false
      };
    }

    case CLEAR_SEARCH_SCENES_FOR_ITEM: {
      return {
        ...state,
        searchedScenes: [],
        loading: false,
        errors: null
      };
    }

    case ITEM_SET_RETURN_URL: {
      return {
        ...state,
        returnUrl: action.meta.returnUrl
      };
    }

    default:
      return state;
  }
};

export default reducer;
