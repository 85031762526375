import {
  FETCH_FEATURE_TOGGLES,
  FETCH_FEATURE_TOGGLE,
  UPDATE_FEATURE_TOGGLE,
  FETCH_USERS_SUMMARY
} from './actions';

interface ActionState {
  featureToggles: any[];
  featureToggle: any;
  users: any[];
  loading: boolean;
  changeLog: any;
  readOnly: boolean;
  redirect: any
}

const defaultState: ActionState = {
  featureToggles: [],
  featureToggle: [],
  users: [],
  loading: true,
  changeLog: {},
  readOnly: false,
  redirect: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_FEATURE_TOGGLES + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_FEATURE_TOGGLES + "_FULFILLED": {
      return {
        ...state,
        featureToggles: action.payload.data.featureToggles,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_FEATURE_TOGGLES + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_FEATURE_TOGGLE + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_FEATURE_TOGGLE + "_FULFILLED": {
      return {
        ...state,
        featureToggle: action.payload.data,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_USERS_SUMMARY + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_USERS_SUMMARY + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_USERS_SUMMARY + "_FULFILLED": {
      return {
        ...state,
        users: action.payload.data.users,
        loading: false,
        errors: null
      };
    }

    case UPDATE_FEATURE_TOGGLE + "_PENDING": {
      return {
        ...state,
        errors: null,
        loading: true
      };
    }

    case UPDATE_FEATURE_TOGGLE + "_FULFILLED": {

      //sortByReleaseDate(changeLogs);
      return {
        ...state,
        loading: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case UPDATE_FEATURE_TOGGLE + "_REJECTED": {
      return {
        ...state,
        featureToggle: action.meta.featureToggle,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "CLEAR_REDIRECT_CHANGE_LOG": {
      return {
        ...state,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export function sortByReleaseDate(list) {
  list.sort((a, b) => {
    return a.releaseDate - b.releaseDate;
  });
}


export default reducer;
