import { clearNotifications, showError, showInfo } from "../../../notifications/ducks/actions";
import getApiClient from "./../../../shared/api";

const url = "/film";

export const BREAKDOWN_CHANGE_ITEM = "BREAKDOWN_CHANGE_ITEM";
export const BREAKDOWN_CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER = "BREAKDOWN_CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER";
export const BREAKDOWN_CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER = "BREAKDOWN_CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER";
export const BREAKDOWN_CHANGE_PROP_TO_SET_DRESSING = "BREAKDOWN_CHANGE_PROP_TO_SET_DRESSING";
export const BREAKDOWN_CHANGE_PROP_TO_COSTUME = "BREAKDOWN_CHANGE_PROP_TO_COSTUME";
export const BREAKDOWN_CHANGE_SET_DRESSING_TO_PROP = "BREAKDOWN_SET_DRESSING_TO_PROP";
export const BREAKDOWN_CHANGE_COSTUME_TO_PROP = "BREAKDOWN_CHANGE_COSTUME_TO_PROP";
export const REMOVE_CHARACTER_FROM_SCENE = "REMOVE_CHARACTER_FROM_SCENE";
export const REMOVE_BACKGROUND_CHARACTER_FROM_SCENE = "REMOVE_BACKGROUND_CHARACTER_FROM_SCENE";
export const BREAKDOWN_REMOVE_PROP_FROM_SCENE = "BREAKDOWN_REMOVE_PROP_FROM_SCENE";
export const BREAKDOWN_REMOVE_SET_DRESSING_FROM_SCENE = "BREAKDOWN_REMOVE_SET_DRESSING_FROM_SCENE";
export const BREAKDOWN_REMOVE_COSTUME_FROM_SCENE = "BREAKDOWN_REMOVE_COSTUME_FROM_SCENE";
export const BREAKDOWN_REMOVE_MAKEUP_FROM_SCENE = "BREAKDOWN_REMOVE_MAKEUP_FROM_SCENE";
export const BREAKDOWN_REMOVE_VISUAL_EFFECT_FROM_SCENE = "BREAKDOWN_REMOVE_VISUAL_EFFECT_FROM_SCENE";
export const BREAKDOWN_REMOVE_SOUND_EFFECT_FROM_SCENE = "BREAKDOWN_REMOVE_SOUND_EFFECT_FROM_SCENE";

export function fetchScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_BREAKDOWN_SCENES",
      payload: getApiClient().get(`/film/${filmId}/scene`)
    });
  };
}

export function fetchBreakdown(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_FILM_BREAKDOWN",
      payload: getApiClient().get(`${url}/${id}/breakdown`),
    });
  };
}

export function fetchSceneBreakdown(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_FILM_SCENE_BREAKDOWN",
      payload: getApiClient().get(`${url}/${filmId}/breakdown/${sceneId}`),
    });
  };
}

export function downloadBreakdown(id, characterOrderBy) {
  return (dispatch) => {
    dispatch({
      type: "DOWNLOAD_SCENE_BREAKDOWN",
      payload: getApiClient().post(`${url}/${id}/breakdown/download`, { characterOrderBy }),
    });
  };
}

export function changeItem(item, destination) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_CHANGE_ITEM,
      meta: { item, destination },
    });
  };
}

export function changeCharacterToBackgroundCharacter(filmId, item) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER,
      payload: getApiClient().put(`/film/${filmId}/character/${item.id}/ChangeToBackgroundCharacter`, item),
      meta: { item }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Character ${item.name} to Background Character`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Character ${item.name} to Background Character`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changeBackgroundCharacterToCharacter(filmId, item) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER,
      payload: getApiClient().put(`/film/${filmId}/backgroundCharacter/${item.id}/ChangeToCharacter`, item),
      meta: { item }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Background Character ${item.name} to Character`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Background Character ${item.name} to Character`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changePropToSetDressing(filmId, item) {
  return (dispatch) => {
    const prop = item;
    dispatch({
      type: BREAKDOWN_CHANGE_PROP_TO_SET_DRESSING,
      payload: getApiClient().put(`/film/${filmId}/prop/${prop.id}/ChangeToSetDressing`, prop),
      meta: { prop }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Prop ${prop.name} to Set Dressing`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Prop ${prop.name} to Set Dressing`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changePropToCostume(filmId, item) {
  return (dispatch) => {
    const prop = item;
    dispatch({
      type: BREAKDOWN_CHANGE_PROP_TO_COSTUME,
      payload: getApiClient().put(`/film/${filmId}/prop/${prop.id}/ChangeToCostume`, prop),
      meta: { prop }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Prop ${prop.name} to Costume`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Prop ${prop.name} to Costume`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changeSetDressingToProp(filmId, item) {
  return (dispatch) => {
    const setDressing = item;
    dispatch({
      type: BREAKDOWN_CHANGE_SET_DRESSING_TO_PROP,
      payload: getApiClient().put(`/film/${filmId}/setDressing/${setDressing.id}/ChangeToProp`, setDressing),
      meta: { setDressing }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Set Dressing ${setDressing.name} to Prop`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Set Dressing ${setDressing.name} to Prop`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changeCostumeToProp(filmId, item) {
  return (dispatch) => {
    const costume = item;
    dispatch({
      type: BREAKDOWN_CHANGE_COSTUME_TO_PROP,
      payload: getApiClient().put(`/film/${filmId}/costume/${costume.id}/ChangeToProp`, costume),
      meta: { costume }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Costume ${costume.name} to Prop`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Costume ${costume.name} to Prop`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function removeCharacterFromScene(filmId, sceneId, characterId) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_CHARACTER_FROM_SCENE,
      payload: getApiClient().delete(`/film/${filmId}/Scene/${sceneId}/Character/${characterId}`),
      meta: { characterId }
    })
      .then((response) => {
        dispatch(showInfo(`Removed character from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error removing character from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function removeBackgroundCharacterFromScene(filmId, sceneId, characterId) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_BACKGROUND_CHARACTER_FROM_SCENE,
      payload: getApiClient().delete(`/film/${filmId}/Scene/${sceneId}/BackgroundCharacter/${characterId}`),
      meta: { characterId }
    })
      .then((response) => {
        dispatch(showInfo(`Removed background character from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error removing background character from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function removePropFromScene(filmId, sceneId, propId) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_REMOVE_PROP_FROM_SCENE,
      payload: getApiClient().delete(`/film/${filmId}/Prop/${propId}/DeleteFromScene/${sceneId}`),
      meta: { propId }
    })
      .then((response) => {
        dispatch(showInfo(`Removed prop from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error removing prop from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function removeSetDressingFromScene(filmId, sceneId, setDressingId) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_REMOVE_SET_DRESSING_FROM_SCENE,
      payload: getApiClient().delete(`/film/${filmId}/SetDressing/${setDressingId}/DeleteFromScene/${sceneId}`),
      meta: { setDressingId }
    })
      .then((response) => {
        dispatch(showInfo(`Removed set dressing from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error removing set dressing from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function removeCostumeFromScene(filmId, sceneId, costumeId) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_REMOVE_COSTUME_FROM_SCENE,
      payload: getApiClient().delete(`/film/${filmId}/Costume/${costumeId}/DeleteFromScene/${sceneId}`),
      meta: { costumeId }
    })
      .then((response) => {
        dispatch(showInfo(`Removed costume from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error removing costume from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function removeMakeupFromScene(filmId, sceneId, makeupId) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_REMOVE_MAKEUP_FROM_SCENE,
      payload: getApiClient().delete(`/film/${filmId}/Makeup/${makeupId}/DeleteFromScene/${sceneId}`),
      meta: { makeupId }
    })
      .then((response) => {
        dispatch(showInfo(`Removed makeup from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error removing makeup from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function removeVisualEffectFromScene(filmId, sceneId, visualEffectId) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_REMOVE_VISUAL_EFFECT_FROM_SCENE,
      payload: getApiClient().delete(`/film/${filmId}/VisualEffect/${visualEffectId}/DeleteFromScene/${sceneId}`),
      meta: { visualEffectId }
    })
      .then((response) => {
        dispatch(showInfo(`Removed visual effect from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error removing visual effect from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function removeSoundEffectFromScene(filmId, sceneId, soundEffectId) {
  return (dispatch) => {
    dispatch({
      type: BREAKDOWN_REMOVE_SOUND_EFFECT_FROM_SCENE,
      payload: getApiClient().delete(`/film/${filmId}/SoundEffect/${soundEffectId}/DeleteFromScene/${sceneId}`),
      meta: { soundEffectId }
    })
      .then((response) => {
        dispatch(showInfo(`Removed sound effect from scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error removing sound effect from scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearPdf() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_DOWNLOAD_SCENE_BREAKDOWN",
      meta: {},
    });
  };
}

export function orderCharactersByScenes() {
  return (dispatch) => {
    dispatch({
      type: "SCRIPT_BREAKDOWN_CHARACTERS_ORDERBY_SCENES"
    });
  };
}

export function orderCharactersByName() {
  return (dispatch) => {
    dispatch({
      type: "SCRIPT_BREAKDOWN_CHARACTERS_ORDERBY_NAME"
    });
  };
}

