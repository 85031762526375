import React from "react";
import { withRouter } from "react-router";
import NumberFormat from "react-number-format";
import "react-circular-progressbar/dist/styles.css";
import FilmalityCircularProgress from "../../shared/filmality-circular-progress";
import CharacterIcon from "../../films/icons/characters";
import BackgroundCharacterIcon from "../../films/icons/background-characters";
import SceneSettingIcon from "../../films/icons/scene-settings";
import ShootingDaysIcon from "../../films/icons/shooting-days";
import PropsIcon from "../../films/icons/props";
import SetDressingsIcon from "../../films/icons/set-dressings";
import CostumesIcon from "../../films/icons/costumes";
import BudgetIcon from "../../films/icons/budget";
import SceneIcon from "../../films/icons/scenes";
import CrewIcon from "../../films/icons/crew";
import ActorsIcon from "../../films/icons/actors";
import BackgroundActorsIcon from "../../films/icons/background-actors";
import './overview.scss';
import Moment from "react-moment";
import Progress from "../../shared/progress";
import formatBytes from "../../shared/formatBytes";
import CurrencyDisplay from "../../shared/currency-display";
import UserTasks from "./user-tasks";

function Overview(props) {
  const { overview, history } = props;
  const filmStorageUsedPercentage = Math.round((overview.filmStorageUsed / overview.storageAllowance) * 1000) / 10;
  const otherFilmStorageUsedPercentage = Math.round((overview.otherFilmStorageUsed / overview.storageAllowance) * 1000) / 10;

  const remainingStorage = overview.storageAllowance - overview.otherFilmStorageUsed - overview.filmStorageUsed;

  return (
    <>
      <div className="columns">
        <div className="column">
          <div className="film-overview-title">{overview.name}</div>
          {overview.hasScript && overview.showScript && <div>
            <div className="script-uploaded">
              <div>Script uploaded: <Moment date={overview.scriptUploaded} format="DD/MM/yyyy" /></div>
              <div>Script version: {overview.scriptVersion}</div>
              {overview.aiModelName && <div>AI Model: {overview.aiModelName}</div>}
            </div>

            <button className="button is-transparent" onClick={() => history.push('./script')}>View Script</button>
          </div>}
          {overview.showStorage && <div>
            <label className="label mt-1">Storage usage</label>
            <div className="storage-progress" style={{ background: `linear-gradient(to right, #947eed ${filmStorageUsedPercentage}%, #00ffce ${filmStorageUsedPercentage}%, #00ffce ${filmStorageUsedPercentage + otherFilmStorageUsedPercentage}%,#040f21 0)` }}></div>
            <div className="progess-percentage">
              <span className="this-film">This film: {formatBytes(overview.filmStorageUsed)}</span>
              <span className="other-films">Other films: {formatBytes(overview.otherFilmStorageUsed)}</span>
              <span className="total-storage">Remaining: {formatBytes(remainingStorage)}</span>
            </div>
          </div>}
        </div>
        <div className="column">
          {overview.showBudget &&
            <div className="box">

              <BudgetIcon className="menu-icon" />
              Budget
              <div className="box budget">
                <div>
                  <div className="current-budget">Current Budget</div>
                  <CurrencyDisplay
                    value={overview.budget}
                  />
                </div>
              </div>
              <button className="button is-transparent" onClick={() => history.push('./budget')}>View Budget</button>
            </div>
          }
        </div>
      </div>
      {overview.tasks?.length > 0 && <UserTasks tasks={overview.tasks} />}
      <div className="columns is-multiline">
        {overview.showScenes && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.completedScenesPercentage}
            title="Completed Scenes"
            icon={<SceneIcon className="menu-icon mr-0" />}
            buttonText={`${overview.completedScenesPercentage < 100 ? "Complete " : ""}Scenes`}
            buttonLink="./scenes"
            history={props.history}
          />
        </div>}

        {overview.showCharacters && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.completedCharactersPercentage}
            title="Completed Characters"
            icon={<CharacterIcon className="menu-icon mr-0" />}
            buttonText={`${overview.completedScenesPercentage < 100 ? "Complete " : ""}Characters`}
            buttonLink="./characters/overview"
            history={props.history}
          />
        </div>}
        {overview.showCharacters && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.charactersAssignedPercentage}
            title="Characters Assigned"
            icon={<CharacterIcon className="menu-icon mr-0" />}
            buttonText={`${overview.completedScenesPercentage < 100 ? "Assign " : ""}Characters`}
            buttonLink="./characters/overview"
            history={props.history}
          />
        </div>}
        {overview.showBackgroundCharacters && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.backgroundCharactersAssignedPercentage}
            title="Background Characters"
            icon={<BackgroundCharacterIcon className="menu-icon mr-0" />}
            buttonText="Background Characters"
            buttonLink="./background-characters/overview"
            history={props.history}
          />
        </div>}
        {overview.showActors && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.actorsSignedReleasePercentage}
            title="Actors Signed Release"
            icon={<ActorsIcon className="menu-icon mr-0" />}
            buttonText="Actors"
            buttonLink="./actors/overview"
            history={props.history}
          />
        </div>}
        {overview.showBackgroundActors && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.backgroundActorsSignedReleasePercentage}
            title="Background Actors Signed"
            icon={<BackgroundActorsIcon className="menu-icon mr-0" />}
            buttonText="Background Actors"
            buttonLink="./background-actors/overview"
            history={props.history}
          />
        </div>}
        {overview.showSceneSettings && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.sceneSettingsAssignedPercentage}
            title="Scene Settings Assigned"
            icon={<SceneSettingIcon className="menu-icon mr-0" />}
            buttonText={`${overview.completedScenesPercentage < 100 ? "Assign " : ""}Scene Settings`}
            buttonLink="./scene-settings/overview"
            history={props.history}
          />
        </div>}
        {overview.showProps && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.propsAcquiredPercentage}
            title="Props Acquired"
            icon={<PropsIcon className="menu-icon mr-0" />}
            buttonText="Props"
            buttonLink="./props/overview"
            history={props.history}
          />
        </div>}
        {overview.showSetDressings && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.setDressingsAcquiredPercentage}
            title="Set Dressings Acquired"
            icon={<SetDressingsIcon className="menu-icon mr-0" />}
            buttonText="Set Dressings"
            buttonLink="./set-dressings/overview"
            history={props.history}
          />
        </div>}
        {overview.showCostumes && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.costumesAcquiredPercentage}
            title="Costumes Acquired"
            icon={<CostumesIcon className="menu-icon mr-0" />}
            buttonText="Costumes"
            buttonLink="./costumes/overview"
            history={props.history}
          />
        </div>}

        {overview.showDaysShot && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.daysShotPercentage}
            title="Days shot"
            icon={<ShootingDaysIcon className="menu-icon mr-0" />}
            buttonText="Shooting Days"
            buttonLink="./shooting-days/calendar"
            history={props.history}
          />
        </div>}
        {overview.showCrewRoles && <div className="circular-progress overview">
          <FilmalityCircularProgress
            percentage={overview.crewRolesFilled}
            title="Crew Members"
            icon={<CrewIcon className="menu-icon mr-0" />}
            buttonText="Crew"
            buttonLink="./departments/overview"
            history={props.history}
          />
        </div>}
      </div>
    </>
  );
}

export default withRouter(Overview);
