import React from "react";
import { NavLink } from "react-router-dom";
import { ITEM_FRONEND_URL, NAME_PLURAL_FOR_UI } from "./ducks/actions";

const ItemsList = ({ filmId, items }) => {
  return (
    <aside className="menu">
      <p className="menu-label">{NAME_PLURAL_FOR_UI} in scene</p>
      <ul className="menu-list">
        {(items || []).map((visualEffect, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/${ITEM_FRONEND_URL}/${visualEffect.id}/edit`}
                activeClassName="is-active"
              >
                {visualEffect.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ItemsList;
