import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const ScenesList = ({ scenes, filmId }) => {
  const location = useLocation();
  const path = location.pathname;
  var lastSlashLocation = path.lastIndexOf("/");

  const page = path.endsWith("scenes") || path.endsWith("order") || path.endsWith("script-days") ? "/edit" : path.substring(lastSlashLocation);

  return (
    <aside className="menu">
      <p className="menu-label">Scenes</p>
      <ul className="menu-list">
        <NavLink
          to={`/films/${filmId}/scenes/script-days`}
          activeClassName="is-active"
          isActive={(match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/scenes/script-days`
            );
          }}
        >
          Script Days
        </NavLink>
        <NavLink
          to={`/films/${filmId}/scenes/order`}
          activeClassName="is-active"
          isActive={(match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/scenes/order`
            );
          }}
        >
          Order
        </NavLink>
        <hr />
        {(scenes || []).map((scene, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/scenes/${scene.id}${page}`}
                activeClassName="is-active"
                title={scene.number + ". " + scene.settingName}
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/scenes/${scene.id}/`
                  );
                }}
              >
                {scene.number}. {scene.settingName}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ScenesList;
