import Dropzone from "react-dropzone";

const ImportMovieMagic = ({ acceptedFileTypes, onImportMovieMagic, isUploading, readOnly }) => {

    return (
        <div>
            <label className="label mt-1">Movie Magic Schedule</label>
            Upload One Line Schedule or Shooting Schedule from Movie Magic.
            <div className="subtitle supported-formats mt-1">
                The following file formats are supported:
                <div className="tags script-formats">
                    {acceptedFileTypes.map((fileType: any, index: number) => <span key={index} className="tag">{fileType}</span>)}
                </div>
            </div>
            <Dropzone
                accept={acceptedFileTypes.join()}
                multiple={false}
                disabled={isUploading || readOnly}
                onDrop={(acceptedFiles, rejectedFiles) => {
                    if (acceptedFiles && acceptedFiles.length > 0) {
                        // setSaving(true);
                        const file = acceptedFiles[0];
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("filename", file.name);
                        onImportMovieMagic(formData);
                    }

                    if (rejectedFiles && rejectedFiles.length > 0) {
                        const fileName = rejectedFiles[0].file.name;
                        const lastDot = fileName.lastIndexOf(".");
                        const extention = fileName.substring(lastDot);
                        // setUploadError(
                        //   "File type " + extention + " is not supported"
                        // );
                    }
                }}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <section
                        className={`image-drop-area ${isDragActive ? "active" : ""}`}>
                        <div
                            {...getRootProps({ className: `image-drop-area-inner ${isDragActive ? "active" : ""}` })}>
                            <input {...getInputProps()} />
                            <button className={`button ${isUploading ? "is-loading" : ""}`} disabled={isUploading || readOnly}>Choose a file…</button>
                        </div>
                    </section>
                )}
            </Dropzone>
        </div>
    )

}

export default ImportMovieMagic;