import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchEquipmentOverview, downloadEquipmentOverview, clearPdf, pdfDownloaded } from "./ducks/actions";
import List from "./list";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import Errors from "../../../shared/errors";

function ListDepartmentsContainer(props) {
  const filmId = props.match.params.filmId;
  const { fetchEquipmentOverview,
    loading,
    equipments,
    errors,
    currencySymbol,
    downloadEquipmentOverview,
    clearPdf,
    pdfDownloaded,
    progress,
    download,
    filmName } = props;

  const [includeDailyRate, setIncludeDailyRate] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState<any>([]);

  useEffect(() => {
    fetchEquipmentOverview(filmId);
  }, [filmId, fetchEquipmentOverview]);

  const selectedCategoryIds = selectedCategories.map((category) => category.value);

  const onDownloadFile = () => {
    downloadEquipmentOverview(filmId, includeDailyRate, selectedCategoryIds);
  };

  const onChangeDailyRate = (value) => {
    clearPdf();
    setIncludeDailyRate(value);
  };

  const onSelectedCategories = (value) => {
    clearPdf();
    setSelectedCategories(value);
  };

  const categories = equipments.map((equipment) => { return { name: equipment.category, id: equipment.categoryId } });
  const uniqueCategories = [...new Set(categories.map((category) => JSON.stringify(category)))].map((e: any) => JSON.parse(e));
  const categoryOptions = uniqueCategories.map((category: any) => { return { value: category.id, label: category.name } });

  const equipmentsToDisplay = equipments.filter((equipment) => { return selectedCategoryIds.includes(equipment.categoryId) });

  const csvData = equipmentsToDisplay.map((equipment: any) => {
    return [equipment.category, equipment.name, includeDailyRate ? currencySymbol + equipment.dailyRate : null];
  });

  const totalDailyRate = equipmentsToDisplay.reduce((acc, equipment) => acc + equipment.dailyRate, 0);

  if (includeDailyRate) {
    csvData?.unshift(["Category", "Equipment", "Daily Rate"]);
    debugger;
    csvData.push(["", "Total", currencySymbol + totalDailyRate]);
  } else {
    csvData?.unshift(["Category", "Equipment"]);
  }

  useEffect(() => {
    if (categoryOptions.length) {
      setSelectedCategories(categoryOptions);
    }
  }, [categoryOptions?.length]);

  function renderLoading() {
    return <Loader />;
  }


  function renderEquipment(equipments, filmId) {
    return (
      <ScrollIntoView
        path="/films/:filmId/equipment/overview"
        loading={loading}>
        <Errors errors={errors} />
        <List equipments={equipmentsToDisplay}
          filmId={filmId}
          currencySymbol={currencySymbol}
          onDownloadFile={onDownloadFile}
          progress={progress}
          download={download}
          clearPdf={pdfDownloaded}
          includeDailyRate={includeDailyRate}
          onChangeDailyRate={onChangeDailyRate}
          selectedCategories={selectedCategories}
          setSelectedCategories={onSelectedCategories}
          categoryOptions={categoryOptions}
          csvData={csvData}
          filmName={filmName} />
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderEquipment(equipments, filmId);
}

function mapStateToProps(state) {
  const { equipmentOverviewState } = state;
  return {
    equipments: equipmentOverviewState.equipment,
    loading: equipmentOverviewState.loading,
    errors: equipmentOverviewState.errors,
    progress: equipmentOverviewState.progress,
    download: equipmentOverviewState.download,
    currencySymbol: state.filmsState?.userFilm?.currencySymbol,
    filmName: state.filmsState?.userFilm?.name
  };
}

export default connect(mapStateToProps, {
  fetchEquipmentOverview,
  downloadEquipmentOverview,
  clearPdf,
  pdfDownloaded
})(ListDepartmentsContainer);
