import React from "react";
import memoizeOne from "memoize-one";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Scene from "./scene";
import {
  OnboardingStep2ClassName,
  OnboardingStep3ClassName,
  OnboardingStep4ClassName
} from './ducks/reducer';
import FilmanizeCheckbox from "../../../shared/filmanize-checkbox";

const ScenesOrderList = ({
  scenes,
  filmId,
  updateSceneOrder,
  onUpdateScenesOrder,
  selectedSceneIds,
  draggingSceneId,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  unselectAll,
  readOnly,
  pristine,
  canAccessSceneSettings,
  allSceneNumbersAreInteger,
  renumber,
  setRenumber
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      // dropped outside the list
      return;
    }

    updateSceneOrder(
      result.source.index,
      result.destination.index,
      result.source.droppableId,
      result.destination.droppableId
    );
  };

  const onDragStart = (start) => {
    const id = start.draggableId;
    const selected = selectedSceneIds.find((sceneId) => sceneId === id);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }

    // setDraggingSceneId(start.draggableId);
  };

  const grid = 8;

  console.log("selectedSceneIds", selectedSceneIds);

  const getDayStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? "#947eed" : "#947EED",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  type SceneIdMap = {
    [sceneId: string]: true;
  };

  const getSelectedMap = memoizeOne((selectedSceneIds: string[]) =>
    selectedSceneIds.reduce(
      (previous: SceneIdMap, current: string): SceneIdMap => {
        previous[current] = true;
        return previous;
      },
      {}
    )
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" >
          {(provided, snapshot) => (

            <table
              className="table"
              {...provided.droppableProps}
              ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>Scene</th>
                  <th>Scene Setting</th>
                  <th>Int/Ext</th>
                  <th>Day/Night</th>
                </tr>
              </thead>

              {(scenes || []).map((scene, index) => {
                const selectionMap = getSelectedMap(
                  selectedSceneIds || []
                );
                const isSelected = selectionMap && scene
                  ? selectionMap[scene.sceneId]
                  : false;
                const isGhosting: boolean =
                  isSelected &&
                  Boolean(draggingSceneId) &&
                  draggingSceneId !== scene.id;
                return (
                  <Scene
                    filmId={filmId}
                    scene={scene}
                    index={index}
                    key={scene?.sceneId ?? index}
                    isSelected={isSelected}
                    isGhosting={isGhosting}
                    selectionCount={(selectedSceneIds || []).length}
                    toggleSelection={toggleSelection}
                    toggleSelectionInGroup={toggleSelectionInGroup}
                    multiSelectTo={multiSelectTo}
                    readOnly={readOnly}
                    canAccessSceneSettings={canAccessSceneSettings}></Scene>
                );
              })}
            </table>
          )}
        </Droppable>
      </DragDropContext>

      {!readOnly && <div className="mt-2 sticky-buttons">
        <div className={OnboardingStep2ClassName}>
          <FilmanizeCheckbox label="Renumber scenes" checked={renumber} onChange={setRenumber} disabled={!allSceneNumbersAreInteger} />
        </div>

        <div>
          <button
            type="submit"
            className="button mt-1"
            disabled={pristine}
            onClick={() => onUpdateScenesOrder(scenes)}>
            Save
          </button>
        </div>
      </div>}
    </>
  );
};

export default ScenesOrderList;
