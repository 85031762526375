import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import {
  fetchAllSceneSettings,
  addSetDressing,
  clearSetDressing,
  clearRedirect,
  uploadImagesAndSaveSetDressing,
  setNameAndSceneSetting,
  setSceneSetting,
  setReturnUrl
} from "./ducks/actions";
import Form from "./form";

function AddSetDressing(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const sceneId = props.sceneId || props.location?.state?.sceneId;
  const sceneSettingId = props.sceneSettingId || props.location?.state?.sceneSettingId;
  const returnUrl = props.location?.state?.returnUrl;
  const { addSetDressing,
    setDressing,
    errors,
    uploadImagesAndSaveSetDressing,
    setNameAndSceneSetting,
    name,
    embedded,
    clearSetDressing,
    setReturnUrl,
    setSceneSetting
  } = props;

  useEffect(() => {
    clearSetDressing();
    props.fetchAllSceneSettings(filmId);
  }, []);

  useEffect(() => {
    if (name && sceneSettingId) {
      setNameAndSceneSetting(name, sceneSettingId);
    }
  }, [name, sceneSettingId]);

  useEffect(() => {
    if (!name && sceneSettingId) {
      setSceneSetting(sceneSettingId);
    }
  }, [sceneSettingId]);

  useEffect(() => {
    setReturnUrl(returnUrl);
  }, [returnUrl]);

  if (!embedded && props.redirect) {
    props.clearRedirect();
    let url;
    if (props.redirect.to === "list") {
      url = `/films/${filmId}/set-dressings/`;
    } else if (props.redirect.to === "scenes") {
      url = `/films/${filmId}/set-dressings/${props.redirect.id}/scenes/`;
    }
    return <Redirect to={url} />;
  }

  function onAddSetDressing(setDressing) {
    setDressing.sceneId = sceneId;
    const files = setDressing.pictures || [];
    if (files && files.length > 0) {
      uploadImagesAndSaveSetDressing(setDressing, files, true, filmId);
    } else {
      if (!setDressing.imageUrls) {
        setDressing.imageUrls = [];
      }
      addSetDressing(filmId, setDressing);
    }
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/set-dressings/add"
      loading={false}>
      <Form
        errors={errors}
        propId={null}
        initialValues={setDressing}
        loading={false}
        onSubmit={onAddSetDressing}
        {...props}
        mode="add"
        acquisitionMethodEnum={props.acquisitionMethodEnum}
        reduxFormValues={props.reduxFormValues}
        allSceneSettings={props.allSceneSettings}
      />
    </ScrollIntoView>
  );
}

function mapStateToSetDressings(state) {
  const { setDressingsState, commonState } = state;

  return {
    setDressing: setDressingsState.setDressing,
    errors: setDressingsState.errors,
    redirect: setDressingsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod,
    allSceneSettings: setDressingsState.sceneSettings
  };
}

export default connect(mapStateToSetDressings, {
  addSetDressing,
  clearSetDressing,
  clearRedirect,
  uploadImagesAndSaveSetDressing,
  fetchAllSceneSettings,
  setNameAndSceneSetting,
  setSceneSetting,
  setReturnUrl
})(AddSetDressing);
