import React from "react";
import CurrencyInputContainer from "../../shared/currency-input-container";
import { Link } from "react-router-dom";
import CurrencyDisplay from "../../shared/currency-display";

function VisualEffect(visualEffects) {
  const {
    item,
    onChangeItemBuget,
    onChangeItemDays,
    readOnly,
    filmId,
    saving,
    url
  } = visualEffects;

  return (
    <tr>
      <td><Link to={`/films/${filmId}/${url}/${item.id}/edit`}>{item.name}</Link></td>
      <td className="budget-daily-rate-column">
        <CurrencyInputContainer
          value={
            item.hire ? item.dailyBudget : item.budget
          }
          onChange={(val) => onChangeItemBuget(item.id, val)}
          readOnly={readOnly}
          disabled={saving}
        />
      </td>
      <td className="has-text-right">
        <CurrencyDisplay
          value={item.total}
        />
      </td>
    </tr>
  );
}

export default VisualEffect;
