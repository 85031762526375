export default (props) =>
  // <svg xmlns="http://www.w3.org/2000/svg" width="17.086" height="29.205" viewBox="0 0 17.086 29.205" {...props}>

  //   <g id="Group_3942" data-name="Group 3942" transform="translate(0 0)" >
  //     <path id="Path_32893" data-name="Path 32893" d="M15.579.5c.237,0,.423.448.163.86Q13.16,5.442,10.577,9.523c-.052.084-.1.168-.188.3h.336c1.657,0,3.314,0,4.971,0,.256,0,.5.011.615.292a.6.6,0,0,1-.185.665Q8.815,19.46,1.505,28.139a1.828,1.828,0,0,1-.144.164.539.539,0,0,1-.625.112.472.472,0,0,1-.152-.627c.324-.781.653-1.56.979-2.34q2.04-4.864,4.079-9.73c.038-.089.073-.18.121-.3H5.432q-2.158,0-4.316,0c-.568,0-.759-.309-.508-.813Q4.029,7.762,7.456.921C7.536.761,7.664.5,7.819.5S15.342.5,15.579.5Z" transform="translate(0.116 0.116)" fill="none" stroke-miterlimit="10" stroke-width="1" />
  //   </g>
  // </svg>
  // <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" {...props}>

  //   <g id="Group_4003" data-name="Group 4003" transform="translate(0.88 0.88)">
  //     <g id="Group_4002" data-name="Group 4002">
  //       <path id="Path_32928" data-name="Path 32928" d="M8.114,19.878V4.7L17.737.5V16.282" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32929" data-name="Path 32929" d="M8.139,20.273a2.346,2.346,0,1,1-2.346-2.346A2.346,2.346,0,0,1,8.139,20.273Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32930" data-name="Path 32930" d="M17.749,16.206A2.347,2.347,0,1,1,15.4,13.86,2.346,2.346,0,0,1,17.749,16.206Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <line id="Line_760" data-name="Line 760" y1="4.317" x2="9.299" transform="translate(8.152 4.735)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32931" data-name="Path 32931" d="M3.094,14.673A8.086,8.086,0,0,1,.5,8.655,8.088,8.088,0,0,1,3.094,2.637" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32932" data-name="Path 32932" d="M4.988,12.853a5.641,5.641,0,0,1-1.809-4.2,5.641,5.641,0,0,1,1.809-4.2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32933" data-name="Path 32933" d="M21.906,2.637A8.086,8.086,0,0,1,24.5,8.655a8.088,8.088,0,0,1-2.594,6.018" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32934" data-name="Path 32934" d="M20.013,4.457a5.641,5.641,0,0,1,1.809,4.2,5.641,5.641,0,0,1-1.809,4.2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //     </g>
  //   </g>
  // </svg>


  // <svg xmlns="http://www.w3.org/2000/svg" width="20.404" height="31.007" viewBox="0 0 20.404 31.007" {...props}>
  //   <g id="Group_4008" data-name="Group 4008" transform="translate(-2.946)">
  //     <g id="Group_4009" data-name="Group 4009" transform="translate(3.446 0.5)">
  //       <path id="Path_32928" data-name="Path 32928" d="M8.114,26.789V6.2L21.169.5V21.91" transform="translate(-1.781 -0.5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32929" data-name="Path 32929" d="M9.813,21.11A3.183,3.183,0,1,1,6.63,17.927,3.183,3.183,0,0,1,9.813,21.11Z" transform="translate(-3.446 5.715)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32930" data-name="Path 32930" d="M19.423,17.043A3.183,3.183,0,1,1,16.24,13.86,3.183,3.183,0,0,1,19.423,17.043Z" transform="translate(-0.019 4.264)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <line id="Line_760" data-name="Line 760" y1="5.857" x2="12.615" transform="translate(6.385 5.745)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //       <path id="Path_32932" data-name="Path 32932" d="M4.988,4.457" transform="translate(-2.896 0.911)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
  //     </g>
  //   </g>
  // </svg>


  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25.291" viewBox="0 0 21 25.291" {...props}>
    <g id="Group_4009" data-name="Group 4009" transform="translate(0 0)">
      <g id="Group_4008" data-name="Group 4008" transform="translate(0 0)">
        <path id="Path_32935" data-name="Path 32935" d="M5.192,22.3V5.275L18.739.56v17.7" transform="translate(0.632 0.068)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        <path id="Path_32936" data-name="Path 32936" d="M17.87,16.575a2.632,2.632,0,1,1-2.632-2.632A2.631,2.631,0,0,1,17.87,16.575Z" transform="translate(1.534 1.696)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        <path id="Path_32937" data-name="Path 32937" d="M5.764,19.987a2.632,2.632,0,1,1-2.632-2.632A2.632,2.632,0,0,1,5.764,19.987Z" transform="translate(0.061 2.112)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        <line id="Line_761" data-name="Line 761" y1="4.842" x2="13.091" transform="translate(5.878 5.378)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
      </g>
    </g>
  </svg>
