import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../../shared/loader";
import InvlidShootingDayNotification from "../invalid-shooting-day-notification";
import { fetchShootingDayCostumes } from "./ducks/actions";
import EditPropsForm from "./edit-form";

function ListShootingDayCrewContainer(props) {
  const { filmId, shootingDayId } = props.match.params;
  const {
    fetchShootingDayCostumes,
    loading,
    costumes,
    errors,
    readOnly
  } = props;

  useEffect(() => {
    fetchShootingDayCostumes(filmId, shootingDayId);
  }, [filmId, shootingDayId]);

  function renderLoading() {
    return <Loader />;
  }

  const anyCostumes = costumes?.length > 0;

  function renderShootingDays(costumes, filmId) {
    return (
      <div className="blade-content wide">
        <p className="menu-label">Costumes</p>
        {!anyCostumes && <div>None required on this day</div>}
        {anyCostumes && <EditPropsForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ costumes }}
          loading={loading}
          readOnly={readOnly}
          {...props}
        />}
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(costumes, filmId);
}

function mapStateToProps(state) {
  const { rootState, costumesState } = state.shootingdaysState;
  return {
    shootingDay: rootState.shootingday,
    readOnly: costumesState.readOnly,
    costumes: costumesState.costumes,
    loading: costumesState.loading,
    errors: costumesState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDayCostumes
})(ListShootingDayCrewContainer);
