import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../../../shared/private-route";
import ListVisualEffectsContainer from "./list-container";
import AddVisualEffect from "./add";

const PropRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/films/:filmId/scenes/:sceneId/sound-effects"
        component={ListVisualEffectsContainer}
      />
      <PrivateRoute
        exact
        path="/films/:filmId/scenes/:sceneId/sound-effects/add"
        component={AddVisualEffect}
      />
    </>
  );
};

export default PropRoutes;
