import getApiClient from "../../../../shared/api";
import {
  showInfo,
  showError,
  clearNotifications
} from "../../../../notifications/ducks/actions";

export const FETCH_SCENES_ORDER = "FETCH_SCENES_ORDER";
export const UPDATE_SCENES_ORDER = "UPDATE_SCENES_ORDER";
export const REORDER_SCENES_ORDER = "REORDER_SCENES_ORDER";
export const SCENE_ORDER_SET_PRISTINE = "SCENE_ORDER_SET_PRISTINE";

export function fetchScenesOrder(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SCENES_ORDER,
      payload: getApiClient().get(`/film/${filmId}/scene/order`)
    });
  };
}

export function updateScenesOrder(filmId, scenes, renumber) {
  const command = { scenes: scenes.map(s => { return { id: s.id } }), renumber };
  return (dispatch) => {
    dispatch({
      type: UPDATE_SCENES_ORDER,
      payload: getApiClient().put(
        `/film/${filmId}/scene/order`,
        command
      ),
      meta: { scenes }
    })
      .then((response) => {
        dispatch(showInfo(`Updated scene order`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating scene order`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function updateOrder(startIndex, endIndex) {
  return (dispatch) => {
    dispatch({
      type: "REORDER_SCENES_SCRIPT_DAYS",
      meta: { startIndex, endIndex }
    });
  };
}

export function updateSceneOrder(
  startIndex,
  endIndex,
  sourceDay,
  destinationDay
) {
  return (dispatch) => {
    dispatch({
      type: REORDER_SCENES_ORDER,
      meta: { startIndex, endIndex, sourceDay, destinationDay }
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function toggleSelectionInGroup(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SCENES_SCRIPT_DAY_TOGGLE_SELECTION_IN_GROUP",
      meta: { sceneId }
    });
  };
}

export function multiSelectTo(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SCENES_SCRIPT_DAY_MULTI_SELECT_TO",
      meta: { sceneId }
    });
  };
}

export function setDraggingSceneId(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SCENES_SCRIPT_DAY_SET_DRAGGING_SCENE_ID",
      meta: { sceneId }
    });
  };
}

export function unselectAll() {
  return (dispatch) => {
    dispatch({
      type: "SCENES_SCRIPT_DAY_UNSELECT_ALL"
    });
  };
}

export function setPristine() {
  return (dispatch) => {
    dispatch({
      type: SCENE_ORDER_SET_PRISTINE
    });
  };
}