import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import { fetchVisualEffects } from "./../../visual-effects/ducks/actions";
import {
  addVisualEffectToScene,
  clearRedirect
} from "./ducks/actions";
import Form from "./form";
import Loader from "../../../shared/loader";

function AddVisualEffectToScene(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    fetchVisualEffects,
    addVisualEffectToScene,
    clearRedirect,
    errors,
    visualEffects,
    sceneVisualEffects,
    visualEffect,
    saving
  } = props;

  const [fromBreakdown, setFromBreakdown] = useState<boolean>(false);
  useEffect(() => {
    setFromBreakdown(props.location?.state?.fromBreakdown);
  }, [props.location?.state?.fromBreakdown]);

  useEffect(() => {
    fetchVisualEffects(filmId);
  }, []);

  if (props.redirect) {
    clearRedirect();
    if (props.redirect.to == "list") {
      const url = `/films/${filmId}/scenes/${sceneId}/visual-effects`;
      return <Redirect to={url} />;
    } else if (props.redirect.to == "breakdown") {
      const url = `/films/${filmId}/breakdown/scenes/${sceneId}`;
      return <Redirect to={url} />;
    }
  }

  function onAddVisualEffect(visualEffect) {
    sanitiseDataTypes(visualEffect);
    addVisualEffectToScene(filmId, sceneId, visualEffect, fromBreakdown);
  }

  function sanitiseDataTypes(prop) {
  }

  const sceneVisualEffectsIds = sceneVisualEffects.map(p => p.id);
  const nonSceneVisualEffects = visualEffects.filter((vfx: any) => !sceneVisualEffectsIds.includes(vfx.id));
  const noVisualEffectsForFilm = visualEffects.length === 0;

  return (
    <ScrollIntoView
      className="blade-content"
      path="/films/:filmId/scenes/:sceneId/visual-effects/add"
      loading={false}>
      <Form
        mode="add"
        errors={errors}
        characterId={null}
        initialValues={visualEffect}
        visualEffects={nonSceneVisualEffects}
        onSubmit={onAddVisualEffect}
        noVisualEffectsForFilm={noVisualEffectsForFilm}
      />
      {saving && <Loader />}
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { visualEffectState } = state.scenesState;
  return {
    sceneVisualEffects: visualEffectState.visualEffects,
    visualEffects: state.visualEffectsState.visualEffects,
    visualEffect: visualEffectState.visualEffect,
    errors: visualEffectState.errors,
    redirect: visualEffectState.redirect,
    saving: visualEffectState.saving
  };
}

export default connect(mapStateToProps, {
  addVisualEffectToScene,
  fetchVisualEffects,
  clearRedirect
})(AddVisualEffectToScene);
