import React, { CSSProperties, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { fetchShootingDay } from "./ducks/actions";

function ShootingDaysMenu(props) {
  const { filmId, shootingdayId } = props.match.params;

  const invalidIds = ["add", "shooting-order", "calendar", "settings", "day-out-of-days", "messages", "import"];

  useEffect(() => {
    if (invalidIds.indexOf(shootingdayId.toLowerCase()) > -1) {
      return;
    }

    props.fetchShootingDay(filmId, shootingdayId);
  }, [filmId, shootingdayId]);

  if (invalidIds.indexOf(shootingdayId.toLowerCase()) > -1) {
    return null;
  }

  const disabledLinkStyle: CSSProperties = {
    borderRadius: "4px",
    padding: "0.85rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "not-allowed",
    color: "#666",
    lineHeight: 3
  };

  const scheduleIsValid = !!props.shootingDay?.hasValidSchedule;

  function renderMenuItem(text, to, enabled) {
    return enabled ? (
      <NavLink to={to} activeClassName="is-active">
        {text}
      </NavLink>
    ) : (
      <span style={disabledLinkStyle}>{text}</span>
    );
  }

  const menuItems = [
    {
      text: "Edit Shooting Day",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/edit`,
      enabled: true
    },
    /*
    {
      text: "Schedule",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/schedule`,
      enabled: true
    },
    */
    {
      text: "Shot List Schedule",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/shot-list-schedule`,
      enabled: true
    },
    {
      text: "Scenes",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/scenes`,
      enabled: true
    },
    {
      text: "Actors",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/actors`,
      enabled: true
    },
    {
      text: "Background Actors",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/background-actors`,
      enabled: true
    },
    {
      text: "Locations",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/locations`,
      enabled: true
    },
    {
      text: "Weather",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/weather`,
      enabled: true
    },
    {
      text: "Costumes",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/costumes`,
      enabled: true
    },
    {
      text: "Hair and Makeup",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/makeup`,
      enabled: true
    },
    {
      text: "Props",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/props`,
      enabled: true
    },
    {
      text: "Set Dressings",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/set-dressings`,
      enabled: true
    },
    {
      text: "Equipment",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/equipment`,
      enabled: true
    },
    {
      text: "Crew",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/crew`,
      enabled: true
    },
    /*
    {
      text: "Shot List",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/shot-list`,
      enabled: scheduleIsValid
    },
    */
    {
      text: "Catering",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/catering`,
      enabled: true
    },
    {
      text: "Risk Assessments",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/risk-assessments`,
      enabled: true
    },
    {
      text: "Call Sheet",
      to: `/films/${filmId}/shooting-days/${shootingdayId}/call-sheet`,
      enabled: true
    }
  ];

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Shooting Day</p>
      <ul className="menu-list">
        {menuItems.map(({ text, to, enabled }, idx) => (
          <li key={idx}>{renderMenuItem(text, to, enabled)}</li>
        ))}
      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shootingDay: state.shootingdaysState.rootState.shootingday
  };
}
export default connect(mapStateToProps, { fetchShootingDay })(ShootingDaysMenu);
