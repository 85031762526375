import {
  clearNotifications,
  showError,
  showInfo
} from "../../../../notifications/ducks/actions";
import getApiClient from "../../../../shared/api";
import { getActionToUploadImagesAndSaveEntity } from "../../../../shared/image-action-creator";

export const MAKEUP_SET_RETURN_URL = "MAKEUP_SET_RETURN_URL";

export function fetchMakeups(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_MAKEUPS",
      payload: getApiClient().get(`/film/${filmId}/makeup`)
    });
  };
}

export function setReturnUrl(returnUrl) {
  return (dispatch) => {
    dispatch({
      type: MAKEUP_SET_RETURN_URL,
      meta: { returnUrl }
    });
  };
}

export function clearMakeup() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_MAKEUP"
    });
  };
}

export function setName(name) {
  return (dispatch) => {
    dispatch({
      type: "MAKEUP_SET_NAME",
      meta: { name }
    });
  };
}

export function fetchNextMakeupNumber(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NEXT_MAKEUP_NUMBER"
    });
  };
}

export function fetchMakeup(filmId, makeupId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_MAKEUP",
      payload: getApiClient().get(`/film/${filmId}/makeup/${makeupId}`)
    });
  };
}

export function updateMakeup(filmId, makeup) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_MAKEUP",
      payload: getApiClient().put(
        `/film/${filmId}/makeup/${makeup.id}`,
        makeup
      ),
      meta: { makeup }
    })
      .then((response) => {
        dispatch(showInfo(`Updated makeup ${makeup.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating makeup ${makeup.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

function addMakeupWithImageUrls(dispatch, makeup, filmId) {
  dispatch({
    type: "ADD_SINGLE_MAKEUP",
    payload: getApiClient().post(`/film/${filmId}/makeup/`, makeup),
    meta: { makeup }
  })
    .then((response) => {
      dispatch(showInfo(`Added makeup ${makeup.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function updateMakeupWithImageUrls(dispatch, makeup, filmId) {
  dispatch({
    type: "UPDATE_SINGLE_MAKEUP",
    payload: getApiClient().put(
      `/film/${filmId}/makeup/${makeup.id}`,
      makeup
    ),
    meta: { makeup }
  })
    .then((response) => {
      dispatch(showInfo(`Updated makeup ${makeup.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export const uploadImagesAndSaveMakeup = getActionToUploadImagesAndSaveEntity(
  addMakeupWithImageUrls,
  updateMakeupWithImageUrls
);

export function addMakeup(filmId, makeup) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_MAKEUP",
      payload: getApiClient().post(`/film/${filmId}/makeup/`, makeup),
      meta: { makeup }
    })
      .then((response) => {
        dispatch(showInfo(`Added makeup ${makeup.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding makeup`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteMakeup(filmId, makeup) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_MAKEUP",
      payload: getApiClient().delete(`/film/${filmId}/makeup/${makeup.id}`),
      meta: { makeup }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted makeup ${makeup.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting makeup ${makeup.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addImageFiles(imageFiles) {
  return (dispatch) => {
    dispatch({
      type: "ADD_MAKEUP_IMAGE_FILES",
      meta: { imageFiles }
    });
  };
}

export function addImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "ADD_MAKEUP_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_MAKEUP_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageFile(fileName) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_MAKEUP_IMAGE_FILE",
      meta: { fileName }
    });
  };
}

export function mainImageUrlChanged(url) {
  return (dispatch) => {
    dispatch({
      type: "MAKEUP_MAIN_IMAGE_CHANGED",
      meta: { url }
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function fetchAllScenes(filmId, characterIds, backgroundCharacterIds) {
  let filters: string[] = [];
  if (characterIds?.length) {
    filters.push(`characterIds=${characterIds.join(",")}`);
  }

  if (backgroundCharacterIds?.length) {
    filters.push(`backgroundCharacterIds=${backgroundCharacterIds.join(",")}`);
  }

  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENES_FOR_MAKEUPS",
      payload: getApiClient().get(
        `/film/${filmId}/scene/withcharacters?${filters.join("&")}`
      )
    });
  };
}

export function fetchScenesAssignedToMakeup(filmId, makeupId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_ASSIGNED_TO_MAKEUPS",
      payload: getApiClient().get(
        `/film/${filmId}/Makeup/${makeupId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedMakeup(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SET_SCENE_ASSIGNED_TO_MAKEUP",
      meta: { sceneId }
    });
  };
}

export function assignScenesToMakeup(filmId, makeupId, sceneIds) {
  return (dispatch) => {
    dispatch({
      type: "ASSIGN_SCENES_TO_MAKEUP",
      payload: getApiClient().put(
        `/film/${filmId}/makeup/${makeupId}/AssignToScenes`,
        { sceneIds }
      ),
      meta: { makeupId }
    })
      .then((response) => {
        dispatch(showInfo(`Assigned Makeup to Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error assigning Makeup to Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchAllCharacters(filmId, sceneId) {
  return (dispatch) => {
    if (sceneId) {
      dispatch({
        type: "FETCH_ALL_CHARACTERS_FOR_MAKEUPS",
        payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/characters`)
      });
    } else {
      dispatch({
        type: "FETCH_ALL_CHARACTERS_FOR_MAKEUPS",
        payload: getApiClient().get(`/film/${filmId}/character`)
      });
    }
  };
}

export function fetchAllBackgroundCharacters(filmId, sceneId) {
  return (dispatch) => {
    if (sceneId) {
      dispatch({
        type: "FETCH_ALL_BACKGROUND_CHARACTERS_FOR_MAKEUPS",
        payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/backgroundCharacters`)
      });
    } else {
      dispatch({
        type: "FETCH_ALL_BACKGROUND_CHARACTERS_FOR_MAKEUPS",
        payload: getApiClient().get(`/film/${filmId}/backgroundCharacter`)
      });
    }
  };
}

export function fetchAllSceneSettings(filmId, characterIds, backgroundCharacterIds) {
  let filters: string[] = [];

  if (characterIds?.length) {
    filters.push(`characterIds=${characterIds.join(",")}`);
  }

  if (backgroundCharacterIds?.length) {
    filters.push(`backgroundCharacterIds=${backgroundCharacterIds.join(",")}`);
  }

  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENE_SETTINGS_FOR_MAKEUPS",
      payload: getApiClient().get(
        `/film/${filmId}/scenesetting/withcharacters?${filters.join("&")}`
      )
    });
  };
}

export function fetchScriptDays(filmId, characterIds, backgroundCharacterIds) {
  let filters: string[] = [];

  if (characterIds?.length) {
    filters.push(`characterIds=${characterIds.join(",")}`);
  }

  if (backgroundCharacterIds?.length) {
    filters.push(`backgroundCharacterIds=${backgroundCharacterIds.join(",")}`);
  }

  return (dispatch) => {
    dispatch({
      type: "FETCH_SCRIPT_DAYS_FOR_MAKEUPS",
      payload: getApiClient().get(
        `/film/${filmId}/scene/scriptDays/search?${filters.join("&")}`
      )
    });
  };
}

export function searchScenes(
  filmId,
  characterIds,
  sceneSettingIds,
  sceneNumberFrom,
  sceneNumberTo,
  selectSearchedScenes,
  scriptDays
) {
  return (dispatch) => {
    let filters: string[] = [];

    if (characterIds?.length) {
      filters.push(`characterIds=${characterIds.join(",")}`);
    }

    if (sceneSettingIds?.length) {
      filters.push(`sceneSettingIds=${sceneSettingIds.join(",")}`);
    }

    if (sceneNumberFrom) {
      filters.push(`sceneFrom=${sceneNumberFrom}`);
    }

    if (sceneNumberTo) {
      filters.push(`sceneTo=${sceneNumberTo}`);
    }

    if (scriptDays?.length) {
      filters.push(`scriptDays=${scriptDays.join(",")}`);
    }

    dispatch({
      type: "SEARCH_SCENES_FOR_MAKEUPS",
      payload: getApiClient().get(
        `/film/${filmId}/scene/search?${filters.join("&")}`
      ),
      meta: { selectSearchedScenes }
    });
  };
}

export function findInScript(
  filmId,
  makeupName
) {
  return (dispatch) => {
    dispatch({
      type: "SEARCH_SCRIPT_FOR_MAKEUPS",
      payload: getApiClient().get(
        `/film/${filmId}/script/search/${makeupName}`
      ),
      meta: { selectSearchedScenes: true }
    });
  };
}

export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_SCENES_FOR_MAKEUPS"
    });
  };
}
