import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "./../../../shared/loader";
import { fetchBackgroundCharactersInScene } from "./ducks/actions";
import AddIcon from "../../icons/add";
import List from "./list";

function ListCharactersContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchBackgroundCharactersInScene, loading, backgroundCharacters, errors, readOnly } = props;
  useEffect(() => {
    fetchBackgroundCharactersInScene(filmId, sceneId);
  }, [filmId, sceneId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderCharacters(backgroundCharacters, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List backgroundCharacters={backgroundCharacters} filmId={filmId} sceneId={sceneId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <NavLink
          to={`/films/${filmId}/scenes/${sceneId}/background-characters/add`}
          activeClassName={
            "is-active " + window.location.pathname.endsWith("add")
              ? "disabled"
              : ""
          }
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Assign Existing
        </NavLink>}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderCharacters(backgroundCharacters, filmId, sceneId);
}

function mapStateToProps(state) {
  return {
    backgroundCharacters: state.scenesState.backgroundCharacterState.backgroundCharacters,
    readOnly: state.scenesState.backgroundCharacterState.readOnly,
    loading: state.scenesState.backgroundCharacterState.loading,
    errors: state.scenesState.backgroundCharacterState.errors
  };
}

export default connect(mapStateToProps, {
  fetchBackgroundCharactersInScene
})(ListCharactersContainer);
