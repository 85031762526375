import ListContainer from "./list-container";
import Add from "./add";
import Edit from "./edit";
import Scenes from "./scenes";
import Menu from "./menu";
import { PrivateRoute } from "../../shared/private-route";

const SoundEffectsRoutes = () => {

  const url = "sound-effects";
  return (
    <>
      <PrivateRoute
        path={`/films/:filmId/${url}`}
        component={ListContainer}
      />
      <PrivateRoute path={`/films/:filmId/${url}/:itemId`} component={Menu} />
      <PrivateRoute exact path={`/films/:filmId/${url}/add`} component={Add} />
      <PrivateRoute
        exact
        path={`/films/:filmId/${url}/:itemId`}
        component={Edit}
      />
      <PrivateRoute
        exact
        path={`/films/:filmId/${url}/:itemId/edit`}
        component={Edit}
      />
      <PrivateRoute
        exact
        path={`/films/:filmId/${url}/:itemId/scenes`}
        component={Scenes}
      />
    </>
  );
};

export default SoundEffectsRoutes;
