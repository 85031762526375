import getApiClient from "../../../../shared/api";

export const FETCH_SHOOTING_DAY_COSTUMES = "FETCH_SHOOTING_DAY_COSTUMES";

export function fetchShootingDayCostumes(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SHOOTING_DAY_COSTUMES,
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Costumes`
      )
    });
  };
}