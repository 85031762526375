import React, { useEffect } from "react";
import { Link, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import Errors from "../../../shared/errors";
import Loader from "../../../shared/loader";
import confirm from "../../../shared/modal/confirm";
import {
  addScenesShootingOrderDays,
  addShootingDay,
  deleteShootingDay,
  fetchScenesShootingOrder,
  fetchScenesShootingOrderByDay,
  smartScenesShootingOrderByDay,
  smartShootingOrder,
  updateDayOrder,
  updateOrder,
  updateSceneOrder,
  updateScenesShootingOrder,
  downloadShootingDays,
  clearPdf,
  clearDownload,
  deleteAllShootingDays,
  resetSceneShootDays
} from "./ducks/actions";
import DayList from "./list-days";
import Onboarding from '../../../shared/onboarding/onboarding';

import {
  SmartDaysOnboardingStep1ClassName,
} from "./ducks/reducer";
import HelpText from "../../../shared/help-text";
import './shooting-order.scss';
import WarningText from "../../../shared/warning-text";
import Dropzone from "react-dropzone";

function ScenesShootingOrderContainer(props) {
  const filmId = props.match.params.filmId;
  const {
    fetchScenesShootingOrder,
    updateScenesShootingOrder,
    smartShootingOrder,
    fetchScenesShootingOrderByDay,
    smartScenesShootingOrderByDay,
    addShootingDay,
    deleteShootingDay,
    scenes,
    days,
    errors,
    warnings,
    loading,
    loadingScene,
    hasDayOrder,
    updateOrder,
    updateSceneOrder,
    updateDayOrder,
    addScenesShootingOrderDays,
    progress,
    download,
    clearPdf,
    clearDownload,
    downloadShootingDays,
    pristine,
    showSmartDays,
    hasPageLengths,
    deleteAllShootingDays,
    resetSceneShootDays,
    readOnly,
    onboardingSteps,
    smartDaysOnboardingSteps,
    scenesWithoutDays,
  } = props;

  useEffect(() => {
    clearDownload();
    fetchScenesShootingOrder(filmId);
  }, []);

  const onUpdateScenesShootingOrder = (scenes) => {
    updateScenesShootingOrder(filmId, scenes);
  };

  const onSmartShootingOrder = (scenes) => {
    smartShootingOrder(filmId);
  };

  const onFetchScenesShootingOrderByDay = () => {
    fetchScenesShootingOrderByDay(filmId);
  };

  const onSmartScenesShootingOrderByDay = () => {
    if (days?.length > 0) {
      confirm(() => smartScenesShootingOrderByDay(filmId), " ALL shooting days");
    } else {
      smartScenesShootingOrderByDay(filmId);
    }
  };

  const onAddShootingDay = () => {
    addShootingDay();
  };

  const onDeleteShootingDay = (dayNumber) => {
    deleteShootingDay(dayNumber);
  };

  const onAddScenesShootingOrderDays = (days) => {
    addScenesShootingOrderDays(filmId, days);
  };

  const onDownloadFile = () => {
    downloadShootingDays(filmId);
  };

  const onDeleteAllShootingDays = () => {
    confirm(() => deleteAllShootingDays(filmId), " ALL shooting days");
  };

  const onResetSceneShootDays = () => {
    confirm(
      () => resetSceneShootDays(filmId),
      null,
      "Are you sure you want to reset shoot days on all scenes?"
    );
  };

  function renderLoading() {
    return <Loader />;
  }

  const scenesWithShootDaysGreaterThanOne = scenes?.filter(s => s.shootDays > 1);

  const hasDates = days?.findIndex(d => d.date !== null) > -1;

  function renderScenes(scenes) {
    return (
      <>
        <div className="blade-content wideaf">
          <p className="menu-label">Scenes Shooting Order</p>
          <Prompt when={!pristine} message="" />
          {(loadingScene || loading) && <Loader />}
          {errors && <Errors errors={errors} />}
          {(!days || days.length == 0) && !readOnly && (
            <>{!hasPageLengths && <WarningText className="shooting-order-help">
              <p>Not all scenes have page lengths. Please add them to the <Link to={`/films/${filmId}/scenes`}>scenes</Link>, so that smart days can use this to produce an optimized shooting schedule.</p>
              <p>For details on how to set these, please see <a href="https://www.youtube.com/watch?v=KryJW8pJPvw&t=143s" target="_blank">this video tutorial</a>.</p>
            </WarningText>}
              {scenesWithShootDaysGreaterThanOne && scenesWithShootDaysGreaterThanOne.length > 0 && <div>
                <WarningText className="shootind-days-warning-width">
                  <p> The following scenes have shoot days greater than one. We suggest you either split the scene in the script section, or reduce the shoot days value to 1 or less in the scene page.</p>
                  <p>You can continue to use Smart Days without making these changes, but you will end up with long shoot days.</p>
                </WarningText>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Scene Setting</th>
                      <th>Shoot Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scenesWithShootDaysGreaterThanOne.map((scene) => (
                      <tr>
                        <td><Link to={`/films/${filmId}/scenes/${scene.sceneId}`}>{scene.number}</Link></td>
                        <td>{scene.settingName}</td>
                        <td>{scene.shootDays}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>}
              <div className="mt-2">
                {showSmartDays && (
                  <>
                    <button
                      type="submit"
                      className={`button ${SmartDaysOnboardingStep1ClassName}`}
                      onClick={() => onSmartScenesShootingOrderByDay()}>
                      Smart Days
                    </button>

                  </>
                )}
              </div>
            </>
          )}
          {warnings && <Errors errors={warnings} isWarning={true} />}
          {days && days.length > 0 && (
            <DayList
              days={days}
              filmId={filmId}
              updateSceneOrder={updateSceneOrder}
              addShootingDay={onAddShootingDay}
              deleteShootingDay={onDeleteShootingDay}
              updateDayOrder={updateDayOrder}
              onAddScenesShootingOrderDays={onAddScenesShootingOrderDays}
              smartScenesShootingOrderByDay={onSmartScenesShootingOrderByDay}
              hasDayOrder={hasDayOrder}
              progress={progress}
              onDownloadFile={onDownloadFile}
              download={download}
              clearPdf={clearPdf}
              onDeleteAllShootingDays={onDeleteAllShootingDays}
              onResetSceneShootDays={onResetSceneShootDays}
              readOnly={readOnly}
              scenesWithoutDays={scenesWithoutDays}
              hasDates={hasDates}
            />
          )}
        </div>
        <Onboarding onboardingSteps={(!days || days.length == 0) ? smartDaysOnboardingSteps : onboardingSteps} section="Shooting Order" />
      </>
    );
  }

  return loading ? renderLoading() : renderScenes(scenes);
}

function mapStateToProps(state) {
  const {
    scenes,
    days,
    hasDayOrder,
    hasPageLengths,
    loading,
    errors,
    warnings,
    progress,
    download,
    pristine,
    showSmartDays,
    readOnly,
    onboardingSteps,
    smartDaysOnboardingSteps,
    scenesWithoutDays,
    loadingScene,
    isUploading
  } = state.shootingOrderState;
  return {
    scenes,
    days,
    hasDayOrder,
    loading,
    loadingScene,
    errors,
    warnings,
    progress,
    download,
    pristine,
    showSmartDays,
    hasPageLengths,
    readOnly,
    onboardingSteps,
    smartDaysOnboardingSteps,
    scenesWithoutDays,
    isUploading
  };
}

export default connect(mapStateToProps, {
  fetchScenesShootingOrder,
  updateScenesShootingOrder,
  fetchScenesShootingOrderByDay,
  smartScenesShootingOrderByDay,
  smartShootingOrder,
  updateOrder,
  addShootingDay,
  deleteShootingDay,
  updateSceneOrder,
  updateDayOrder,
  addScenesShootingOrderDays,
  downloadShootingDays,
  clearPdf,
  clearDownload,
  deleteAllShootingDays,
  resetSceneShootDays
})(ScenesShootingOrderContainer);
