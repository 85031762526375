import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  updateFeatureToggle,
  fetchFeatureToggles,
  clearRedirect,
  fetchFeatureToogle,
  fetchUsers
} from "./ducks/actions";
import Form from "./form";
import Loader from "../../shared/loader";

function EditChangeLog(props) {
  const {
    errors,
    fetchFeatureToogle,
    loading,
    redirect,
    updateFeatureToggle,
    clearRedirect,
    featureToggle,
    fetchUsers,
    users
  } = props;

  const featureToggleName = props.match.params.featureToggleName;

  useEffect(() => {
    fetchFeatureToogle(featureToggleName);
    fetchUsers();
  }, []);

  if (redirect) {
    if (redirect.to === "list") {
      clearRedirect();
      const url = `/admin/feature-toggles`;
      return <Redirect to={url} />;
    }
  }

  function onUpdateFeatureToggle(featureToggle) {
    updateFeatureToggle(featureToggleName, featureToggle);
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/admin/change-logs/add"
      loading={false}>
      {loading && <Loader />}
      {!loading && <Form
        errors={errors}
        costumeId={null}
        initialValues={featureToggle}
        loading={loading}
        onSubmit={onUpdateFeatureToggle}
        mode="edit"
        featureToggleName={featureToggleName}
        changeLogChangeTypeEnum={props.changeLogChangeTypeEnum}
        reduxFormValues={props.reduxFormValues}
        users={users}
      />}
    </ScrollIntoView>
  );
}

function mapStateToChangeLogs(state) {
  const { featureTogglesState } = state;
  return {
    featureToggle: featureTogglesState.featureToggle,
    errors: featureTogglesState.errors,
    redirect: featureTogglesState.redirect,
    loading: featureTogglesState.loading,
    featureRequests: featureTogglesState.featureRequests,
    users: featureTogglesState.users
  };
}

export default connect(mapStateToChangeLogs, {
  updateFeatureToggle,
  fetchFeatureToggles,
  clearRedirect,
  fetchFeatureToogle,
  fetchUsers
})(EditChangeLog);
