import { Prompt } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import Errors from "./../../../shared/errors";
import {
  renderSingleReactSelect
} from "./../../../shared/form-helpers";
import required from "./../../../shared/validation";
import { NAME_FOR_MESSAGES, NAME_FOR_UI, NAME_PLURAL_FOR_UI } from "./ducks/actions";

function SoundEffectForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    items,
    noItemsForFilm
  } = props;

  const thisItem = props.initialValues || {};
  const readOnly = thisItem.readOnly;

  const itemOptions = items?.map((e) => {
    return { label: e.name, value: e.id };
  });

  const noVisualEffects = !itemOptions?.length;

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add {NAME_FOR_MESSAGES} to scene</h2>}
      {mode === "edit" && (
        <h2 className="menu-label">Edit {NAME_FOR_MESSAGES} in scene</h2>
      )}
      {errors && <Errors errors={errors} />}
      {noVisualEffects &&
        <>
          {noItemsForFilm && <div>No {NAME_FOR_MESSAGES}s added to film</div>}
          {!noItemsForFilm && <div>All {NAME_FOR_MESSAGES}s already added to scene</div>}
        </>}

      {!noVisualEffects &&
        <form autoComplete="off" onSubmit={handleSubmit}>
          {mode === "add" && (
            <Field
              name="itemId"
              label={NAME_FOR_UI}
              component={renderSingleReactSelect}
              value={thisItem.visualEffectId}
              validate={[required]}
              options={itemOptions}
              required
              readOnly={readOnly}>
            </Field>
          )}
          {mode === "edit" && (
            <div className="field">
              <label className="label">
                {NAME_PLURAL_FOR_UI}
              </label>
              <div className="control">
                {thisItem.name}
              </div>
            </div>
          )}

          {!readOnly && <div className="buttons">
            <button
              type="submit"
              className="button is-primary"
              disabled={pristine || submitting}>
              Save
            </button>

            {mode === "edit" && (
              <button
                type="button"
                className="button is-text"
                disabled={submitting || !props.initialValues}
                onClick={() => handleDelete(thisItem)}>
                Remove from scene
              </button>
            )}
          </div>}
        </form>}
    </>
  );
}

export default reduxForm({
  form: "CharacterForm",
  enableReinitialize: true
})(SoundEffectForm);
