import { sortByName } from "../../../shared/utility";
import { ItemTypes } from "../constants";
import { BREAKDOWN_CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER, BREAKDOWN_CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER, BREAKDOWN_CHANGE_COSTUME_TO_PROP, BREAKDOWN_CHANGE_ITEM, BREAKDOWN_CHANGE_PROP_TO_COSTUME, BREAKDOWN_CHANGE_PROP_TO_SET_DRESSING, BREAKDOWN_CHANGE_SET_DRESSING_TO_PROP, REMOVE_BACKGROUND_CHARACTER_FROM_SCENE, REMOVE_CHARACTER_FROM_SCENE, BREAKDOWN_REMOVE_PROP_FROM_SCENE, BREAKDOWN_REMOVE_SET_DRESSING_FROM_SCENE, BREAKDOWN_REMOVE_COSTUME_FROM_SCENE, BREAKDOWN_REMOVE_MAKEUP_FROM_SCENE, BREAKDOWN_REMOVE_VISUAL_EFFECT_FROM_SCENE, BREAKDOWN_REMOVE_SOUND_EFFECT_FROM_SCENE } from "./actions";

interface BreakdownState {
  films: any[];
  film: any;
  download: any;
  breakdown: any;
  characterOrderBy: string;
  scenes: any[];
  scene: any;
  errors: any;
  loading: boolean;
}

const defaultState: BreakdownState = {
  films: [],
  film: {},
  scenes: [],
  scene: null,
  breakdown: {},
  download: null,
  characterOrderBy: 'Name',
  errors: null,
  loading: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_BREAKDOWN_SCENES_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_BREAKDOWN_SCENES_FULFILLED": {
      return {
        ...state,
        loading: false,
        scenes: action.payload.data.scenes
      };
    }

    case "FETCH_BREAKDOWN_SCENES_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data
      };
    }

    case "FETCH_SINGLE_FILM_BREAKDOWN_PENDING": {
      return {
        ...state,
        loading: true,
        download: null
      };
    }

    case "FETCH_SINGLE_FILM_BREAKDOWN_FULFILLED": {
      return {
        ...state,
        loading: false,
        breakdown: action.payload.data,
        redirect: false,
        characterOrderBy: 'Name'
      };
    }

    case "FETCH_SINGLE_FILM_BREAKDOWN_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data.errors
      };
    }

    case "FETCH_SINGLE_FILM_SCENE_BREAKDOWN_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_SINGLE_FILM_SCENE_BREAKDOWN_FULFILLED": {
      return {
        ...state,
        loading: false,
        scene: action.payload.data.scene,
        canAccessBackgroundCharacters: action.payload.data.canAccessBackgroundCharacters,
        canAccessCharacters: action.payload.data.canAccessCharacters,
        canAccessCostumes: action.payload.data.canAccessCostumes,
        canAccessMakeups: action.payload.data.canAccessMakeups,
        canAccessProps: action.payload.data.canAccessProps,
        canAccessSetDressings: action.payload.data.canAccessSetDressings,
        canAccessVisualEffects: action.payload.data.canAccessVisualEffects,
        canAccessSoundEffects: action.payload.data.canAccessSoundEffects,
      };
    }

    case "FETCH_SINGLE_FILM_SCENE_BREAKDOWN_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case "DOWNLOAD_SCENE_BREAKDOWN_PENDING": {
      return {
        ...state,
        loading: false,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case "DOWNLOAD_SCENE_BREAKDOWN_FULFILLED": {
      return state;
    }

    case "DOWNLOAD_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case "DOWNLOAD_SCENE_BREAKDOWN_COMPLETE": {
      return {
        ...state,
        loading: false,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case "CLEAR_DOWNLOAD_SCENE_BREAKDOWN": {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    case "SCRIPT_BREAKDOWN_CHARACTERS_ORDERBY_SCENES": {
      const breakdown = { ...state.breakdown };

      let characters = breakdown.characters;
      characters.sort((a, b) => {
        return b.numberOfScenes - a.numberOfScenes;
      });

      return {
        ...state,
        breakdown: breakdown,
        characterOrderBy: 'Scenes'
      };
    }

    case "SCRIPT_BREAKDOWN_CHARACTERS_ORDERBY_NAME": {
      const breakdown = { ...state.breakdown };

      let characters = breakdown.characters;

      characters.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });

      return {
        ...state,
        breakdown: breakdown,
        characterOrderBy: 'Name'
      };
    }

    case BREAKDOWN_CHANGE_ITEM: {
      const { item, destination } = action.meta;
      let scene = { ...state.scene };
      if (item.type === ItemTypes.CHARACTER && destination === ItemTypes.BACKGROUND_CHARACTER) {
        const characters = Array.from(state.scene.characters);
        const characterIndex = characters.findIndex((b: any) => b.id === item.item.id);
        characters.splice(characterIndex, 1);
        const backgroundCharacters = Array.from(state.scene.backgroundCharacters);
        backgroundCharacters.push(item.item);
        sortByName(backgroundCharacters);
        scene = { ...scene, backgroundCharacters, characters };
      } else if (item.type === ItemTypes.BACKGROUND_CHARACTER && destination === ItemTypes.CHARACTER) {
        const backgroundCharacters = Array.from(state.scene.backgroundCharacters);
        const backgroundCharacterIndex = backgroundCharacters.findIndex((b: any) => b.id === item.item.id);
        backgroundCharacters.splice(backgroundCharacterIndex, 1);
        const characters = Array.from(state.scene.characters);
        characters.push(item.item);
        sortByName(characters);
        scene = { ...scene, backgroundCharacters, characters };
      } else if (item.type === ItemTypes.PROP && destination === ItemTypes.SET_DRERSSING) {
        const props = Array.from(state.scene.props);
        const propIndex = props.findIndex((b: any) => b.id === item.item.id);
        props.splice(propIndex, 1);
        const setDressings = Array.from(state.scene.setDressings);
        setDressings.push(item.item);
        sortByName(setDressings);
        scene = { ...scene, props, setDressings };
      } else if (item.type === ItemTypes.PROP && destination === ItemTypes.COSTUME) {
        const props = Array.from(state.scene.props);
        const propIndex = props.findIndex((b: any) => b.id === item.item.id);
        props.splice(propIndex, 1);
        const costumes = Array.from(state.scene.costumes);
        costumes.push(item.item);
        sortByName(costumes);
        scene = { ...scene, props, costumes };
      } else if (item.type === ItemTypes.SET_DRERSSING && destination === ItemTypes.PROP) {
        const setDressings = Array.from(state.scene.setDressings);
        const setDressingIndex = setDressings.findIndex((b: any) => b.id === item.item.id);
        setDressings.splice(setDressingIndex, 1);
        const props = Array.from(state.scene.props);
        props.push(item.item);
        sortByName(props);
        scene = { ...scene, props, setDressings };
      } else if (item.type === ItemTypes.COSTUME && destination === ItemTypes.PROP) {
        const costumes = Array.from(state.scene.costumes);
        const costumeIndex = costumes.findIndex((b: any) => b.id === item.item.id);
        costumes.splice(costumeIndex, 1);
        const props = Array.from(state.scene.props);
        props.push(item.item);
        sortByName(props);
        scene = { ...scene, props, costumes };
      }

      return {
        ...state,
        scene
      };
    }

    case BREAKDOWN_CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case BREAKDOWN_CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER + "_FULFILLED": {
      const { item } = action.meta;
      let scene = { ...state.scene };
      const characters = Array.from(state.scene.characters);
      const characterIndex = characters.findIndex((b: any) => b.id === item.id);
      characters.splice(characterIndex, 1);
      const backgroundCharacters = Array.from(state.scene.backgroundCharacters);

      const newId = action.payload.data.backgroundCharacterId;
      backgroundCharacters.push({ ...item, id: newId });
      sortByName(backgroundCharacters);
      scene = { ...scene, backgroundCharacters, characters };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_CHANGE_CHARACTER_TO_BACKGROUND_CHARACTER + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case BREAKDOWN_CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER + "_FULFILLED": {
      const { item } = action.meta;
      let scene = { ...state.scene };
      const backgroundCharacters = Array.from(state.scene.backgroundCharacters);
      const backgroundCharacterIndex = backgroundCharacters.findIndex((b: any) => b.id === item.id);
      backgroundCharacters.splice(backgroundCharacterIndex, 1);
      const characters = Array.from(state.scene.characters);

      const newId = action.payload.data.characterId;
      characters.push({ ...item, id: newId });
      sortByName(characters);
      scene = { ...scene, backgroundCharacters, characters };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_CHANGE_PROP_TO_SET_DRESSING + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case BREAKDOWN_CHANGE_PROP_TO_SET_DRESSING + "_FULFILLED": {
      const { prop } = action.meta;
      let scene = { ...state.scene };
      const props = Array.from(state.scene.props);
      const propIndex = props.findIndex((b: any) => b.id === prop.id);
      props.splice(propIndex, 1);
      const setDressings = Array.from(state.scene.setDressings);
      const newId = action.payload.data.setDressingId;
      setDressings.push({ ...prop, id: newId });
      sortByName(setDressings);
      scene = { ...scene, props, setDressings };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_CHANGE_PROP_TO_SET_DRESSING + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_CHANGE_PROP_TO_COSTUME + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case BREAKDOWN_CHANGE_PROP_TO_COSTUME + "_FULFILLED": {
      const { prop } = action.meta;
      let scene = { ...state.scene };
      const props = Array.from(state.scene.props);
      const propIndex = props.findIndex((b: any) => b.id === prop.id);
      props.splice(propIndex, 1);
      const costumes = Array.from(state.scene.costumes);
      const newId = action.payload.data.costumeId;
      costumes.push({ ...prop, id: newId });

      sortByName(costumes);
      scene = { ...scene, props, costumes };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_CHANGE_PROP_TO_COSTUME + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_CHANGE_SET_DRESSING_TO_PROP + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case BREAKDOWN_CHANGE_SET_DRESSING_TO_PROP + "_FULFILLED": {
      const { setDressing } = action.meta;

      let scene = { ...state.scene };
      const setDressings = Array.from(state.scene.setDressings);
      const setDressingIndex = setDressings.findIndex((b: any) => b.id === setDressing.id);
      setDressings.splice(setDressingIndex, 1);
      const props = Array.from(state.scene.props);
      const newId = action.payload.data.propId;
      props.push({ ...setDressing, id: newId });
      sortByName(props);
      scene = { ...scene, props, setDressings };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_CHANGE_SET_DRESSING_TO_PROP + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_CHANGE_COSTUME_TO_PROP + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case BREAKDOWN_CHANGE_COSTUME_TO_PROP + "_FULFILLED": {
      const { costume } = action.meta;

      let scene = { ...state.scene };
      const costumes = Array.from(state.scene.costumes);
      const costumeIndex = costumes.findIndex((b: any) => b.id === costume.id);
      costumes.splice(costumeIndex, 1);
      const props = Array.from(state.scene.props);
      const newId = action.payload.data.propId;
      props.push({ ...costume, id: newId });
      sortByName(props);
      scene = { ...scene, props, costumes };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_CHANGE_COSTUME_TO_PROP + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_CHANGE_COSTUME_TO_PROP + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case BREAKDOWN_CHANGE_COSTUME_TO_PROP + "_FULFILLED": {
      const { costume } = action.meta;

      let scene = { ...state.scene };
      const costumes = Array.from(state.scene.costumes);
      const costumeIndex = costumes.findIndex((b: any) => b.id === costume.id);
      costumes.splice(costumeIndex, 1);
      const props = Array.from(state.scene.props);
      const newId = action.payload.data.propId;
      props.push({ ...costume, id: newId });
      sortByName(props);
      scene = { ...scene, props, costumes };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_CHANGE_COSTUME_TO_PROP + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case REMOVE_CHARACTER_FROM_SCENE + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case REMOVE_CHARACTER_FROM_SCENE + "_FULFILLED": {
      const { characterId } = action.meta;
      let scene = { ...state.scene };
      const characters = Array.from(state.scene.characters);
      const characterIndex = characters.findIndex((b: any) => b.id === characterId);
      characters.splice(characterIndex, 1);
      scene = { ...scene, characters };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case REMOVE_CHARACTER_FROM_SCENE + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case REMOVE_BACKGROUND_CHARACTER_FROM_SCENE + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case REMOVE_BACKGROUND_CHARACTER_FROM_SCENE + "_FULFILLED": {
      const { characterId } = action.meta;
      let scene = { ...state.scene };
      const backgroundCharacters = Array.from(state.scene.backgroundCharacters);
      const backgroundCharacterIndex = backgroundCharacters.findIndex((b: any) => b.id === characterId);
      backgroundCharacters.splice(backgroundCharacterIndex, 1);
      scene = { ...scene, backgroundCharacters };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case REMOVE_BACKGROUND_CHARACTER_FROM_SCENE + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_REMOVE_PROP_FROM_SCENE + "_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case BREAKDOWN_REMOVE_PROP_FROM_SCENE + "_FULFILLED": {
      const { propId } = action.meta;
      let scene = { ...state.scene };
      const props = Array.from(state.scene.props);
      const propIndex = props.findIndex((b: any) => b.id === propId);
      props.splice(propIndex, 1);
      scene = { ...scene, props };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_REMOVE_PROP_FROM_SCENE + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_REMOVE_SET_DRESSING_FROM_SCENE + "_PENDING":
    case BREAKDOWN_REMOVE_COSTUME_FROM_SCENE + "_PENDING":
    case BREAKDOWN_REMOVE_MAKEUP_FROM_SCENE + "_PENDING":
    case BREAKDOWN_REMOVE_VISUAL_EFFECT_FROM_SCENE + "_PENDING":
      {
        return {
          ...state,
          loading: true
        };
      }

    case BREAKDOWN_REMOVE_COSTUME_FROM_SCENE + "_REJECTED":
    case BREAKDOWN_REMOVE_MAKEUP_FROM_SCENE + "_REJECTED":
    case BREAKDOWN_REMOVE_VISUAL_EFFECT_FROM_SCENE + "_REJECTED":
    case BREAKDOWN_REMOVE_SET_DRESSING_FROM_SCENE + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.data?.errors
      };
    }

    case BREAKDOWN_REMOVE_SET_DRESSING_FROM_SCENE + "_FULFILLED": {
      const { setDressingId } = action.meta;
      let scene = { ...state.scene };
      const setDressings = Array.from(state.scene.setDressings);
      const setDressingIndex = setDressings.findIndex((b: any) => b.id === setDressingId);
      setDressings.splice(setDressingIndex, 1);
      scene = { ...scene, setDressings };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_REMOVE_COSTUME_FROM_SCENE + "_FULFILLED": {
      const { costumeId } = action.meta;
      let scene = { ...state.scene };
      const costumes = Array.from(state.scene.costumes);
      const costumeIndex = costumes.findIndex((b: any) => b.id === costumeId);
      costumes.splice(costumeIndex, 1);
      scene = { ...scene, costumes };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_REMOVE_MAKEUP_FROM_SCENE + "_FULFILLED": {
      const { makeupId } = action.meta;
      let scene = { ...state.scene };
      const makeups = Array.from(state.scene.makeups);
      const makeupIndex = makeups.findIndex((b: any) => b.id === makeupId);
      makeups.splice(makeupIndex, 1);
      scene = { ...scene, makeups };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_REMOVE_VISUAL_EFFECT_FROM_SCENE + "_FULFILLED": {
      const { visualEffectId } = action.meta;
      let scene = { ...state.scene };
      const visualEffects = Array.from(state.scene.visualEffects);
      const visualEffectIndex = visualEffects.findIndex((b: any) => b.id === visualEffectId);
      visualEffects.splice(visualEffectIndex, 1);
      scene = { ...scene, visualEffects };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    case BREAKDOWN_REMOVE_SOUND_EFFECT_FROM_SCENE + "_FULFILLED": {
      const { soundEffectId } = action.meta;
      let scene = { ...state.scene };
      const soundEffects = Array.from(state.scene.soundEffects);
      const soundEffectIndex = soundEffects.findIndex((b: any) => b.id === soundEffectId);
      soundEffects.splice(soundEffectIndex, 1);
      scene = { ...scene, soundEffects };
      return {
        ...state,
        scene,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
