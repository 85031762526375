import getApiClient from "../../../../shared/api";

export const FETCH_SHOOTING_DAY_MAKEUPS = "FETCH_SHOOTING_DAY_MAKEUPS";

export function fetchShootingDayMakeups(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SHOOTING_DAY_MAKEUPS,
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingDayId}/Makeups`
      )
    });
  };
}