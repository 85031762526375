import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "./../../shared/loader";
import Onboarding from '../../shared/onboarding/onboarding';
import {
  fetchItem,
  assignScenesToItem,
  clearRedirect,
  fetchAllScenes,
  fetchAllCharacters,
  fetchAllSceneSettings,
  fetchScenesAssignedToItem,
  findInScript,
  clearSceneSearchResults,
  setSceneAssignedToItem,
  ITEM_SEARCH_SCRIPT,
  NAME_PLURAL_FOR_UI,
  ITEM_FRONEND_URL
} from "./ducks/actions";
import Form from "./scenes-form";
import {
  searchScenes,
  SEARCH_SCENES_FOR_ITEMS
} from "../scenes/search/ducks/actions";
import SearchScenesForm from "../scenes/search/search-scenes-form";
import ScriptSearch from "../script/search/script-search";
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from './ducks/reducer';

function ItemScenes(props) {
  const itemId = props.itemId || props.match.params.itemId;
  const filmId = props.filmId || props.match.params.filmId;
  const {
    prop,
    fetchAllScenes,
    fetchItem,
    fetchAllCharacters,
    fetchAllSceneSettings,
    fetchScenesAssignedToItem,
    assignScenesToItem,
    setSceneAssignedToItem,
    loadingProp,
    allScenes,
    searchScenes,
    findInScript,
    clearSceneSearchResults,
    errors,
    readOnly,
    sceneId,
    embedded,
    onboardingSteps,
    loadingSceneSettings,
    findingInScript,
    searchingScenes
  } = props;

  const invalidIds = ["add", "scenes"];

  // const allSelectedScenes = (props.selectedSceneIds || []).concat(
  //   props.searchedScenes?.map((s) => s.id)
  // );

  useEffect(() => {
    fetchItem(filmId, itemId);
    fetchAllScenes(filmId);
    fetchAllCharacters(filmId);
    fetchAllSceneSettings(filmId);
    if (!sceneId) {
      fetchScenesAssignedToItem(filmId, itemId);
    }
  }, [filmId, itemId]);

  useEffect(() => {
    if (sceneId) {
      setSceneAssignedToItem(sceneId);
    }
  }, [sceneId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/${ITEM_FRONEND_URL}/`;
      return <Redirect to={url} />;
    } else {
      props.clearRedirect();
      return <Redirect to={props.redirect.to} />;
    }
  }

  const onFindInScript = (e) => {
    e.preventDefault();
    findInScript(filmId, prop.name);
  };

  if (invalidIds.indexOf(itemId) > -1) {
    return null;
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderForm() {
    return (
      <div className="blade-content">
        <h2 className="menu-label">{NAME_PLURAL_FOR_UI} Scenes</h2>

        {!readOnly && <div className={`box mt-1 ${OnboardingStep1ClassName}`}>
          <SearchScenesForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            visualEffectId={itemId}
            loading={loadingProp}
            onSubmit={onSearchScenes}
            findInScript={onFindInScript}
            allCharacters={props.allCharacters}
            allSceneSettings={props.allSceneSettings}
            propName={prop?.name}
            searchingScenes={searchingScenes}
            scenes={allScenes}
            showCharacters={true}
            showScriptDays={false}
            showSceneSettings={true}
          />
        </div>}
        {!readOnly && <div className={`box mt-1 ${OnboardingStep2ClassName}`}>
          <ScriptSearch filmId={filmId} text={prop?.name} typeToDispatch={ITEM_SEARCH_SCRIPT} findingInScript={findingInScript} />
        </div>}
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ selectedSceneIds: props.selectedSceneIds }}
          loading={loadingSceneSettings || loadingProp}
          onSubmit={onUpdatePropScenes}
          reduxFormValues={props.reduxFormValues}
          allScenes={props.allScenes}
          searchedSceneIds={props.searchedScenes?.map((s) => s.id)}
          selectSearchedScenes={props.selectSearchedScenes}
          clearSceneSearchResults={clearSceneSearchResults}
          readOnly={readOnly}
        />
        <Onboarding onboardingSteps={onboardingSteps} section={`${NAME_PLURAL_FOR_UI} Scenes`} />
      </div>
    );
  }

  function onSearchScenes(params) {
    searchScenes(
      SEARCH_SCENES_FOR_ITEMS,
      filmId,
      params
    );
  }

  function onUpdatePropScenes(propScenes) {
    assignScenesToItem(filmId, itemId, propScenes.selectedSceneIds);
  }

  return allScenes?.length ? renderForm() : renderLoading();
}

function mapStateToProps(state) {
  const { soundEffectsState, form } = state;
  return {
    prop: soundEffectsState.prop,
    allScenes: soundEffectsState.scenes,
    allCharacters: soundEffectsState.characters,
    allSceneSettings: soundEffectsState.sceneSettings,
    selectedSceneIds: soundEffectsState.selectedSceneIds,
    loadingSceneSettings: soundEffectsState.loadingSceneSettings,
    readOnly: soundEffectsState.readOnly,
    searchedScenes: soundEffectsState.searchedScenes,
    selectSearchedScenes: soundEffectsState.selectSearchedScenes,
    findingInScript: soundEffectsState.findingInScript,
    searchingScenes: soundEffectsState.searchingScenes,
    errors: soundEffectsState.errors,
    redirect: soundEffectsState.redirect,
    onboardingSteps: soundEffectsState.onboardingSteps,
    reduxFormValues: form?.propScenesForm?.values
  };
}

export default
  connect(mapStateToProps, {
    fetchItem,
    fetchAllScenes,
    fetchAllCharacters,
    fetchAllSceneSettings,
    fetchScenesAssignedToItem,
    clearRedirect,
    assignScenesToItem,
    searchScenes,
    findInScript,
    clearSceneSearchResults,
    setSceneAssignedToItem
  })(ItemScenes);
