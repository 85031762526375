import { FETCH_SHOOTING_DAY_COSTUMES } from "./actions";

const defaultState = {
  costumes: [],
  loading: true,
  readOnly: false,
  errors: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_SHOOTING_DAY_COSTUMES + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_SHOOTING_DAY_COSTUMES + "_FULFILLED": {
      return {
        ...state,
        costumes: action.payload.data.costumes,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_SHOOTING_DAY_COSTUMES + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
