import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/loader";
import { fetchFeatureToggles } from "./ducks/actions";
import List from "./list";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";

function ListCostumesContainer(props) {
  const { fetchFeatureToggles, featureToggles, loading, errors, readOnly } = props;

  useEffect(() => {
    fetchFeatureToggles();
  }, [fetchFeatureToggles]);

  function renderLoading() {
    return <Loader />;
  }

  function renderChangeLogs(featureToggles) {
    return (
      <ScrollIntoView
        className="blade-menu"
        path="/admin/feature-toggles">
        <List featureToggles={featureToggles} />
      </ScrollIntoView>
    );
  }

  return loading ? renderLoading() : renderChangeLogs(featureToggles);
}

function mapStateToProps(state) {
  const { featureToggles, loading, errors, readOnly } = state.featureTogglesState;
  return { featureToggles, loading, errors, readOnly };
}

export default connect(mapStateToProps, {
  fetchFeatureToggles
})(ListCostumesContainer);
