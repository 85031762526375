import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchItemsInScene, ITEM_FRONEND_URL } from "./ducks/actions";
import List from "./list";
import AddIcon from "../../icons/add";
import { Link } from "react-router-dom";
import { stat } from "fs";

function ListItemsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchItemsInScene, loading, sceneItems, errors, readOnly } = props;

  useEffect(() => {
    fetchItemsInScene(filmId, sceneId);
  }, [filmId, sceneId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderVisualEffects(items, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List items={items} filmId={filmId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <Link
          to={`/films/${filmId}/scenes/${sceneId}/${ITEM_FRONEND_URL}/add`}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Assign Existing
        </Link>}
        {!readOnly && <Link
          to={{ pathname: `/films/${filmId}/${ITEM_FRONEND_URL}/add`, state: { sceneId, returnUrl: `/films/${filmId}/scenes/${sceneId}/${ITEM_FRONEND_URL}` } }}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add New
        </Link>}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderVisualEffects(sceneItems, filmId, sceneId);
}

function mapStateToProps(state) {
  const { soundEffectState } = state.scenesState;
  return {
    sceneItems: soundEffectState.items,
    loading: soundEffectState.loading,
    errors: soundEffectState.errors,
    readOnly: soundEffectState.readOnly
  };
}

export default connect(mapStateToProps, {
  fetchItemsInScene
})(ListItemsContainer);
