import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../../shared/loader";
import InvlidShootingDayNotification from "../invalid-shooting-day-notification";
import { fetchShootingDaySetDressings } from "./ducks/actions";
import EditSetDressingsForm from "./edit-form";

function ListShootingDayCrewContainer(props) {
  const { filmId, shootingDayId } = props.match.params;
  const {
    fetchShootingDaySetDressings,
    loading,
    setDressings,
    errors,
    readOnly
  } = props;

  useEffect(() => {
    fetchShootingDaySetDressings(filmId, shootingDayId);
  }, [filmId, shootingDayId]);

  function renderLoading() {
    return <Loader />;
  }

  const anySetDressings = setDressings?.length > 0;

  function renderShootingDays(setDressings, filmId) {
    return (
      <div className="blade-content wide">
        <p className="menu-label">Set Dressings</p>
        {!anySetDressings && <div>None required on this day</div>}
        {anySetDressings && <EditSetDressingsForm
          errors={errors}
          mode="edit"
          filmId={filmId}
          initialValues={{ setDressings }}
          loading={loading}
          readOnly={readOnly}
          {...props}
        />}
      </div>
    );
  }

  if (!props.shootingDay?.hasValidSchedule) {
    return <InvlidShootingDayNotification />;
  }

  return loading ? renderLoading() : renderShootingDays(setDressings, filmId);
}

function mapStateToProps(state) {
  const { rootState, setDressingsState } = state.shootingdaysState;
  return {
    shootingDay: rootState.shootingday,
    readOnly: setDressingsState.readOnly,
    setDressings: setDressingsState.setDressings,
    loading: setDressingsState.loading,
    errors: setDressingsState.errors
  };
}

export default connect(mapStateToProps, {
  fetchShootingDaySetDressings
})(ListShootingDayCrewContainer);
