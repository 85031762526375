import { NavLink } from "react-router-dom";

const FeatureTogglesList = ({ featureToggles }) => {
  return (
    <aside className="menu">
      <p className="menu-label">Feature Toggles</p>
      <ul className="menu-list">
        {featureToggles?.map((featureToggle, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/admin/feature-toggles/${featureToggle.name}/edit`}
                title={featureToggle.name}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/admin/feature-toggles/${featureToggle.name}`
                  );
                }}>
                {featureToggle.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default FeatureTogglesList;
