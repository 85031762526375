import { useEffect, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import DeleteIcon from "../icons/delete";
import EditIcon from "../icons/edit";
import confirm from "../../shared/modal/confirm";
import TextareaAutosize from 'react-textarea-autosize';

const Task = ({ task,
    index,
    textChanged,
    textFinishedEditing,
    editTask,
    deleteTask,
    showMenuOnTaskId,
    showMenuOnTask,
    collaborators,
    assignTask,
    readOnly }) => {
    const onTextChanged = (e) => {
        if (e.nativeEvent.inputType === "insertLineBreak") {
            textFinishedEditing(task);
            return;
        }

        textChanged(task.id, e.target.value);
    };

    const onTextFinishedEditing = () => {
        textFinishedEditing(task);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        textFinishedEditing(task);
    }

    const onEdit = () => {
        editTask(task);
    }

    const onDelete = () => {
        confirm(() => deleteTask(task), task.title);
    }

    const inputRef = useRef<any>(null);

    useEffect(() => {
        if (task.edit) {
            inputRef?.current?.focus();
        }
    }, [task.edit]);

    const onMenuClick = () => {
        showMenuOnTask(task.id, showMenuOnTaskId !== task.id);
    };

    const onAssignTask = (collaboratorId, isOwner) => {
        assignTask(task.id, collaboratorId, isOwner);
    };

    const assigneeInitials = task.assignee ? task.assignee?.firstName?.substring(0, 1) + task.assignee?.lastName?.substring(0, 1) : "";
    const unSelectedCollaborators = collaborators?.filter(c => c.id !== task.assignee?.id);

    return (
        <Draggable draggableId={task.id} index={index} isDragDisabled={readOnly || task.saving || task.edit}>
            {(provided, snapshot) => (
                <div className={`task ${snapshot.isDragging ? "dragging" : ""} ${task.saving ? "saving" : ""} ${task.error ? "is-danger" : ""}`}  {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}>
                    <div className='task-content-container'>
                        <div className='task-content'>
                            {task.edit && <form onSubmit={onFormSubmit}><TextareaAutosize ref={inputRef} className='input task-text' value={task.title} onChange={onTextChanged} onBlur={onTextFinishedEditing} disabled={task.saving} /></form>}
                            {!task.edit && <>{task.title}</>}
                        </div>
                        <div className='task-content-button'>
                            {/* <a><EditIcon onClick={onEdit} className={`white-icon`} /></a>
                                <a><DeleteIcon onClick={onDelete} className={`white-icon`} /></a> */}
                            {!readOnly && <div className={`dropdown ${showMenuOnTaskId === task.id && !(task.saving || task.edit) ? "is-active" : ""}`}>
                                <div className="dropdown-trigger">
                                    <button className="button" onClick={onMenuClick}>
                                        <span>...</span>
                                    </button>
                                </div>
                                <div className="dropdown-menu">
                                    <div className="dropdown-content">
                                        <a className="dropdown-item" onClick={onEdit}>
                                            <EditIcon onClick={onEdit} className={`white-icon`} />Edit
                                        </a>
                                        <a className="dropdown-item" onClick={onDelete}>
                                            <DeleteIcon onClick={onDelete} className={`white-icon`} /> Delete
                                        </a>


                                        {((unSelectedCollaborators && unSelectedCollaborators.length > 0) || assigneeInitials) && <div className="dropdown-trigger">
                                            <a className="dropdown-item popout">
                                                <span>Assign to</span>
                                                &nbsp;
                                                <span className='arrow'>{' >'}</span>
                                            </a>

                                            <div className="dropdown-content">
                                                {assigneeInitials && <a className="dropdown-item" onClick={() => onAssignTask(null, false)}>
                                                    Unassign
                                                </a>}
                                                {(unSelectedCollaborators || []).map(collaborator => (
                                                    <a className="dropdown-item" onClick={() => onAssignTask(collaborator.id, collaborator.isOwner)}>
                                                        {collaborator.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>}
                            {assigneeInitials &&
                                <div className="assignee-circle" style={{ backgroundColor: task.assignee.backgroundColor }} title={task.assignee.firstName + " " + task.assignee.lastName}>
                                    {assigneeInitials}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    )
}

export default Task
