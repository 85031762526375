import getApiClient from "./../../../../shared/api";
import { showInfo, showError, clearNotifications } from "./../../../../notifications/ducks/actions";

export const ITEM_NAME = "SOUND_EFFECT";
export const ITEM_API_URL = "soundEffect";
export const NAME_FOR_MESSAGES = "sound effect";
export const NAME_FOR_UI = "Sound Effect";
export const NAME_PLURAL_FOR_UI = "Sound Effects";
export const ITEM_FRONEND_URL = "sound-effects";

export const ADD_ITEM_TO_SCENE = "ADD_" + ITEM_NAME + "_TO_SCENE";
export const CLEAR_SCENE_ITEM_REDIRECT = "CLEAR_SCENE_" + ITEM_NAME + "_REDIRECT";
export const FETCH_ITEMS_IN_SCENE = "FETCH_" + ITEM_NAME + "S_IN_SCENE";

export function fetchItemsInScene(filmId, sceneId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ITEMS_IN_SCENE,
      payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/${ITEM_API_URL}s`),
    });
  };
}

export function addItemToScene(filmId, sceneId, item, fromBreakdown) {
  return (dispatch) => {
    dispatch({
      type: ADD_ITEM_TO_SCENE,
      payload: getApiClient().post(`/film/${filmId}/scene/${sceneId}/${ITEM_API_URL}/${item.itemId}`),
      meta: { fromBreakdown }
    })
      .then((response) => {
        dispatch(showInfo(`Added ${NAME_FOR_MESSAGES} to scene`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding ${NAME_FOR_MESSAGES} to scene`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SCENE_ITEM_REDIRECT
    });
  };
}
