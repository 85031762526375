import {
  clearNotifications,
  showError,
  showInfo
} from "../../../../notifications/ducks/actions";
import getApiClient from "../../../../shared/api";
import { getActionToUploadImagesAndSaveEntity } from "../../../../shared/image-action-creator";

export const CHANGE_COSTUME_TO_PROP = "CHANGE_COSTUME_TO_PROP";
export const COSTUME_SET_RETURN_URL = "COSTUME_SET_RETURN_URL";
export const ASSIGN_SCENES_TO_COSTUME = "ASSIGN_SCENES_TO_COSTUME";

export function fetchCostumes(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_COSTUMES",
      payload: getApiClient().get(`/film/${filmId}/costume`)
    });
  };
}

export function setReturnUrl(returnUrl) {
  return (dispatch) => {
    dispatch({
      type: COSTUME_SET_RETURN_URL,
      meta: { returnUrl }
    });
  };
}

export function clearCostume() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_COSTUME"
    });
  };
}

export function setName(name) {
  return (dispatch) => {
    dispatch({
      type: "COSTUME_SET_NAME",
      meta: { name }
    });
  };
}


export function fetchNextCostumeNumber(filmId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NEXT_COSTUME_NUMBER"
    });
  };
}

export function fetchCostume(filmId, costumeId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SINGLE_COSTUME",
      payload: getApiClient().get(`/film/${filmId}/costume/${costumeId}`)
    });
  };
}

export function updateCostume(filmId, costume) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SINGLE_COSTUME",
      payload: getApiClient().put(
        `/film/${filmId}/costume/${costume.id}`,
        costume
      ),
      meta: { costume }
    })
      .then((response) => {
        dispatch(showInfo(`Updated costume ${costume.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating costume ${costume.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function changeCostumeToProp(filmId, costume) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_COSTUME_TO_PROP,
      payload: getApiClient().put(`/film/${filmId}/costume/${costume.id}/ChangeToProp`, costume),
      meta: { costume }
    })
      .then((response) => {
        dispatch(showInfo(`Changed Costume ${costume.name} to Prop`));
      })
      .catch((response) => {
        dispatch(showError(`Error changing Costume ${costume.name} to Prop`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

function addCostumeWithImageUrls(dispatch, costume, filmId) {
  dispatch({
    type: "ADD_SINGLE_COSTUME",
    payload: getApiClient().post(`/film/${filmId}/costume/`, costume),
    meta: { costume }
  })
    .then((response) => {
      dispatch(showInfo(`Added costume ${costume.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function updateCostumeWithImageUrls(dispatch, costume, filmId) {
  dispatch({
    type: "UPDATE_SINGLE_COSTUME",
    payload: getApiClient().put(
      `/film/${filmId}/costume/${costume.id}`,
      costume
    ),
    meta: { costume }
  })
    .then((response) => {
      dispatch(showInfo(`Updated costume ${costume.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export const uploadImagesAndSaveCostume = getActionToUploadImagesAndSaveEntity(
  addCostumeWithImageUrls,
  updateCostumeWithImageUrls
);

export function addCostume(filmId, costume) {
  return (dispatch) => {
    dispatch({
      type: "ADD_SINGLE_COSTUME",
      payload: getApiClient().post(`/film/${filmId}/costume/`, costume),
      meta: { costume }
    })
      .then((response) => {
        dispatch(showInfo(`Added costume ${costume.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding costume`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteCostume(filmId, costume) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_SINGLE_COSTUME",
      payload: getApiClient().delete(`/film/${filmId}/costume/${costume.id}`),
      meta: { costume }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted costume ${costume.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting costume ${costume.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addImageFiles(imageFiles) {
  return (dispatch) => {
    dispatch({
      type: "ADD_COSTUME_IMAGE_FILES",
      meta: { imageFiles }
    });
  };
}

export function addImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "ADD_COSTUME_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_COSTUME_IMAGE_URL",
      meta: { url }
    });
  };
}

export function deleteImageFile(fileName) {
  return (dispatch) => {
    dispatch({
      type: "DELETE_COSTUME_IMAGE_FILE",
      meta: { fileName }
    });
  };
}

export function mainImageUrlChanged(url) {
  return (dispatch) => {
    dispatch({
      type: "COSTUME_MAIN_IMAGE_CHANGED",
      meta: { url }
    });
  };
}

export function clearRedirect(dispatch) {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT"
    });
  };
}

export function fetchAllScenes(filmId, characterIds, backgroundCharacterIds) {
  let filters: string[] = [];

  if (characterIds?.length) {
    filters.push(`characterIds=${characterIds.join(",")}`);
  }

  if (backgroundCharacterIds?.length) {
    filters.push(`backgroundCharacterIds=${backgroundCharacterIds.join(",")}`);
  }

  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENES_FOR_COSTUMES",
      payload: getApiClient().get(
        `/film/${filmId}/scene/withcharacters?${filters.join("&")}`
      )
    });
  };
}

export function setLoadingSceneSettings() {
  return (dispatch) => {
    dispatch({
      type: "SET_LOADING_SCENE_SETTINGS"
    });
  };
}

export function fetchScenesAssignedToCostume(filmId, costumeId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SCENES_ASSIGNED_TO_COSTUMES",
      payload: getApiClient().get(
        `/film/${filmId}/Costume/${costumeId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedCotume(sceneId) {
  return (dispatch) => {
    dispatch({
      type: "SET_SCENE_ASSIGNED_TO_COSTUME",
      meta: { sceneId }
    });
  };
}

export function assignScenesToCostume(filmId, costumeId, sceneIds) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_SCENES_TO_COSTUME,
      payload: getApiClient().put(
        `/film/${filmId}/costume/${costumeId}/AssignToScenes`,
        { sceneIds }
      ),
      meta: { costumeId }
    })
      .then((response) => {
        dispatch(showInfo(`Assigned Costume to Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error assigning Costume to Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchAllCharacters(filmId, sceneId) {
  return (dispatch) => {
    if (sceneId) {
      dispatch({
        type: "FETCH_ALL_CHARACTERS_FOR_COSTUMES",
        payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/characters`)
      });
    } else {
      dispatch({
        type: "FETCH_ALL_CHARACTERS_FOR_COSTUMES",
        payload: getApiClient().get(`/film/${filmId}/character`)
      });
    }
  };
}

export function fetchAllBackgroundCharacters(filmId, sceneId) {
  return (dispatch) => {
    if (sceneId) {
      dispatch({
        type: "FETCH_ALL_BACKGROUND_CHARACTERS_FOR_COSTUMES",
        payload: getApiClient().get(`/film/${filmId}/Scene/${sceneId}/backgroundCharacters`)
      });
    } else {
      dispatch({
        type: "FETCH_ALL_BACKGROUND_CHARACTERS_FOR_COSTUMES",
        payload: getApiClient().get(`/film/${filmId}/backgroundCharacter`)
      });
    }
  };
}

export function fetchAllSceneSettings(filmId, characterIds, backgroundCharacterIds) {
  let filters: string[] = [];

  if (characterIds?.length) {
    filters.push(`characterIds=${characterIds.join(",")}`);
  }

  if (backgroundCharacterIds?.length) {
    filters.push(`backgroundCharacterIds=${backgroundCharacterIds.join(",")}`);
  }

  return (dispatch) => {
    dispatch({
      type: "FETCH_ALL_SCENE_SETTINGS_FOR_COSTUMES",
      payload: getApiClient().get(
        `/film/${filmId}/scenesetting/withcharacters?${filters.join("&")}`
      )
    });
  };
}

export function fetchScriptDays(filmId, characterIds, backgroundCharacterIds) {
  let filters: string[] = [];

  if (characterIds?.length) {
    filters.push(`characterIds=${characterIds.join(",")}`);
  }

  if (backgroundCharacterIds?.length) {
    filters.push(`backgroundCharacterIds=${backgroundCharacterIds.join(",")}`);
  }

  return (dispatch) => {
    dispatch({
      type: "FETCH_SCRIPT_DAYS_FOR_COSTUMES",
      payload: getApiClient().get(
        `/film/${filmId}/scene/scriptDays/search?${filters.join("&")}`
      )
    });
  };
}

export function findInScript(
  filmId,
  costumeName
) {
  return (dispatch) => {
    dispatch({
      type: "SEARCH_SCRIPT_FOR_COSTUMES",
      payload: getApiClient().get(
        `/film/${filmId}/script/search/${costumeName}`
      ),
      meta: { selectSearchedScenes: true }
    });
  };
}

export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_SCENES_FOR_COSTUMES"
    });
  };
}
