import {
  clearNotifications,
  showError,
  showInfo
} from "../../../notifications/ducks/actions";
import getApiClient from "../../../shared/api";

export const FETCH_FEATURE_TOGGLES = "FETCH_FEATURE_TOGGLES";
export const FETCH_FEATURE_TOGGLE = "FETCH_FEATURE_TOGGLE";
export const UPDATE_FEATURE_TOGGLE = "UPDATE_FEATURE_TOGGLE";
export const FETCH_USERS_SUMMARY = "FETCH_USERS_SUMMARY";

export function fetchFeatureToggles() {
  return (dispatch) => {
    dispatch({
      type: FETCH_FEATURE_TOGGLES,
      payload: getApiClient().get(`/featureToggle`)
    });
  };
}

export function fetchFeatureToogle(name) {
  return (dispatch) => {
    dispatch({
      type: FETCH_FEATURE_TOGGLE,
      payload: getApiClient().get(`/featureToggle/${name}`)
    });
  };
}

export function updateFeatureToggle(name, featureRequest) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_TOGGLE,
      payload: getApiClient().put(`/featureToggle/${name}`, featureRequest),
      meta: { featureRequest }
    })
      .then((response) => {
        dispatch(showInfo(`Updated feature toggle ${featureRequest.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating feature toggle ${featureRequest.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchUsers() {
  return (dispatch) => {
    dispatch({
      type: FETCH_USERS_SUMMARY,
      payload: getApiClient().get(`/admin/users/summary`)
    });
  };
}


export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REDIRECT_CHANGE_LOG"
    });
  };
}
