import React, { CSSProperties, Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { fetchUserFilm } from "./film/ducks/actions";
import FilmIcon from './icons/film';
import OverviewIcon from './icons/overview';
import BreakdownIcon from './icons/breakdown';
import ScriptIcon from './icons/script';
import CollaboratorsIcon from './icons/collaborators';
import TasksIcon from './icons/tasks';
import CharactersIcon from './icons/characters';
import BackgroundCharactersIcon from './icons/background-characters';
import SceneSettingsIcon from './icons/scene-settings';
import ScenesIcon from './icons/scenes';
import ActorsIcon from './icons/actors';
import BackgroundActorsIcon from './icons/background-actors';
import LocationsIcon from './icons/locations';
import CostumesIcon from './icons/costumes';
import MakeupIcon from './icons/makeup';
import PropsIcon from './icons/props';
import SetDressingsIcon from './icons/set-dressings';
import EquipmentIcon from './icons/equipment';
import CrewIcon from './icons/crew';
import SchedulingIcon from './icons/scheduling';
import BudgetIcon from './icons/budget';
import ShootingDaysIcon from './icons/shooting-days';
import VisualEffectsIcon from './icons/visual-effects';
import SoundEffectsIcon from './icons/sound-effects';
import LeftArrow from "./icons/left-arrow";

const FilmMenu = (props) => {
  const { userFilm } = props;
  const filmId = props.match.params.filmId;

  const { t } = useTranslation(['menu']);

  const minWidth = 768;
  const [menuOpen, setMenuOpen] = useState(window.innerWidth > minWidth);
  const [menuOpenManual, setMenuOpenManual] = useState(false);


  const openMenu = () => {
    if (!menuOpenManual) {
      const isDesktop = window.innerWidth > minWidth;
      if (isDesktop !== menuOpen) {
        setMenuOpen(isDesktop);
      };
    }
  };

  useEffect(() => {
    if (!menuOpenManual) {
      window.addEventListener("resize", openMenu, false);
    } else {
      window.removeEventListener("resize", openMenu, false);
    }

  }, [menuOpen, menuOpenManual]);

  useEffect(() => {
    if (filmId.toLowerCase() !== "add") {
      props.fetchUserFilm(filmId);
    }
  }, [filmId]);

  if (filmId.toLowerCase() === "add") {
    return null;
  }

  const minimizeMenu = () => {
    setMenuOpenManual(true);
    setMenuOpen(!menuOpen);
  };



  const menu = [
    {
      text: "Film",
      shortText: "Film",
      menu: [
        { text: t('Overview'), name: "Overview", path: `/films/${filmId}/overview`, icon: <OverviewIcon className="menu-icon" /> },
        { text: t("Film"), name: "Film", path: `/films/${filmId}/edit`, icon: <FilmIcon className="menu-icon" /> },
        {
          text: t("Breakdown"), name: "Breakdown", path: `/films/${filmId}/breakdown/overview`, icon: <BreakdownIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/breakdown`
            );
          }
        }
      ]
    },
    {
      text: "Story World",
      shortText: "Story",
      menu: [
        {
          text: t("Characters"), name: "Characters", path: `/films/${filmId}/characters/overview`, icon: <CharactersIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/characters`
            );
          }
        },
        {
          text: t("Background Characters"), name: "Background Characters", path: `/films/${filmId}/background-characters/overview`, icon: <BackgroundCharactersIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/background-characters`
            );
          }
        },
        {
          text: t("Scene Settings"), name: "Scene Settings", path: `/films/${filmId}/scene-settings/overview`, icon: <SceneSettingsIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/scene-settings`
            );
          }
        },
        { text: t("Scenes"), name: "Scenes", path: `/films/${filmId}/scenes`, icon: <ScenesIcon className="menu-icon" /> }
      ]
    },
    {
      text: "Real World",
      shortText: "Real",
      menu: [
        {
          text: t("Actors"), name: "Actors", path: `/films/${filmId}/actors/overview`, icon: <ActorsIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/actors`
            );
          }
        },
        {
          text: t("Background Actors"), name: "Background Actors", path: `/films/${filmId}/background-actors/overview`, icon: <BackgroundActorsIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/background-actors`
            );
          }
        },
        {
          text: t("Shooting Locations"), name: "Shooting Locations", path: `/films/${filmId}/locations/overview`, icon: <LocationsIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/locations`
            );
          }
        },
        {
          text: t("Costumes"), name: "Costumes", path: `/films/${filmId}/costumes/overview`, icon: <CostumesIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/costumes`
            );
          }
        },
        {
          text: t("Hair and Makeup"), name: "Makeup", path: `/films/${filmId}/makeup`, icon: <MakeupIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/makeup`
            );
          }
        },
        {
          text: t("Props"), name: "Props", path: `/films/${filmId}/props/overview`, icon: <PropsIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/props`
            );
          }
        },
        {
          text: t("Set Dressings"), name: "Set Dressings", path: `/films/${filmId}/set-dressings/overview`, icon: <SetDressingsIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/set-dressings`
            );
          }
        },
        {
          text: t("Visual Effects"), name: "Visual Effects", path: `/films/${filmId}/visual-effects`, icon: <VisualEffectsIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/visual-effects`
            );
          }
        }
      ]
    },
    {
      text: "Production",
      shortText: "Prod",
      menu: [
        {
          text: t("Equipment"), name: "Equipment", path: `/films/${filmId}/equipment-category/overview`, icon: <EquipmentIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/equipment-category`
            );
          }
        },
        {
          text: t("Crew"), name: "Crew", path: `/films/${filmId}/departments/overview`, icon: <CrewIcon className="menu-icon" />, isActive: (match, location) => {
            return location.pathname.startsWith(
              `/films/${filmId}/departments`
            );
          }
        },
        // { text: t("Scheduling"), path: `/films/${filmId}/availability`, icon: <SchedulingIcon className="menu-icon" /> },
        { text: t("Budget"), name: "Budget", path: `/films/${filmId}/budget`, icon: <BudgetIcon className="menu-icon" /> },
        { text: t("Shooting Days"), name: "Shooting Days", path: `/films/${filmId}/shooting-days`, icon: <ShootingDaysIcon className="menu-icon" /> }
      ]
    }
  ];

  if (userFilm?.hasScript) {
    menu[0].menu.push({ text: t("Script"), name: "Script", path: `/films/${filmId}/script`, icon: <ScriptIcon className="menu-icon" /> });
  }

  if (userFilm?.hasCommercialScript) {
    menu[0].menu.push({ text: t("Script"), name: "Script", path: `/films/${filmId}/commercial-script`, icon: <ScriptIcon className="menu-icon" /> });
  }

  if (true) {
    menu[0].menu.push({
      text: t("Collaborators"), name: "Collaborators", path: `/films/${filmId}/collaborators/overview`, icon: <CollaboratorsIcon className="menu-icon" />, isActive: (match, location) => {
        return location.pathname.startsWith(
          `/films/${filmId}/collaborators`
        );
      }
    });
  }

  if (userFilm?.tasksEnabled) {
    menu[0].menu.push({ text: t("Tasks"), name: "Tasks", path: `/films/${filmId}/tasks`, icon: <TasksIcon className="menu-icon" /> });
  }

  if (userFilm?.schedulingEnabled) {
    menu[3].menu.splice(2, 0, { text: t("Scheduling"), name: "Scheduling", path: `/films/${filmId}/scheduling`, icon: <SchedulingIcon className="menu-icon" /> });
  }

  if (userFilm?.soundEffectsEnabled) {
    menu[2].menu.push({
      text: t("Sound Effects"), name: "Sound Effects", path: `/films/${filmId}/sound-effects`, icon: <SoundEffectsIcon className="menu-icon" />, isActive: (match, location) => {
        return location.pathname.startsWith(
          `/films/${filmId}/sound-effects`
        );
      }
    });
  }

  menu.forEach(menu => {
    menu.menu = menu.menu.filter(item => userFilm?.visibleSections?.indexOf(item.name) !== -1)
  });

  return (
    <>
      <div className={`blade-menu main ${menuOpen ? "" : "minimized"}`}>
        <aside className="menu">
          <button className={`button arrow`} onClick={minimizeMenu}><LeftArrow className={`left-arrow ${menuOpen ? "" : "minimized"}`} /></button>
          {menu.filter(menu => menu.menu.length > 0).map(({ text, shortText, menu }, menuIdx) => {
            return (
              <div key={menuIdx}>
                <p className={`menu-label left-menu ${menuOpen ? "" : "minimized"}`}>{menuOpen ? text : shortText}</p>
                <ul className="menu-list">
                  {menu.map((item, itemIdx) => {
                    return (
                      <li key={itemIdx}>
                        <NavLink
                          to={item.path}
                          className="navbar-item no-ellipsis"
                          activeClassName="is-active"
                          isActive={item.isActive}>
                          {item.icon}
                          <span className={`menuText ${menuOpen ? "" : "minimized"}`}>{item.text}</span>
                        </NavLink>
                        <span className={`menu-tooltip ${menuOpen ? "" : "minimized"}`}>{item.text}</span>
                      </li>
                    );
                  })}
                </ul>
                {menuIdx < 3 && <hr />}
              </div>
            );
          })}
        </aside>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { filmsState } = state;
  return {
    userFilm: filmsState.userFilm
  };
}

export default withRouter(connect(mapStateToProps, { fetchUserFilm })(FilmMenu));
