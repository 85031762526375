import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchSceneCanAccess } from "./ducks/actions";

function SceneMenu(props) {
  const { filmId, sceneId } = props.match.params;
  const {
    fetchSceneCanAccess,
    canAccess
  } = props;

  const invalidIds = ["add", "script-days", "order"];

  useEffect(() => {
    if (invalidIds.indexOf(sceneId) === -1) {
      fetchSceneCanAccess(filmId, sceneId);
    }
  }, []);

  if (invalidIds.indexOf(sceneId) > -1) {
    return null;
  }

  return (
    <div className="blade-menu menu">
      <p className="menu-label">Scene</p>
      <ul className="menu-list">
        <li>
          <NavLink
            to={`/films/${filmId}/scenes/${sceneId}/edit`}
            activeClassName="is-active">
            Edit Scene
          </NavLink>
        </li>
        {(canAccess && canAccess.canAccessShots) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/shots`}
              activeClassName="is-active">
              Shots
            </NavLink>
          </li>
        }
        {(canAccess && canAccess.canAccessCharacters) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/characters`}
              activeClassName="is-active">
              Characters
            </NavLink>
          </li>
        }
        {(canAccess && canAccess.canAccessBackgroundCharacters) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/background-characters`}
              activeClassName="is-active">
              Background Characters
            </NavLink>
          </li>
        }
        {(canAccess && canAccess.canAccessShootingDays) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/shooting-days`}
              activeClassName="is-active">
              Shooting Days
            </NavLink>
          </li>
        }
        {(canAccess && canAccess.canAccessCostumes) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/costumes`}
              activeClassName="is-active">
              Costumes
            </NavLink>
          </li>
        }
        {(canAccess?.canAccessMakeups) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/makeup`}
              activeClassName="is-active">
              Makeup
            </NavLink>
          </li>
        }
        {(canAccess && canAccess.canAccessProps) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/props`}
              activeClassName="is-active">
              Props
            </NavLink>
          </li>
        }
        {(canAccess && canAccess.canAccessSetDressings) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/set-dressings`}
              activeClassName="is-active">
              Set Dressings
            </NavLink>
          </li>
        }

        {(canAccess && canAccess.canAccessVisualEffects) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/visual-effects`}
              activeClassName="is-active">
              Visual Effects
            </NavLink>
          </li>
        }

        {(canAccess && canAccess.canAccessSoundEffects) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/sound-effects`}
              activeClassName="is-active">
              Sound Effects
            </NavLink>
          </li>
        }

        {(canAccess && canAccess.canAccessEquipment) &&
          <li>
            <NavLink
              to={`/films/${filmId}/scenes/${sceneId}/equipment`}
              activeClassName="is-active">
              Equipment
            </NavLink>
          </li>
        }


      </ul>
    </div>
  );
}

function mapStateToProps(state) {
  const rootState = state.scenesState.rootState;
  return {
    canAccess: rootState.canAccess
  };
}

export default connect(mapStateToProps, {
  fetchSceneCanAccess
})(SceneMenu);
