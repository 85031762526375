import React from "react";
import CurrencyDisplay from "../../shared/currency-display";
import GenericItem from "./generic-item";

function GenericItems(props) {
  const {
    budget,
    onChangeItemBuget,
    onChangeItemDays,
    showHideItems,
    visible,
    readOnly,
    filmId,
    saving,
    title,
    url,
    items,
    total
  } = props;

  return (
    <div>
      <div className="columns subtitle is-4 mt-1">
        <div className="column">
          <h4
            className="subtitle is-4 pointer"
            onClick={() => showHideItems(!visible)}>
            <button className="button is-text is-small">
              {!visible && <i className="material-icons">add</i>}
              {visible && <i className="material-icons">remove</i>}
            </button>
            {title}
          </h4>
        </div>
        <div className="column budget-header-number">
          <CurrencyDisplay
            value={total}
          />
        </div>
      </div>
      {visible && (
        <>
          <table className="table budget-table is-fullwidth">
            <thead>
              <tr>
                <th>Name</th>
                <th className="budget-daily-rate-column">Budget</th>
                <th className="has-text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {(items || []).map((item, i) => {
                return (
                  <GenericItem
                    key={i}
                    item={item}
                    onChangeItemBuget={onChangeItemBuget}
                    onChangeItemDays={onChangeItemDays}
                    maxDays={budget.days}
                    totalScenes={budget.totalScenes}
                    hasShootingDays={budget.hasShootingDays}
                    readOnly={readOnly}
                    filmId={filmId}
                    saving={saving}
                    url={url}
                  />
                );
              })}
              <tr>
                <td colSpan={2}></td>
                <td className="has-text-right">
                  <CurrencyDisplay
                    value={total}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default GenericItems;
