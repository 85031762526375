export default (props) =>
  <svg id="Group_3999" data-name="Group 3999" xmlns="http://www.w3.org/2000/svg" width="24" height="31.6" viewBox="0 0 24 31.6" {...props}>
    <g transform="scale(1.2)">
      <g id="Group_3996" data-name="Group 3996">
        <g id="Group_3995" data-name="Group 3995">
          <path id="Path_32922" data-name="Path 32922" d="M18.909,3.487V24.5H.5V3.487H4.582v3H14.749v-3Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
      </g>
      <line id="Line_757" data-name="Line 757" x2="7.76" transform="translate(7.056 10.42)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" />
      <line id="Line_758" data-name="Line 758" x2="4.507" transform="translate(7.021 14.496)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" />
      <g id="Group_3998" data-name="Group 3998">
        <g id="Group_3997" data-name="Group 3997" >
          <path id="Path_32923" data-name="Path 32923" d="M4.818,9.6A.816.816,0,1,1,4,10.42.816.816,0,0,1,4.818,9.6" fill="#495d7a" />
          <path id="Path_32924" data-name="Path 32924" d="M4.818,13.68A.816.816,0,1,1,4,14.5a.816.816,0,0,1,.816-.816" fill="#495d7a" />
          <path id="Path_32925" data-name="Path 32925" d="M14.748,2.495v3.99H4.582V2.495H7.66a1.994,1.994,0,1,1,3.987,0Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          <line id="Line_759" data-name="Line 759" x2="2.101" transform="translate(7.056 18.573)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" />
          <path id="Path_32926" data-name="Path 32926" d="M4.818,17.757A.816.816,0,1,1,4,18.573a.816.816,0,0,1,.816-.816" fill="#495d7a" />
        </g>
      </g>
    </g>
  </svg>

