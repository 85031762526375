import { Link } from "react-router-dom";
import ScriptItem from "./script-item";
import { useDrop } from "react-dnd";
import AddIcon from "../icons/add";

const ScriptItems = ({ label,
  items,
  url,
  icon,
  readOnly,
  allowedItemTypes,
  itemType,
  dropItem,
  canDrag,
  deleteItem,
  assignItemLink,
  addItemLink,
  returnUrl,
  sceneId,
  sceneSettingId = null }) => {

  const onDropItem = (item) => {
    dropItem(item, itemType);
  };

  const canDropItem = (item, monitor) => {
    if (readOnly) {
      return false;
    }

    return allowedItemTypes.includes(item.type);
  };

  const [{ isOver }, drop] = useDrop({
    accept: allowedItemTypes,
    drop: (item: any, monitor) => onDropItem(item),
    canDrop: (item, monitor) => canDropItem(item, monitor),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  const getItemStyle = (isDragging) => ({
    background: isDragging ? "#947eed" : "#040f21"
  });


  return (
    <>
      {items && (
        <div className="card card-script-items" ref={drop} style={getItemStyle(isOver)}>
          <header className="card-header">
            <div className="card-header-title card-script-items-title">
              <label>{icon({ className: "menu-icon" })} {label}</label>
            </div>
          </header>
          <div className="card-content pt-0">
            {items?.length < 1 && <ul>None</ul>}
            <ul>
              {(items || []).map((item, index) => (
                <ScriptItem item={item}
                  index={index} label={label} readOnly={readOnly} url={url}
                  itemType={itemType}
                  canDrag={canDrag}
                  deleteItem={deleteItem}
                />
              ))}
            </ul>
          </div>
          <div className="mb-1">
            {assignItemLink && <div className="card-script-items-add-button-container ml-1">
              <Link to={{ pathname: assignItemLink, state: { fromBreakdown: true } }} className="button is-transparent">
                <AddIcon className="white-icon" />Assign Existing</Link>
            </div>}
            {addItemLink && <Link
              to={{ pathname: addItemLink, state: { sceneId, sceneSettingId, returnUrl } }}
              className="button is-transparent is-add">
              <AddIcon className="white-icon" />
              Add New
            </Link>}
          </div>
        </div>
      )}
    </>
  );
};

export default ScriptItems;
