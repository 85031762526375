import { connect } from "react-redux";
import ImportMovieMagic from "./import";
import { importMovieMagic, clearRedirect } from "../shooting-order/ducks/actions";
import { Redirect } from "react-router-dom";
import Errors from "../../../shared/errors";

const ImportMovieMagicContainer = (props) => {
    const { isUploading, importMovieMagic, readOnly, redirect, clearRedirect, errors } = props;
    const filmId = props.match.params.filmId;

    const onImportMovieMagic = (formData) => {
        importMovieMagic(filmId, formData);
    };

    const acceptedFileTypes = [".pdf"];

    if (redirect) {
        if (redirect.to === "shooting-order") {
            const url = `/films/${filmId}/shooting-days/shooting-order`;
            clearRedirect();
            return <Redirect to={url} />;
        }
    }

    return (
        <div className="blade-content wideaf">
            <p className="menu-label">Import Schedule</p>
            {errors && <Errors errors={errors} />}
            <ImportMovieMagic
                acceptedFileTypes={acceptedFileTypes}
                onImportMovieMagic={onImportMovieMagic}
                isUploading={isUploading}
                readOnly={readOnly} />
        </div>
    )
}

function mapStateToProps(state) {
    const {
        loading,
        errors,
        warnings,
        readOnly,
        isUploading,
        redirect
    } = state.shootingOrderState;
    return {
        loading,
        errors,
        warnings,
        readOnly,
        isUploading,
        redirect
    };
}

export default connect(mapStateToProps, {
    importMovieMagic,
    clearRedirect
})(ImportMovieMagicContainer);