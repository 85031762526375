import { sortByName } from "../../../../shared/utility";
import { ADD_ITEM_TO_SCENE, CLEAR_SCENE_ITEM_REDIRECT, FETCH_ITEMS_IN_SCENE } from "./actions";

const defaultState = {
  item: {},
  items: [],
  loading: true,
  saving: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_ITEMS_IN_SCENE + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_ITEMS_IN_SCENE + "_FULFILLED": {
      return {
        ...state,
        items: action.payload.data.items,
        loading: false,
        errors: null
      };
    }

    case FETCH_ITEMS_IN_SCENE + "_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case ADD_ITEM_TO_SCENE + "_PENDING": {
      return {
        ...state,
        saving: true,
        errors: null
      };
    }

    case ADD_ITEM_TO_SCENE + "_FULFILLED": {
      const item = action.payload.data.item;
      const items = [...state.items, item];
      sortByName(items);
      const { fromBreakdown } = action.meta;
      return {
        ...state,
        items,
        saving: false,
        errors: null,
        redirect: { to: fromBreakdown ? "breakdown" : "list" }
      };
    }

    case ADD_ITEM_TO_SCENE + "_REJECTED": {
      return {
        ...state,
        saving: false,
        errors: action.payload.response.data.errors
      };
    }

    case CLEAR_SCENE_ITEM_REDIRECT: {
      return {
        ...state,
        item: null,
        redirect: null
      };
    }

    default:
      return state;
  }
};

export default reducer;
