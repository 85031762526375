import React from "react";
import { NavLink } from "react-router-dom";
import { ITEM_FRONEND_URL, NAME_PLURAL_FOR_UI } from "./ducks/actions";

const ItemsList = ({ items, filmId }) => {
  return (
    <aside className="menu">
      <p className="menu-label">{NAME_PLURAL_FOR_UI}</p>
      <ul className="menu-list">
        {/* <li>
          <NavLink
            to={`/films/${filmId}/visual-effects/overview`}
            activeClassName="is-active">
            Overview
          </NavLink>
        </li>
        <hr /> */}
        {items?.map((item, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={`/films/${filmId}/${ITEM_FRONEND_URL}/${item.id}/edit`}
                title={item.name}
                activeClassName="is-active"
                isActive={(match, location) => {
                  return location.pathname.startsWith(
                    `/films/${filmId}/${ITEM_FRONEND_URL}/${item.id}`
                  );
                }}>
                {item.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ItemsList;
