import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "../../../shared/loader";
import Onboarding from '../../../shared/onboarding/onboarding';
import {
  assignScenesToMakeup,
  clearRedirect,
  fetchMakeup,
  fetchAllScenes,
  fetchAllSceneSettings,
  fetchScenesAssignedToMakeup,
  setSceneAssignedMakeup,
  findInScript,
  clearSceneSearchResults,
  fetchScriptDays
} from "./ducks/actions";
import {
  searchScenes,
  SEARCH_SCENES_FOR_MAKEUP
} from "../../scenes/search/ducks/actions";
import Form from "./scenes-form";
import SearchScenesForm from "../../scenes/search/search-scenes-form";
import ScriptSearch from "../../script/search/script-search";
import { OnboardingStep1ClassName, OnboardingStep2ClassName } from './ducks/reducer';

function MakeupScenes(props) {
  const makeupId = props.makeupId || props.match.params.makeupId;
  const filmId = props.filmId || props.match.params.filmId;
  const {
    fetchMakeup,
    fetchAllScenes,
    fetchAllSceneSettings,
    fetchScenesAssignedToMakeup,
    setSceneAssignedMakeup,
    fetchScriptDays,
    findInScript,
    makeup,
    assignScenesToMakeup,
    loadingMakeup,
    allScenes,
    searchScenes,
    clearSceneSearchResults,
    errors,
    sceneId,
    embedded,
    loadingScenes,
    onboardingSteps,
    loadingSceneSettings,
    findingInScript,
    searchingScenes
  } = props;

  const invalidIds = ["add", "scenes"];
  const readOnly = makeup?.readOnly;
  useEffect(() => {
    fetchMakeup(filmId, makeupId);
    if (!sceneId) {
      fetchScenesAssignedToMakeup(filmId, makeupId);
    }
  }, [filmId, makeupId]);

  useEffect(() => {
    if (makeup) {
      fetchAllScenes(filmId, makeup.characterIds, makeup.backgroundCharacterIds);
      fetchAllSceneSettings(filmId, makeup.characterIds, makeup.backgroundCharacterIds);
      fetchScriptDays(filmId, makeup.characterIds, makeup.backgroundCharacterIds);
    }
  }, [filmId, makeupId, makeup]);

  useEffect(() => {
    if (sceneId) {
      setSceneAssignedMakeup(sceneId);
    }
  }, [sceneId]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/makeup/`;
      return <Redirect to={url} />;
    } else {
      props.clearRedirect();
      return <Redirect to={props.redirect.to} />;
    }
  }

  if (invalidIds.indexOf(makeupId) > -1) {
    return null;
  }

  const onFindInScript = (e) => {
    e.preventDefault();
    findInScript(filmId, makeup.name);
  };

  function renderLoading() {
    return <Loader />;
  }

  function renderForm() {
    return (
      <div className="blade-content">
        <h2 className="menu-label">Makeup Scenes</h2>
        {!readOnly && <div className={`box mt-1 ${OnboardingStep1ClassName}`}>
          <SearchScenesForm
            errors={errors}
            mode="edit"
            filmId={filmId}
            makeupId={makeupId}
            loading={loadingMakeup}
            onSubmit={onSearchScenes}
            findInScript={onFindInScript}
            allSceneSettings={props.allSceneSettings}
            scenes={allScenes}
            scriptDays={props.scriptDays}
            makeupName={makeup.name}
            searchingScenes={searchingScenes}
            showCharacters={false}
            showScriptDays={true}
            showSceneSettings={true}
          />
        </div>}
        {!readOnly && <div className={`box mt-1 ${OnboardingStep2ClassName}`}>
          <ScriptSearch filmId={filmId} text={makeup.name} typeToDispatch="MAKEUP_SEARCH_SCRIPT" findingInScript={findingInScript} />
        </div>}
        <Form
          errors={errors}
          mode="edit"
          filmId={filmId}
          makeupId={makeupId}
          initialValues={{ selectedSceneIds: props.selectedSceneIds }}
          loading={loadingSceneSettings || loadingMakeup || loadingScenes}
          onSubmit={onUpdateMakeupScenes}
          reduxFormValues={props.reduxFormValues}
          allScenes={allScenes}
          searchedSceneIds={props.searchedScenes?.map((s) => s.id)}
          selectSearchedScenes={props.selectSearchedScenes}
          clearSceneSearchResults={clearSceneSearchResults}
          readOnly={readOnly}
        />
        <Onboarding onboardingSteps={onboardingSteps} section="Makeup Scenes" />
      </div>
    );
  }

  function onSearchScenes(params) {
    searchScenes(
      SEARCH_SCENES_FOR_MAKEUP,
      filmId,
      params
    );
  }

  function onUpdateMakeupScenes(makeupScenes) {
    // remove any scenes that can't be seen    
    const visibleSceneIds = allScenes.map(s => s.id);
    const selectedSceneIds = makeupScenes.selectedSceneIds.filter(s => visibleSceneIds.indexOf(s) >= 0);
    assignScenesToMakeup(filmId, makeupId, selectedSceneIds);
  }

  return !loadingScenes && makeup ? renderForm() : renderLoading();
}

function mapStateToMakeups(state) {
  const { makeupState, form } = state;
  return {
    makeup: makeupState.makeup,
    allScenes: makeupState.scenes,
    allSceneSettings: makeupState.sceneSettings,
    selectedSceneIds: makeupState.selectedSceneIds,
    loadingSceneSettings: makeupState.loadingSceneSettings,
    searchedScenes: makeupState.searchedScenes,
    selectSearchedScenes: makeupState.selectSearchedScenes,
    findingInScript: makeupState.findingInScript,
    searchingScenes: makeupState.searchingScenes,
    scriptDays: makeupState.scriptDays,
    errors: makeupState.errors,
    redirect: makeupState.redirect,
    loadingScenes: makeupState.loadingScenes,
    onboardingSteps: makeupState.onboardingSteps,
    reduxFormValues: form?.makeupScenesForm?.values
  };
}

export default
  connect(mapStateToMakeups, {
    fetchMakeup,
    fetchAllScenes,
    fetchAllSceneSettings,
    fetchScenesAssignedToMakeup,
    setSceneAssignedMakeup,
    fetchScriptDays,
    clearRedirect,
    assignScenesToMakeup,
    searchScenes,
    findInScript,
    clearSceneSearchResults
  })(MakeupScenes);
