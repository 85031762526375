import {
  clearNotifications,
  showError,
  showInfo
} from "./../../../notifications/ducks/actions";
import getApiClient from "./../../../shared/api";
import { getActionToUploadImagesAndSaveEntity } from "./../../../shared/image-action-creator";

export const ITEM_NAME = "SOUND_EFFECT";
export const ITEM_API_URL = "soundEffect";
export const NAME_FOR_MESSAGES = "sound effect";
export const NAME_FOR_UI = "Sound Effect";
export const NAME_PLURAL_FOR_UI = "Sound Effects";
export const ITEM_FRONEND_URL = "sound-effects";

export const FETCH_ITEMS = "FETCH_" + ITEM_NAME + "S";
export const FETCH_SINGLE_ITEM = "FETCH_SINGLE_" + ITEM_NAME;
export const ITEM_SET_NAME = ITEM_NAME + "_SET_NAME";
export const ADD_SINGLE_ITEM = "ADD_SINGLE_" + ITEM_NAME;
export const CLEAR_ITEM = "CLEAR_" + ITEM_NAME;
export const ASSIGN_SCENES_TO_ITEM = "ASSIGN_SCENES_TO_" + ITEM_NAME;
export const DELETE_SINGLE_ITEM = "DELETE_SINGLE_" + ITEM_NAME;
export const UPDATE_SINGLE_ITEM = "UPDATE_SINGLE_" + ITEM_NAME;
export const ITEM_SET_RETURN_URL = ITEM_NAME + "_SET_RETURN_URL";
export const ADD_ITEM_IMAGE_FILES = "ADD_" + ITEM_NAME + "_IMAGE_FILES";
export const ADD_ITEM_IMAGE_URL = "ADD_" + ITEM_NAME + "_IMAGE_URL";
export const DELETE_ITEM_IMAGE_URL = "DELETE_" + ITEM_NAME + "_IMAGE_URL";
export const DELETE_ITEM_IMAGE_FILE = "DELETE_" + ITEM_NAME + "_IMAGE_FILE";
export const ITEM_MAIN_IMAGE_CHANGED = ITEM_NAME + "_MAIN_IMAGE_CHANGED";
export const CLEAR_ITEM_REDIRECT = "CLEAR_" + ITEM_NAME + "_REDIRECT";
export const FETCH_ALL_SCENES_FOR_ITEM = "FETCH_ALL_SCENES_FOR_" + ITEM_NAME;
export const FETCH_SCENES_ASSIGNED_TO_ITEM = "FETCH_SCENES_ASSIGNED_TO_" + ITEM_NAME;
export const SET_SCENE_ASSIGNED_TO_ITEM = "SET_SCENE_ASSIGNED_TO_" + ITEM_NAME;
export const FETCH_ALL_CHARACTERS_FOR_ITEM = "FETCH_ALL_CHARACTERS_FOR_" + ITEM_NAME;
export const FETCH_ALL_SCENE_SETTINGS_FOR_ITEM = "FETCH_ALL_SCENE_SETTINGS_FOR_" + ITEM_NAME;
export const SEARCH_SCENES_FOR_ITEM = "SEARCH_SCENES_FOR_" + ITEM_NAME;
export const SEARCH_SCRIPT_FOR_ITEM = "SEARCH_SCRIPT_FOR_" + ITEM_NAME;
export const CLEAR_SEARCH_SCENES_FOR_ITEM = "CLEAR_SEARCH_SCENES_FOR_" + ITEM_NAME;
export const ITEM_SEARCH_SCRIPT = ITEM_NAME + "_SEARCH_SCRIPT";


export function fetchItems(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ITEMS,
      payload: getApiClient().get(`/film/${filmId}/${ITEM_API_URL}`)
    });
  };
}

export function clearItem() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ITEM
    });
  };
}

export function setReturnUrl(returnUrl) {
  return (dispatch) => {
    dispatch({
      type: ITEM_SET_RETURN_URL,
      meta: { returnUrl }
    });
  };
}

export function setName(name) {
  return (dispatch) => {
    dispatch({
      type: ITEM_SET_NAME,
      meta: { name }
    });
  };
}

export function fetchItem(filmId, itemId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SINGLE_ITEM,
      payload: getApiClient().get(`/film/${filmId}/${ITEM_API_URL}/${itemId}`)
    });
  };
}

export function updateItem(filmId, item) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SINGLE_ITEM,
      payload: getApiClient().put(`/film/${filmId}/${ITEM_API_URL}/${item.id}`, item),
      meta: { item }
    })
      .then((response) => {
        dispatch(showInfo(`Updated ${NAME_FOR_MESSAGES} ${item.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error updating ${NAME_FOR_MESSAGES} ${item.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

function addItemWithImageUrls(dispatch, item, filmId) {
  dispatch({
    type: ADD_SINGLE_ITEM,
    payload: getApiClient().post(`/film/${filmId}/${ITEM_API_URL}/`, item),
    meta: { item }
  })
    .then((response) => {
      dispatch(showInfo(`Added ${NAME_FOR_MESSAGES} ${item.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

function updateItemWithImageUrls(dispatch, item, filmId) {
  dispatch({
    type: UPDATE_SINGLE_ITEM,
    payload: getApiClient().put(`/film/${filmId}/${ITEM_API_URL}/${item.id}`, item),
    meta: { item }
  })
    .then((response) => {
      dispatch(showInfo(`Updated ${NAME_FOR_MESSAGES} ${item.name}`));
    })
    .then((response) => {
      dispatch(clearNotifications());
    });
}

export const uploadImagesAndSaveItem = getActionToUploadImagesAndSaveEntity(
  addItemWithImageUrls,
  updateItemWithImageUrls
);

export function addItem(filmId, item) {
  return (dispatch) => {
    dispatch({
      type: ADD_SINGLE_ITEM,
      payload: getApiClient().post(`/film/${filmId}/${ITEM_API_URL}/`, item),
      meta: { item }
    })
      .then((response) => {
        dispatch(showInfo(`Added ${NAME_FOR_MESSAGES} ${item.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error adding ${NAME_FOR_MESSAGES}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function deleteItem(filmId, item) {
  return (dispatch) => {
    dispatch({
      type: DELETE_SINGLE_ITEM,
      payload: getApiClient().delete(`/film/${filmId}/${ITEM_API_URL}/${item.id}`),
      meta: { item }
    })
      .then((response) => {
        dispatch(showInfo(`Deleted ${NAME_FOR_MESSAGES} ${item.name}`));
      })
      .catch((response) => {
        dispatch(showError(`Error deleting ${NAME_FOR_MESSAGES} ${item.name}`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function addImageFiles(imageFiles) {
  return (dispatch) => {
    dispatch({
      type: ADD_ITEM_IMAGE_FILES,
      meta: { imageFiles }
    });
  };
}

export function addImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: ADD_ITEM_IMAGE_URL,
      meta: { url }
    });
  };
}

export function deleteImageUrl(url) {
  return (dispatch) => {
    dispatch({
      type: DELETE_ITEM_IMAGE_URL,
      meta: { url }
    });
  };
}

export function deleteImageFile(fileName) {
  return (dispatch) => {
    dispatch({
      type: DELETE_ITEM_IMAGE_FILE,
      meta: { fileName }
    });
  };
}

export function mainImageUrlChanged(url) {
  return (dispatch) => {
    dispatch({
      type: ITEM_MAIN_IMAGE_CHANGED,
      meta: { url }
    });
  };
}

export function clearRedirect() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ITEM_REDIRECT
    });
  };
}

export function fetchAllScenes(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_SCENES_FOR_ITEM,
      payload: getApiClient().get(`/film/${filmId}/scene`)
    });
  };
}

export function fetchScenesAssignedToItem(filmId, itemId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SCENES_ASSIGNED_TO_ITEM,
      payload: getApiClient().get(
        `/film/${filmId}/${ITEM_API_URL}/${itemId}/AssignedToScenes`
      )
    });
  };
}

export function setSceneAssignedToItem(sceneId) {
  return (dispatch) => {
    dispatch({
      type: SET_SCENE_ASSIGNED_TO_ITEM,
      meta: { sceneId }
    });
  };
}

export function assignScenesToItem(filmId, itemId, sceneIds) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_SCENES_TO_ITEM,
      payload: getApiClient().put(
        `/film/${filmId}/${ITEM_API_URL}/${itemId}/AssignToScenes`,
        { sceneIds }
      ),
      meta: { visualEffectId: itemId }
    })
      .then((response) => {
        dispatch(showInfo(`Assigned ${NAME_FOR_MESSAGES} to Scenes`));
      })
      .catch((response) => {
        dispatch(showError(`Error assigning ${NAME_FOR_MESSAGES} to Scenes`));
      })
      .then((response) => {
        dispatch(clearNotifications());
      });
  };
}

export function fetchAllCharacters(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_CHARACTERS_FOR_ITEM,
      payload: getApiClient().get(`/film/${filmId}/character`)
    });
  };
}

export function fetchAllSceneSettings(filmId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_SCENE_SETTINGS_FOR_ITEM,
      payload: getApiClient().get(`/film/${filmId}/scenesetting`)
    });
  };
}

export function searchScenes(
  filmId,
  characterIds,
  sceneSettingIds,
  sceneNumberFrom,
  sceneNumberTo,
  selectSearchedScenes
) {
  return (dispatch) => {
    let filters: string[] = [];

    if (characterIds?.length) {
      filters.push(`characterIds=${characterIds.join(",")}`);
    }

    if (sceneSettingIds?.length) {
      filters.push(`sceneSettingIds=${sceneSettingIds.join(",")}`);
    }

    if (sceneNumberFrom) {
      filters.push(`sceneFrom=${sceneNumberFrom}`);
    }

    if (sceneNumberTo) {
      filters.push(`sceneTo=${sceneNumberTo}`);
    }

    dispatch({
      type: SEARCH_SCENES_FOR_ITEM,
      payload: getApiClient().get(
        `/film/${filmId}/scene/search?${filters.join("&")}`
      ),
      meta: { selectSearchedScenes }
    });
  };
}

export function findInScript(
  filmId,
  propName
) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_SCRIPT_FOR_ITEM,
      payload: getApiClient().get(
        `/film/${filmId}/script/search/${propName}`
      ),
      meta: { selectSearchedScenes: true }
    });
  };
}

export function clearSceneSearchResults() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_SCENES_FOR_ITEM
    });
  };
}
