import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../shared/scroll-into-view/scroll-into-view";
import {
  addItem,
  clearItem,
  clearRedirect,
  uploadImagesAndSaveItem,
  setName,
  setReturnUrl,
  ITEM_FRONEND_URL
} from "./ducks/actions";
import Form from "./form";

function AddItem(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const sceneId = props.sceneId || props.location?.state?.sceneId;
  const returnUrl = props.location?.state?.returnUrl;

  const { addItem, item, errors, uploadImagesAndSaveItem, setName, name, embedded, clearItem,
    setReturnUrl
  } = props;

  useEffect(() => {
    clearItem();
  }, []);

  useEffect(() => {
    if (name) {
      setName(name);
    }
  }, [name]);

  useEffect(() => {
    setReturnUrl(returnUrl);
  }, [returnUrl]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/${ITEM_FRONEND_URL}/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "scenes") {
      props.clearRedirect();
      const url = `/films/${filmId}/${ITEM_FRONEND_URL}/${props.redirect.id}/scenes/`;
      return <Redirect to={url} />;
    }
  }

  function onAddItem(item) {
    item.sceneId = sceneId;
    const files = item.pictures || [];
    if (files && files.length > 0) {
      uploadImagesAndSaveItem(item, files, true, filmId);
    } else {
      if (!item.imageUrls) {
        item.imageUrls = [];
      }
      addItem(filmId, item);
    }
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path={`/films/:filmId/${ITEM_FRONEND_URL}/add`}
      loading={false}>
      <Form
        errors={errors}
        propId={null}
        initialValues={item}
        loading={false}
        onSubmit={onAddItem}
        {...props}
        mode="add"
        reduxFormValues={props.reduxFormValues}
      />
    </ScrollIntoView>
  );
}

function mapStateToProps(state) {
  const { soundEffectsState, commonState } = state;

  return {
    item: soundEffectsState.item,
    errors: soundEffectsState.errors,
    redirect: soundEffectsState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod
  };
}

export default connect(mapStateToProps, {
  addItem,
  clearItem,
  clearRedirect,
  uploadImagesAndSaveItem,
  setName,
  setReturnUrl
})(AddItem);
