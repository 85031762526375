import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ScrollIntoView from "../../../shared/scroll-into-view/scroll-into-view";
import {
  addMakeup,
  clearMakeup,
  clearRedirect,
  fetchAllCharacters,
  fetchAllBackgroundCharacters,
  uploadImagesAndSaveMakeup,
  setName,
  setReturnUrl
} from "./ducks/actions";
import Form from "./form";

function AddMakeup(props) {
  const filmId = props.filmId || props.match.params.filmId;
  const sceneId = props.sceneId || props.location?.state?.sceneId;
  const returnUrl = props.location?.state?.returnUrl;
  const {
    addMakeup,
    makeup,
    errors,
    uploadImagesAndSaveMakeup,
    fetchAllCharacters,
    fetchAllBackgroundCharacters,
    setName,
    name,
    embedded,
    allCharacters,
    allBackgroundCharacters,
    clearMakeup,
    setReturnUrl
  } = props;

  useEffect(() => {
    clearMakeup();
    fetchAllCharacters(filmId, sceneId);
    fetchAllBackgroundCharacters(filmId);
  }, []);

  useEffect(() => {
    if (name) {
      setName(name);
    }
  }, [name]);

  useEffect(() => {
    setReturnUrl(returnUrl);
  }, [returnUrl]);

  if (!embedded && props.redirect) {
    if (props.redirect.to === "list") {
      props.clearRedirect();
      const url = `/films/${filmId}/makeup/`;
      return <Redirect to={url} />;
    } else if (props.redirect.to === "scenes") {
      props.clearRedirect();
      const url = `/films/${filmId}/makeup/${props.redirect.id}/scenes/`;
      return <Redirect to={url} />;
    }
  }

  function onAddMakeup(makeup) {
    makeup.characterIds = makeup.characters?.map((x) => x.value || x);
    makeup.backgroundCharacterIds = makeup.backgroundCharacters?.map((x) => x.value || x);
    makeup.sceneId = sceneId;
    const files = makeup.pictures || [];
    if (files && files.length > 0) {
      uploadImagesAndSaveMakeup(makeup, files, true, filmId);
    } else {
      if (!makeup.images) {
        makeup.images = [];
      }
      addMakeup(filmId, makeup);
    }
  }

  return (
    <ScrollIntoView
      className="blade-content widest"
      path="/films/:filmId/makeup/add"
      loading={false}>
      <Form
        errors={errors}
        makeupId={null}
        initialValues={makeup}
        loading={false}
        onSubmit={onAddMakeup}
        mode="add"
        acquisitionMethodEnum={props.acquisitionMethodEnum}
        reduxFormValues={props.reduxFormValues}
        allCharacters={allCharacters}
        allBackgroundCharacters={allBackgroundCharacters}
      />
    </ScrollIntoView>
  );
}

function mapStateToMakeups(state) {
  const { makeupState, commonState } = state;

  return {
    makeup: makeupState.makeup,
    errors: makeupState.errors,
    redirect: makeupState.redirect,
    acquisitionMethodEnum: commonState.acquisitionMethod,
    allCharacters: makeupState.characters,
    allBackgroundCharacters: makeupState.backgroundCharacters
  };
}

export default connect(mapStateToMakeups, {
  addMakeup,
  clearMakeup,
  clearRedirect,
  fetchAllCharacters,
  fetchAllBackgroundCharacters,
  uploadImagesAndSaveMakeup,
  setName,
  setReturnUrl
})(AddMakeup);
