import mapPictures from "../../../../shared/image-mapper";
import OnboardingType from "../../../../shared/onboarding/onboarding-type";
import { sortByName } from "./../../../../shared/utility";
import { CHANGE_SET_DRESSING_TO_PROP, SET_DRESSING_SCENE_SETTING, SET_DRESSING_SET_RETURN_URL } from "./actions";

interface ActionState {
  setDressings: any[];
  loading: boolean;
  loadingSceneSettings: boolean;
  loadingScenes: boolean;
  setDressing: any;
  imageFiles: any[];
  scenes: any[];
  readOnly: boolean;
  onboardingSteps: OnboardingType[];
  findingInScript: boolean;
  searchingScenes: boolean;
  enableSave: boolean;
  returnUrl: string | null;
}

export const OnboardingStep1ClassName = 'set-dressing-scenes-onboarding-step-1';
export const OnboardingStep2ClassName = 'set-dressing-scenes-onboarding-step-2';
export const OnboardingStep3ClassName = 'set-dressing-scenes-onboarding-step-3';
export const OnboardingStep4ClassName = 'set-dressing-scenes-onboarding-step-4';
export const OnboardingStep5ClassName = 'set-dressing-scenes-onboarding-step-5';

const defaultState: ActionState = {
  setDressings: [],
  loading: true,
  loadingSceneSettings: false,
  loadingScenes: true,
  setDressing: {},
  imageFiles: [],
  scenes: [],
  readOnly: false,
  findingInScript: false,
  searchingScenes: false,
  enableSave: false,
  returnUrl: null,
  onboardingSteps: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Scene Search',
      content: `        
        Use this to quickly select the scenes that match the selected criteria.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Script Search',
      content: `Or use this to search the script for the name of the set dressing, and select any scenes that match. You can chose which parts of the script to select.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Select',
      content: `Or just select or unselect all scenes.`
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Manual',
      content: `<p>If any scenes have not been selected using any of the above methods, then you can manually select or unselect a scene.</p>
                <p>This list only includes scenes which are set at the selected scene setting.</p>`
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Save',
      content: `Once you have the desired scenes selected, don't forget to click Save.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SET_DRESSINGS_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "CLEAR_SET_DRESSING": {
      return {
        ...state,
        loading: false,
        setDressing: null,
        errors: null
      };
    }

    case "SET_DRESSING_SET_NAME_AND_SCENE_SETTING": {
      const setDressing = { name: action.meta.name, sceneSettingId: action.meta.sceneSettingId, quantity: 1, nameInScript: action.meta.name, acquisitionMethod: 1, enableSave: true }
      return {
        ...state,
        setDressing: setDressing
      };
    }

    case SET_DRESSING_SCENE_SETTING: {
      const setDressing = { sceneSettingId: action.meta.sceneSettingId, quantity: 1, acquisitionMethod: 1, enableSave: true }
      return {
        ...state,
        setDressing: setDressing
      };
    }

    case "FETCH_NEXT_SET_DRESSING_NUMBER": {
      let nextNumber = 1;
      if (state.setDressings && state.setDressings.length > 0) {
        const maxNumber = Math.max.apply(
          null,
          state.setDressings.map((setDressing) => {
            return setDressing.number;
          })
        );
        nextNumber = maxNumber + 1;
      }
      return {
        ...state,
        setDressing: { number: nextNumber }
      };
    }

    case "FETCH_SET_DRESSINGS_FULFILLED": {
      return {
        ...state,
        setDressings: action.payload.data.setDressings,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case "FETCH_SINGLE_SET_DRESSING_PENDING": {
      return {
        ...state,
        setDressing: null,
        loadingSetDressing: true
      };
    }

    case "FETCH_SINGLE_SET_DRESSING_FULFILLED": {
      let setDressing = action.payload.data;

      mapPictures(setDressing);

      return {
        ...state,
        setDressing,
        loadingSetDressing: false,
        errors: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingSetDressing: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingSetDressing: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingSetDressing: false
      };
    }

    case "UPDATE_SINGLE_SET_DRESSING_PENDING": {
      return {
        ...state,
        setDressing: action.payload,
        loadingSetDressing: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_SET_DRESSING_FULFILLED": {
      const setDressing = { ...action.meta.setDressing, ...action.payload.data.setDressing };
      const setDressings: any = state.setDressings.map((c: any, index: number) => {
        if (c.id === setDressing.id) {
          return { ...c, ...setDressing };
        } else {
          return c;
        }
      });
      sortByName(setDressings);
      return {
        ...state,
        setDressing,
        setDressings,
        loadingSetDressing: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_SET_DRESSING_REJECTED": {
      return {
        ...state,
        setDressing: action.meta.setDressing,
        errors: action.payload.response.data.errors,
        loadingSetDressing: false
      };
    }

    case CHANGE_SET_DRESSING_TO_PROP + "_PENDING": {
      return {
        ...state,
        loadingSetDressing: true,
        errors: null
      };
    }

    case CHANGE_SET_DRESSING_TO_PROP + "_FULFILLED": {
      const setDressings = Array.from(state.setDressings);
      var index = setDressings.findIndex(s => s.id === action.meta.setDressing.id);
      if (index >= 0) {
        setDressings.splice(index);
      }

      return {
        ...state,
        setDressings,
        loadingSetDressing: false,
        errors: null,
        redirect: { to: "prop", id: action.payload.data.propId }
      };
    }

    case CHANGE_SET_DRESSING_TO_PROP + "_REJECTED": {
      return {
        ...state,
        setDressing: action.meta.setDressing,
        errors: action.payload.response.data.errors,
        loadingSetDressing: false
      };
    }

    case "ADD_SINGLE_SET_DRESSING_PENDING": {
      return {
        ...state,
        errors: null,
        loadingSetDressing: true
      };
    }

    case "ADD_SINGLE_SET_DRESSING_FULFILLED": {
      const setDressing: any = action.payload.data.setDressing;

      let setDressings = [setDressing, ...state.setDressings];
      sortByName(setDressings);
      return {
        ...state,
        setDressing,
        setDressings,
        loadingSetDressing: false,
        errors: null,
        redirect: { to: "scenes", id: setDressing.id }
      };
    }

    case "ADD_SINGLE_SET_DRESSING_REJECTED": {
      return {
        ...state,
        setDressing: action.meta.setDressing,
        errors: action.payload.response.data.errors,
        loadingSetDressing: false
      };
    }

    case "DELETE_SINGLE_SET_DRESSING_PENDING": {
      return {
        ...state,
        setDressing: action.payload,
        loadingSetDressing: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_SET_DRESSING_FULFILLED": {
      const setDressing = action.meta.setDressing;
      const setDressings: any = state.setDressings.filter((c: any, index: number) => {
        return c.id !== setDressing.id;
      });

      return {
        ...state,
        setDressing: null,
        setDressings,
        loadingSetDressing: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_SET_DRESSING_REJECTED": {
      return {
        ...state,
        setDressing: action.meta.setDressing,
        errors: action.payload.response.data.errors,
        loadingSetDressing: false
      };
    }

    case "ADD_SET_DRESSING_IMAGE_FILES": {
      let mainImageUrl = state.setDressing.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.imageFiles[0].name;
      }

      return {
        ...state,
        imageFiles: [...state.imageFiles, ...action.meta.imageFiles],
        setDressing: { ...state.setDressing, mainImageUrl: mainImageUrl }
      };
    }

    case "ADD_SET_DRESSING_IMAGE_URL": {
      var setDressing: any = { ...state.setDressing };
      let mainImageUrl = state.setDressing.mainImageUrl;
      if (!mainImageUrl) {
        mainImageUrl = action.meta.url;
      }

      if (setDressing?.imageUrls) {
        setDressing = {
          ...setDressing,
          imageUrls: [...setDressing?.imageUrls, action.meta.url],
          mainImageUrl: mainImageUrl
        };
      } else {
        setDressing = {
          ...setDressing,
          imageUrls: [action.meta.url],
          mainImageUrl: mainImageUrl
        };
      }
      return {
        ...state,
        setDressing: setDressing
      };
    }

    case "DELETE_SET_DRESSING_IMAGE_URL": {
      let imageUrls: any = state.setDressing.imageUrls;
      imageUrls = imageUrls.filter((item) => {
        return item !== action.meta.url;
      });
      return {
        ...state,
        setDressing: { ...state.setDressing, imageUrls: imageUrls }
      };
    }

    case "DELETE_SET_DRESSING_IMAGE_FILE": {
      let imageFiles = state.imageFiles.filter((file) => {
        return file.name !== action.meta.fileName;
      });
      return {
        ...state,
        imageFiles: imageFiles
      };
    }

    case "SET_DRESSING_MAIN_IMAGE_CHANGED": {
      return {
        ...state,
        setDressing: { ...state.setDressing, mainImageUrl: action.meta.url }
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        setDressing: null,
        redirect: null
      };
    }

    case "FETCH_ALL_SCENES_FOR_SET_DRESSING_PENDING": {
      return {
        ...state,
        loadingScenes: true
      };
    }

    case "FETCH_ALL_SCENES_FOR_SET_DRESSING_FULFILLED": {
      const scenes = action.payload.data.scenes.map((scene) => {
        return { ...scene, name: `${scene.number}. ${scene.settingName}` };
      });
      return {
        ...state,
        scenes,
        loadingScenes: false,
        errors: null
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_SET_DRESSING_PENDING": {
      return {
        ...state,
        loadingSceneSettings: true
      };
    }

    case "FETCH_SCENES_ASSIGNED_TO_SET_DRESSING_FULFILLED": {
      return {
        ...state,
        selectedSceneIds: action.payload.data.sceneIds,
        readOnly: action.payload.data.readOnly,
        loadingSceneSettings: false,
        errors: null
      };
    }

    case "SET_SCENE_ASSIGNED_TO_SET_DRESSING": {
      return {
        ...state,
        selectedSceneIds: [action.meta.sceneId]
      };
    }

    case "ASSIGN_SCENES_TO_SET_DRESSING_FULFILLED": {
      return {
        ...state,
        loading: false,
        errors: null,
        redirect: { to: state.returnUrl || "list" }
      };
    }

    case "FETCH_ALL_SCENE_SETTINGS_FOR_SET_DRESSING_FULFILLED": {
      return {
        ...state,
        sceneSettings: action.payload.data.sceneSettings,
        loading: false,
        errors: null
      };
    }

    case "SEARCH_SCENES_FOR_SET_DRESSING_PENDING": {
      return {
        ...state,
        searchingScenes: true
      };
    }

    case "SEARCH_SCENES_FOR_SET_DRESSING_FULFILLED": {
      return {
        ...state,
        searchedScenes: action.payload.data.scenes,
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        searchingScenes: false,
      };
    }

    case "SEARCH_SCENES_FOR_SET_DRESSING_REJECTED": {
      return {
        ...state,
        searchingScenes: false,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "SET_DRESSING_SEARCH_SCRIPT_PENDING": {
      return {
        ...state,
        findingInScript: true
      }
    }

    case "SET_DRESSING_SEARCH_SCRIPT_FULFILLED": {
      let searchedScenes = action.payload.data.scenes;
      const sceneIds = state.scenes.map(s => s.id);
      const filteredScenes = searchedScenes.filter(s => sceneIds.indexOf(s) > -1);
      return {
        ...state,
        searchedScenes: filteredScenes.map((id) => { return { id } }),
        selectSearchedScenes: action.meta.selectSearchedScenes,
        loading: false,
        errors: null,
        findingInScript: false
      };
    }

    case "SET_DRESSING_SEARCH_SCRIPT_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false,
        findingInScript: false
      };
    }

    case "CLEAR_SEARCH_SCENES_FOR_SET_DRESSING": {
      return {
        ...state,
        searchedScenes: [],
        loading: false,
        errors: null
      };
    }

    case SET_DRESSING_SET_RETURN_URL: {
      return {
        ...state,
        returnUrl: action.meta.returnUrl
      };
    }

    default:
      return state;
  }
};

export default reducer;
