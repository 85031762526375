import { FETCH_SHOOTING_DAY_MAKEUPS } from "./actions";

const defaultState = {
  makeups: [],
  loading: true,
  readOnly: false,
  errors: null
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case FETCH_SHOOTING_DAY_MAKEUPS + "_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case FETCH_SHOOTING_DAY_MAKEUPS + "_FULFILLED": {
      return {
        ...state,
        makeups: action.payload.data.makeups,
        readOnly: action.payload.data.readOnly,
        loading: false,
        errors: null
      };
    }

    case FETCH_SHOOTING_DAY_MAKEUPS + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
