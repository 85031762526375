import { Link, Prompt } from "react-router-dom";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import Errors from "../../shared/errors";
import {
  renderTextField,
  renderSingleReactSelect,
  renderCkEditorField,
  renderCheckbox,
  renderReactSelect,
  renderMultiReactSelect
} from "../../shared/form-helpers";
import FormErrors from "../../shared/form-errors";
import BoxWraper from "../../account/box-wrapper";

let CostumeForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    errors,
    mode,
    submitFailed,
    invalid,
    users
  } = props;

  const thisFeatureToggle = props.initialValues || {};
  const readOnly = thisFeatureToggle.readOnly;

  const userOptions = users?.map(user => ({ value: user.id, label: user.name + " (" + user.emailAddress + ")" }));
  return (
    <BoxWraper width={7}>
      <Prompt when={!pristine} message="" />
      {mode === "edit" && <h2 className="menu-label">Edit Feature Toggle</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="isEnabled"
              label="Enabled"
              component={renderCheckbox}
              readOnly={readOnly}
            />
            <div className="mt-1">
              <Field
                name="enabledUserIds"
                label="Users"

                component={renderMultiReactSelect}
                readOnly={readOnly}
                options={userOptions}
              />
            </div>


            {!readOnly &&
              <>
                <div className="buttons mt-2">
                  <button
                    type="submit"
                    className="button is-primary"
                    disabled={pristine || submitting}>
                    Save
                  </button>
                </div>
              </>}

            <FormErrors submitFailed={submitFailed} invalid={invalid} />

          </form>
        </div>
      </div>
    </BoxWraper>
  );
}

CostumeForm = reduxForm({
  form: "editCostumeForm",
  enableReinitialize: true
})(CostumeForm);

// Decorate with connect to read form values
const selector = formValueSelector('editCostumeForm'); // <-- same as form name
const CostumeFormConnect = connect(
  state => {
    // can select values individually
    const acquisitionMethodValue = selector(state, 'acquisitionMethod')

    return {
      acquisitionMethodValue
    }
  }
)(CostumeForm)

export default CostumeFormConnect
