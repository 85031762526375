import React from "react";
import { Prompt } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import Errors from "../../../shared/errors";
import {
  renderCheckbox
} from "../../../shared/form-helpers";
import "./shooting-days-equipment.scss";
import CanAccessLink from "../../../shared/can-access-link";

function shootingDayPropsForm(props) {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    saved,
    readOnly
  } = props;
  const { filmId } = props.match.params;
  const thisList = props.initialValues || {};

  if (saved) {
    reset();
  }

  return (
    <div className="shooting-days-equipment">
      <Prompt when={!pristine} message="" />
      {errors && <Errors errors={errors} />}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="">
          <table className="table ">
            <thead>
              <tr>
                <th>Prop</th>
              </tr>
            </thead>
            <tbody>
              <FieldArray
                name="shootingDayProps"
                component={renderProps}
                filmId={filmId}
                readOnly={readOnly} />
            </tbody>
          </table>
        </div>
        <br />
      </form>
    </div>
  );
}

function renderProps({ fields, meta: { error }, readOnly, filmId }) {
  return (
    <>
      {(fields || []).map((equipmentCategory, idx, records) => {
        return (
          <>
            <tr key={idx} >
              <td colSpan={2}>
                <span>
                  <CanAccessLink to={`/films/${filmId}/props/${records.get(idx).id}/edit`} canAccess={true}>{records.get(idx).name}</CanAccessLink>
                </span>
              </td>
            </tr>
          </>
        );
      })}
    </>
  );
}

export default reduxForm({
  form: "shootingDayPropsForm",
  enableReinitialize: true
})(shootingDayPropsForm);
