import getApiClient from "../../../../shared/api";

export const FETCH_SHOOTING_DAY_SET_DRESSINGS = "FETCH_SHOOTING_DAY_SET_DRESSINGS";

export function fetchShootingDaySetDressings(filmId, shootingDayId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_SHOOTING_DAY_SET_DRESSINGS,
      payload: getApiClient().get(
        `/film/${filmId}/ShootingDay/${shootingDayId}/SetDressings`
      )
    });
  };
}