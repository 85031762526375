export const ItemTypes = {
    BACKGROUND_CHARACTER: "BACKGROUND_CHARACTER",
    CHARACTER: "CHARACTER",
    COSTUME: "COSTUME",
    PROP: "PROP",
    MAKEUP: "MAKEUP",
    SET_DRERSSING: "SET_DRERSSING",
    VISUAL_EFFECT: "VISUAL_EFFECT",
    SOUND_EFFECT: "SOUND_EFFECT"
};
