import { combineReducers } from "redux";
import scheduleReducer from "./../schedule/ducks/reducer";
import shotListScheduleReducer from "./../shot-list-schedule/ducks/reducer";
import actorsReducer from "./../actors/ducks/reducer";
import backgroundActorsReducer from "./../background-actors/ducks/reducer";
import crewReducer from "./../crew/ducks/reducer";
import equipmentReducer from "./../equipment/ducks/reducer";
import weatherReducer from "./../weather/ducks/reducer";
import callSheetReducer from "./../call-sheet/ducks/reducer";
import shotListReducer from "./../shot-list/ducks/reducer";
import cateringReducer from "./../catering/ducks/reducer";
import locationsReducer from "./../locations/ducks/reducer";
import scenesReducer from "./../scenes/ducks/reducer";
import settingsReducer from "./../settings/ducks/reducer";
import dayOutOfDaysReducer from "./../day-out-of-days/ducks/reducer";
import riskAssessmentReducer from "./../risk-assessments/ducks/reducer";
import messagesReducer from "./../messages/ducks/reducer";
import propsReducer from "./../props/ducks/reducer";
import setDressingsReducer from "./../set-dressings/ducks/reducer";
import costumesReducer from "./../costumes/ducks/reducer";
import makeupsReducer from "./../makeups/ducks/reducer";
import { UPDATE_SHOOTING_CALENDAR } from "../calendar/ducks/actions";
import { IMPORT_MOVIE_MAGIC } from "../shooting-order/ducks/actions";

const defaultState = {
  shootingdays: [],
  hasDays: false,
  loadingShootingDay: true,
  loadingShootingDayList: true
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_SHOOTINGDAYS_PENDING": {
      return {
        ...state,
        loadingShootingDay: true,
        loadingShootingDayList: true,
        errors: null
      };
    }

    case "FETCH_SHOOTINGDAYS_FULFILLED": {
      return {
        ...state,
        shootingdays: action.payload.data.shootingDays,
        hasDays: action.payload.data.hasDays,
        hasPageLengths: action.payload.data.hasPageLengths,
        readOnly: action.payload.data.readOnly,
        canAccessMessages: action.payload.data.canAccessMessages,
        loadingShootingDay: false,
        loadingShootingDayList: false,
        errors: null
      };
    }

    case "FETCH_SHOOTINGDAYS_REJECTED": {
      return {
        ...state,
        loadingShootingDay: false,
        loadingShootingDayList: false,
        errors: action.payload.response.data.errors
      };
    }

    case UPDATE_SHOOTING_CALENDAR + "_FULFILLED": {
      const days = Array.from(state.shootingdays);
      const returnedDays = action.payload.data.days;

      days.forEach((day: any) => {
        const foundDay = returnedDays.find(d => d.id === day.id);
        if (foundDay) {
          day.date = foundDay.date;
          day.number = foundDay.number;
        }
      });

      sortShootingDays(days);

      return {
        ...state,
        shootingdays: days
      };
    }

    case "ADD_SCENES_SHOOTING_ORDER_DAYS_FULFILLED": {
      return {
        ...state,
        shootingdays: action.payload.data.shootingDays
      };
    }

    case "UPDATE_SHOOTING_CALENDAR_FULFILLED": {
      const shootingdays = action.meta.days.map((d) => {
        return { id: d.id, number: d.number, date: d.start };
      });
      return {
        ...state,
        shootingdays: shootingdays
      };
    }

    case "ADD_SCENES_SHOOTING_ORDER_DAYS_FULFILLED": {
      return {
        ...state,
        hasDays: true
      };
    }

    case "FETCH_SCENES_SHOOTING_ORDER_BY_DAY_FULFILLED": {
      const shootingdays = action.payload.data.days.map((d) => {
        return { id: d.id, number: d.number, date: d.start };
      });
      return {
        ...state,
        shootingdays: shootingdays,
        hasDays: true
      };
    }

    case IMPORT_MOVIE_MAGIC + "_FULFILLED": {
      const shootingdays = action.payload.data.shootingDays.map((d) => {
        return { id: d.id, number: d.number, date: d.date };
      });
      return {
        ...state,
        shootingdays: shootingdays,
        hasDays: true
      };
    }

    case "DELETE_ALL_SHOOTING_DAYS_FULFILLED": {
      return {
        ...state,
        shootingdays: [],
        hasDays: false
      };
    }

    case "FETCH_SINGLE_SHOOTINGDAY_PENDING": {
      return {
        ...state,
        shootingday: null,
        loadingShootingDay: true,
        redirect: null
      };
    }

    case "FETCH_SINGLE_SHOOTINGDAY_FULFILLED": {
      return {
        ...state,
        shootingday: action.payload.data,
        loadingShootingDay: false,
        errors: null,
        redirect: null
      };
    }

    case "UPDATE_SINGLE_SHOOTINGDAY_PENDING": {
      return {
        ...state,
        shootingday: action.payload,
        loadingShootingDay: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_SHOOTINGDAY_FULFILLED": {
      const shootingday = {
        ...action.meta.shootingday,
        ...action.payload.data.shootingday
      };
      const shootingdays: any = state.shootingdays.map(
        (c: any, index: number) => {
          if (c.id === shootingday.id) {
            return { ...c, ...shootingday };
          } else {
            return c;
          }
        }
      );

      sortShootingDays(shootingdays);

      return {
        ...state,
        shootingday,
        shootingdays,
        loadingShootingDay: false,
        errors: null
      };
    }

    case "UPDATE_SINGLE_SHOOTINGDAY_REJECTED": {
      return {
        ...state,
        shootingday: action.meta.shootingday,
        errors: action.payload.response.data.errors,
        loadingShootingDay: false,
        redirect: null
      };
    }

    case "ADD_SINGLE_SHOOTINGDAY_PENDING": {
      return {
        ...state,
        shootingday: action.payload,
        errors: null,
        loadingShootingDay: true,
        redirect: null
      };
    }

    case "ADD_SINGLE_SHOOTINGDAY_FULFILLED": {
      const shootingday: any = action.payload.data.shootingday;

      let shootingdays = [shootingday, ...state.shootingdays];
      sortShootingDays(shootingdays);

      return {
        ...state,
        shootingday,
        shootingdays,
        loadingShootingDay: false,
        errors: null,
        redirect: { to: "schedule", shootingdayId: shootingday.id }
      };
    }

    case "ADD_SINGLE_SHOOTINGDAY_REJECTED": {
      return {
        ...state,
        shootingday: action.meta.shootingday,
        errors: action.payload.response.data.errors,
        loadingShootingDay: false,
        redirect: null
      };
    }

    case "DELETE_SINGLE_SHOOTINGDAY_PENDING": {
      return {
        ...state,
        shootingday: action.payload,
        loadingShootingDay: true,
        errors: null
      };
    }

    case "DELETE_SINGLE_SHOOTINGDAY_FULFILLED": {
      const shootingday = action.meta.shootingday;
      const shootingdays: any = state.shootingdays.filter(
        (c: any, index: number) => {
          return c.id !== shootingday.id;
        }
      );

      return {
        ...state,
        shootingday: null,
        shootingdays,
        loadingShootingDay: false,
        errors: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_SHOOTINGDAY_REJECTED": {
      return {
        ...state,
        shootingday: action.meta.shootingday,
        errors: action.payload.response.data.errors,
        loadingShootingDay: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        shootingday: null,
        redirect: null
      };
    }

    case "SET_SHOOTING_DAY_SCHEDULE_VALID": {
      return {
        ...state,
        shootingday: { ...state.shootingdays, hasValidSchedule: true }
      };
    }

    default:
      return state;
  }
};

function sortShootingDays(shootingdays) {
  shootingdays.sort((a, b) => {
    return new Date(a.date).valueOf() - new Date(b.date).valueOf();
  });
}

export default combineReducers({
  rootState: reducer,
  scheduleState: scheduleReducer,
  shotListScheduleState: shotListScheduleReducer,
  actorsState: actorsReducer,
  backgroundActorsState: backgroundActorsReducer,
  crewState: crewReducer,
  equipmentState: equipmentReducer,
  weatherState: weatherReducer,
  callSheetState: callSheetReducer,
  shotListState: shotListReducer,
  cateringState: cateringReducer,
  locationsState: locationsReducer,
  scenesState: scenesReducer,
  settingsState: settingsReducer,
  dayOutOfDaysState: dayOutOfDaysReducer,
  riskAssessmentState: riskAssessmentReducer,
  messagesState: messagesReducer,
  propsState: propsReducer,
  setDressingsState: setDressingsReducer,
  costumesState: costumesReducer,
  makeupsState: makeupsReducer
});
