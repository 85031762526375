import OnboardingType from "../../../shared/onboarding/onboarding-type";
import { BUGET_SHOW_HIDE_SOUND_EFFECTS, BUGET_SHOW_HIDE_VISUAL_EFFECTS, UPDATE_BUGET_SOUND_EFFECT_BUDGET, UPDATE_BUGET_VISUAL_EFFECT_BUDGET } from "./actions";

interface BudgetState {
  budget: any;
  charactersVisible: boolean;
  sceneSettingsVisible: boolean;
  crewVisible: boolean;
  equipmentVisible: boolean;
  propsVisible: boolean;
  costumesVisible: boolean;
  cateringVisible: boolean;
  download: any;
  onboardingStepsWithShootingDays: OnboardingType[];
  onboardingStepsWithoutShootingDays: OnboardingType[];
  saved: boolean;
  saving: boolean;
  errors: any;
}

export const OnboardingStep1ClassName = 'budget-onboarding-step-1';
export const OnboardingStep2ClassName = 'budget-onboarding-step-2';
export const OnboardingStep3ClassName = 'budget-onboarding-step-3';
export const OnboardingStep4ClassName = 'budget-onboarding-step-4';
export const OnboardingStep5ClassName = 'budget-onboarding-step-5';
export const OnboardingStep6ClassName = 'budget-onboarding-step-6';

const DailyRateContent = `<p>You can change the daily rate here to see how it affects the budget total. Changes will not be saved unless you click Save.</p>
<p>The initial budget is based on the BECTU or Equity rates, based on the film budget you selected when uploading the script.</p>`;

const SectionContent = `You can collapse or expand sections of the budget breakdown to customise the display. The layout will be saved when you save the budget.`;

const ContingencyContent = `Select the amount of contingency (as a percentage of budget) you want to build into the budget.`;

const defaultState: BudgetState = {
  budget: {},
  charactersVisible: true,
  sceneSettingsVisible: true,
  crewVisible: true,
  equipmentVisible: true,
  propsVisible: true,
  costumesVisible: true,
  cateringVisible: true,
  download: null,
  saved: true,
  saving: false,
  errors: null,
  onboardingStepsWithShootingDays: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Budget',
      content: `        
      The budget is based on the number of shooting days you have created, and the days a character, scene setting, prop or costume is required for.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Days',
      content: `<p>
      You can reduce the number of days with the slider to see how it
      affects the budget, but you will need to delete shooting days for
      this to become a reality. When changing the total days, if an item
      is required for less than the total shooting days, it will stay at
      that number of days until the total becomes less than that.
    </p>
    <p>
      You can delete all the shooting days in the shooting days option to
      get back to the estimated budget.
    </p>`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Contingency',
      content: ContingencyContent
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Sections',
      content: SectionContent
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Daily Rate',
      content: DailyRateContent
    }
  ],
  onboardingStepsWithoutShootingDays: [
    {
      target: '.' + OnboardingStep1ClassName,
      title: 'Budget',
      content: `        
      As you have not assigned any shooting days yet, this is a very rough
      budget. The number of days a character, scene setting, prop or
      costume is required is based on the percentage of scenes they appear
      in multiplied by the total number of days.
      `
    },
    {
      target: '.' + OnboardingStep2ClassName,
      title: 'Days',
      content: `The intial number of estimated days is based on the number of pages
      in the script, divided by your setting for the number of pages that
      can be shot in a day. This only works if the script you uploaded was
      from Final Draft, as it is the only format to contain this
      information, otherwise it defaults to 30.`
    },
    {
      target: '.' + OnboardingStep3ClassName,
      title: 'Contingency',
      content: ContingencyContent
    },
    {
      target: '.' + OnboardingStep4ClassName,
      title: 'Sections',
      content: SectionContent
    },
    {
      target: '.' + OnboardingStep5ClassName,
      title: 'Daily Rate',
      content: DailyRateContent
    },
    {
      target: '.' + OnboardingStep6ClassName,
      title: 'Days',
      content: `You can adjust the estimated days for most items to get a more accurate budget.`
    }
  ]
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {

    case "FETCH_SINGLE_FILM_BUDGET_PENDING": {
      return {
        ...state,
        loading: true,
        download: null,
        budget: {}
      };
    }

    case "FETCH_SINGLE_FILM_BUDGET_FULFILLED": {
      return {
        ...state,
        loading: false,
        budget: action.payload.data,
        charactersVisible: action.payload.data.budgetSettings?.charactersVisible ?? true,
        sceneSettingsVisible: action.payload.data.budgetSettings?.sceneSettingsVisible ?? true,
        crewVisible: action.payload.data.budgetSettings?.crewVisible ?? true,
        equipmentVisible: action.payload.data.budgetSettings?.equipmentVisible ?? true,
        propsVisible: (action.payload.data.budgetSettings?.propsVisible ?? true) && action.payload.data.props.length > 0,
        costumesVisible: (action.payload.data.budgetSettings?.costumesVisible ?? true) && action.payload.data.costumes.length > 0,
        makeupsVisible: (action.payload.data.budgetSettings?.makeupsVisible ?? true) && action.payload.data.makeups.length > 0,
        setDressingsVisible: (action.payload.data.budgetSettings?.setDressingsVisible ?? true) && action.payload.data.setDressings.length > 0,
        backgroundCharactersVisible: (action.payload.data.budgetSettings?.backgroundCharactersVisible ?? true) && action.payload.data.backgroundCharacters.length > 0,
        cateringVisible: (action.payload.data.budgetSettings?.cateringVisible ?? true),
        visualEffectsVisible: (action.payload.data.budgetSettings?.visualEffectsVisible ?? true),
        soundEffectsVisible: (action.payload.data.budgetSettings?.soundEffectsVisible ?? true),
        soundEffectsEnabled: action.payload.data.soundEffectsEnabled,
        saved: true
      };
    }

    case "FETCH_SINGLE_FILM_BUDGET_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "UPDATE_BUGET_DAYS": {
      const days = action.meta.days;
      let budget = { ...state.budget }
      budget.days = days;

      let crewTotal = 0;
      budget.departments.forEach(department => {
        let departmentTotal = 0;
        department.crew.forEach(crew => {
          let crewDays;
          crewDays = crew.originalDays ? crew.originalDays < days ? crew.originalDays : days : days;

          crew.days = crewDays;
          crew.total = crew.required ? crew.dailyRate * crewDays : 0;
          crewTotal += crew.total;
          departmentTotal += crew.total;
        });

        department.total = departmentTotal;
      });

      let equipmentTotal = 0;
      budget.equipments.forEach(equipmentCategory => {
        let equipmentCategoryTotal = 0;
        equipmentCategory.equipment.forEach(equipment => {
          let equipmentDays;
          equipmentDays = equipment.originalDays ? equipment.originalDays < days ? equipment.originalDays : days : days;

          equipment.days = equipmentDays;
          calculateEquipmentCost(equipment);
          equipmentTotal += equipment.total;
          equipmentCategoryTotal += equipment.total;
        });

        equipmentCategory.total = equipmentCategoryTotal;
      });

      let characterTotal = 0;
      budget.characters.forEach(character => {
        let characterDays;
        if (budget.hasShootingDays) {
          characterDays = character.shootingDays < days ? character.shootingDays : days;
        } else {
          characterDays = Math.ceil(days * character.numberOfScenes / budget.totalScenes);
        }
        character.days = characterDays;
        character.total = character.dailyBudget * characterDays;
        characterTotal += character.total;
      });

      let propsTotal = 0;
      budget.props.forEach(prop => {
        if (prop.hire) {
          let propDays;
          if (budget.hasShootingDays) {
            propDays = prop.shootingDays < days ? prop.shootingDays : days;
          } else {
            if (prop.hasBudgetDays) {
              propDays = prop.originalDays < days ? prop.originalDays : days;
            } else {
              propDays = Math.ceil(days * prop.numberOfScenes / budget.totalScenes);
            }
          }
          prop.days = propDays;
          prop.total = prop.dailyBudget * prop.quantity * propDays;
          propsTotal += prop.total;
        } else {
          propsTotal += prop.quantity * prop.budget;
        }
      });

      let setDressingsTotal = 0;
      budget.setDressings.forEach(setDressing => {
        if (setDressing.hire) {
          let setDressingDays;
          if (budget.hasShootingDays) {
            setDressingDays = setDressing.shootingDays < days ? setDressing.shootingDays : days;
          } else {
            if (setDressing.hasBudgetDays) {
              setDressingDays = setDressing.originalDays < days ? setDressing.originalDays : days;
            } else {
              setDressingDays = Math.ceil(days * setDressing.numberOfScenes / budget.totalScenes);
            }
          }
          setDressing.days = setDressingDays;
          setDressing.total = setDressing.dailyBudget * setDressing.quantity * setDressingDays;
          setDressingsTotal += setDressing.total;
        } else {
          setDressingsTotal += setDressing.quantity * setDressing.budget;
        }
      });

      let costumesTotal = 0;
      budget.costumes.forEach(costume => {
        if (costume.hire) {
          let costumeDays;
          if (budget.hasShootingDays) {
            costumeDays = costume.shootingDays < days ? costume.shootingDays : days;
          } else {
            if (costume.hasBudgetDays) {
              costumeDays = costume.originalDays < days ? costume.originalDays : days;
            } else {
              costumeDays = Math.ceil(days * costume.numberOfScenes / budget.totalScenes);
            }
          }
          costume.days = costumeDays;
          costume.total = costume.dailyBudget * costume.quantity * costumeDays;
          costumesTotal += costume.total;
        } else {
          costumesTotal += costume.budget * costume.quantity;
        }
      });

      let makeupsTotal = 0;
      budget.makeups.forEach(makeup => {
        let makeupDays;
        if (budget.hasShootingDays) {
          makeupDays = makeup.shootingDays < days ? makeup.shootingDays : days;
        } else {
          if (makeup.hasBudgetDays) {
            makeupDays = makeup.originalDays < days ? makeup.originalDays : days;
          } else {
            makeupDays = Math.ceil(days * makeup.numberOfScenes / budget.totalScenes);
          }
        }
        makeup.days = makeupDays;
        makeup.total = makeup.dailyBudget * makeup.quantity * makeupDays;
        makeupsTotal += makeup.total;
      });

      let backgroundCharactersTotal = 0;
      budget.backgroundCharacters.forEach(backgroundCharacter => {
        let backgroundCharacterDays;
        if (budget.hasShootingDays) {
          backgroundCharacterDays = backgroundCharacter.shootingDays < days ? backgroundCharacter.shootingDays : days;
        } else {
          if (backgroundCharacter.hasBudgetDays) {
            backgroundCharacterDays = backgroundCharacter.originalDays < days ? backgroundCharacter.originalDays : days;
          } else {
            backgroundCharacterDays = Math.ceil(days * backgroundCharacter.numberOfScenes / budget.totalScenes);
          }
        }
        backgroundCharacter.days = backgroundCharacterDays;
        backgroundCharacter.total = backgroundCharacter.dailyBudget * backgroundCharacterDays * backgroundCharacter.quantity;
        backgroundCharactersTotal += backgroundCharacter.total;
      });

      let sceneSettingsTotal = 0;
      budget.sceneSettings.forEach(sceneSetting => {
        let sceneSettingDays;
        if (budget.hasShootingDays) {
          sceneSettingDays = sceneSetting.shootingDays < days ? sceneSetting.shootingDays : days;
        } else {
          if (sceneSetting.hasBudgetDays) {
            sceneSettingDays = sceneSetting.originalDays < days ? sceneSetting.originalDays : days;
          } else {
            sceneSettingDays = Math.ceil(days * sceneSetting.numberOfScenes / budget.totalScenes);
          }
        }

        sceneSetting.days = sceneSettingDays;
        sceneSetting.total = sceneSetting.dailyBudget * sceneSettingDays;
        sceneSettingsTotal += sceneSetting.total;
      });

      recalculateCateringBudget(budget);

      budget.charactersTotal = characterTotal;
      budget.propsTotal = propsTotal;
      budget.setDressingsTotal = setDressingsTotal;
      budget.costumesTotal = costumesTotal;
      budget.sceneSettingsTotal = sceneSettingsTotal;
      budget.crewTotal = crewTotal;
      budget.equipmentTotal = equipmentTotal;
      budget.makeupsTotal = makeupsTotal;
      budget.backgroundCharactersTotal = backgroundCharactersTotal;
      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      }
    }

    case "UPDATE_BUGET_CONTINGENCY_PERCENTAGE": {
      const contingencyPercentage = action.meta.contingencyPercentage;
      let budget = { ...state.budget }
      budget.contingencyPercentage = contingencyPercentage;
      budget.totalBudget = budget.totalExcludingContingency + (Math.round(budget.totalExcludingContingency * budget.contingencyPercentage) / 100);

      return {
        ...state,
        budget,
        saved: false
      }
    }

    case "UPDATE_BUGET_CHARACTER_DAILY_RATE": {
      const characterId = action.meta.characterId;
      const dailyBudget = action.meta.dailyBudget;

      let budget = { ...state.budget };

      var character = findById(budget.characters, characterId);
      character.dailyBudget = dailyBudget;
      character.total = character.dailyBudget * character.days;

      budget.charactersTotal = budget.characters.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_SCENE_SETTING_DAILY_RATE": {
      const sceneSettingId = action.meta.sceneSettingId;
      const dailyBudget = action.meta.dailyBudget ?? 0;

      let budget = { ...state.budget };

      var sceneSetting = findById(budget.sceneSettings, sceneSettingId);
      sceneSetting.dailyBudget = dailyBudget;
      sceneSetting.total = sceneSetting.dailyBudget * sceneSetting.days;

      budget.sceneSettingsTotal = budget.sceneSettings.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_PROP_BUDGET": {
      const propId = action.meta.propId;
      const propBudget = action.meta.budget;

      let budget = { ...state.budget };

      var prop = findById(budget.props, propId);
      if (prop.hire) {
        prop.dailyBudget = propBudget;
        prop.total = prop.dailyBudget * prop.quantity * prop.days;
      } else {
        prop.budget = propBudget;
        prop.total = prop.budget * prop.quantity;
      }

      budget.propsTotal = budget.props.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_PROP_DAYS": {
      const propId = action.meta.propId;
      const days = action.meta.days;

      let budget = { ...state.budget };

      var prop = findById(budget.props, propId);
      prop.days = days;
      prop.originalDays = days;
      prop.hasBudgetDays = true;
      prop.total = prop.days * prop.dailyBudget * prop.quantity;
      budget.propsTotal = budget.props.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_SET_DRESSING_BUDGET": {
      const setDressingId = action.meta.setDressingId;
      const setDressingBudget = action.meta.budget;

      let budget = { ...state.budget };

      var setDressing = findById(budget.setDressings, setDressingId);
      if (setDressing.hire) {
        setDressing.dailyBudget = setDressingBudget;
        setDressing.total = setDressing.dailyBudget * setDressing.quantity * setDressing.days;
      } else {
        setDressing.budget = setDressingBudget;
        setDressing.total = setDressing.budget * setDressing.quantity;
      }

      budget.setDressingsTotal = budget.setDressings.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case UPDATE_BUGET_VISUAL_EFFECT_BUDGET: {
      const visualEffectId = action.meta.visualEffectId;
      const visualEffectBudget = action.meta.budget;

      let budget = { ...state.budget };

      var item = findById(budget.visualEffects, visualEffectId);

      item.budget = visualEffectBudget;
      item.total = item.budget;

      budget.visualEffectsTotal = budget.visualEffects.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case UPDATE_BUGET_SOUND_EFFECT_BUDGET: {
      const itemId = action.meta.itemId;
      const itemBudget = action.meta.budget;

      let budget = { ...state.budget };

      var item = findById(budget.soundEffects, itemId);

      item.budget = itemBudget;
      item.total = item.budget;

      budget.soundEffectsTotal = budget.soundEffects.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_SET_DRESSING_DAYS": {
      const setDressingId = action.meta.setDressingId;
      const days = action.meta.days;

      let budget = { ...state.budget };

      var setDressing = findById(budget.setDressings, setDressingId);
      setDressing.days = days;
      setDressing.originalDays = days;
      setDressing.hasBudgetDays = true;
      setDressing.total = setDressing.days * setDressing.dailyBudget * setDressing.quantity;
      budget.setDressingsTotal = budget.setDressings.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_SCENE_SETTING_DAYS": {
      const sceneSettingId = action.meta.sceneSettingId;
      const days = action.meta.days;

      let budget = { ...state.budget };

      var sceneSetting = findById(budget.sceneSettings, sceneSettingId);
      sceneSetting.days = days;
      sceneSetting.originalDays = days;
      sceneSetting.hasBudgetDays = true;
      sceneSetting.total = sceneSetting.days * sceneSetting.dailyBudget;
      budget.sceneSettingsTotal = budget.sceneSettings.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_COSTUME_BUDGET": {
      const costumeId = action.meta.costumeId;
      const costumeBudget = action.meta.budget;

      let budget = { ...state.budget };

      var costume = findById(budget.costumes, costumeId);
      if (costume.hire) {
        costume.dailyBudget = costumeBudget;
        costume.total = costume.dailyBudget * costume.quantity * costume.days;
      } else {
        costume.budget = costumeBudget;
        costume.total = costume.budget * costume.quantity;
      }

      budget.costumesTotal = budget.costumes.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_COSTUME_DAYS": {
      const costumeId = action.meta.costumeId;
      const days = action.meta.days;

      let budget = { ...state.budget };

      var costume = findById(budget.costumes, costumeId);
      costume.days = days;
      costume.originalDays = days;
      costume.hasBudgetDays = true;
      costume.total = costume.days * costume.quantity * costume.dailyBudget;
      budget.costumesTotal = budget.costumes.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_MAKEUP_BUDGET": {
      const makeupId = action.meta.makeupId;
      const makeupBudget = action.meta.budget;

      let budget = { ...state.budget };

      var catering = findById(budget.makeups, makeupId);
      catering.dailyBudget = makeupBudget;
      catering.total = catering.dailyBudget * catering.quantity * catering.days;

      budget.makeupsTotal = budget.makeups.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_MAKEUP_DAYS": {
      const makeupId = action.meta.makeupId;
      const days = action.meta.days;

      let budget = { ...state.budget };

      var catering = findById(budget.makeups, makeupId);
      catering.days = days;
      catering.originalDays = days;
      catering.hasBudgetDays = true;
      catering.total = catering.days * catering.quantity * catering.dailyBudget;
      budget.cateringTotal = catering.total;

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_CATERING_BUDGET": {
      let cateringBudget = action.meta.budget;
      if (cateringBudget === null || cateringBudget === undefined) {
        cateringBudget = 0;
      }

      let budget = { ...state.budget };
      var catering = budget.catering;
      catering.budget = cateringBudget;
      recalculateCateringBudget(budget);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_CATERING_DAYS": {

      const days = action.meta.days;
      let budget = { ...state.budget };

      var catering = budget.catering;
      catering.days = days;
      catering.originalDays = days;
      catering.hasBudgetDays = true;
      catering.total = catering.budget * catering.quantity * catering.days;
      budget.cateringTotal = catering.total;

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_BACKGROUND_CHARACTER_BUDGET": {
      const backgroundCharacterId = action.meta.backgroundCharacterId;
      const backgroundCharacterBudget = action.meta.budget;

      let budget = { ...state.budget };

      var backgroundCharacter = findById(budget.backgroundCharacters, backgroundCharacterId);
      backgroundCharacter.dailyBudget = backgroundCharacterBudget;
      backgroundCharacter.total = backgroundCharacter.dailyBudget * backgroundCharacter.days * backgroundCharacter.quantity;

      budget.backgroundCharactersTotal = budget.backgroundCharacters.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_BACKGROUND_CHARACTER_DAYS": {
      const backgroundCharacterId = action.meta.backgroundCharacterId;
      const days = action.meta.days;

      let budget = { ...state.budget };

      var backgroundCharacter = findById(budget.backgroundCharacters, backgroundCharacterId);
      backgroundCharacter.days = days;
      backgroundCharacter.originalDays = days;
      backgroundCharacter.hasBudgetDays = true;
      backgroundCharacter.total = backgroundCharacter.days * backgroundCharacter.dailyBudget * backgroundCharacter.quantity;
      budget.backgroundCharactersTotal = budget.backgroundCharacters.reduce((a, b) => {
        return a + b.total;
      }, 0);

      recalculateCateringBudget(budget);
      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_CREW_REQUIRED": {
      const roleId = action.meta.roleId;
      const required = action.meta.required;
      const departmentId = action.meta.departmentId;

      let budget = { ...state.budget };

      var department = findById(budget, departmentId);
      var role = findById(budget, roleId);
      role.required = required;

      let crewQuantity = 0;
      budget.departments.forEach((department: any) => {
        department.crew.forEach(crew => {
          if (crew.required) {
            crewQuantity++;
          }
        });
      });

      recalculateCateringBudget(budget);

      role.total = role.required ? role.days * role.dailyRate : 0;

      department.total = department.crew.reduce((a, b) => {
        return a + b.total;
      }, 0);

      budget.crewTotal = budget.departments.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_CREW_DAYS": {
      const roleId = action.meta.roleId;
      const days = action.meta.days;
      const prepDays = action.meta.prepDays ?? 0;
      const departmentId = action.meta.departmentId;

      let budget = { ...state.budget };

      var department = findById(budget, departmentId);
      var role = findById(budget, roleId);
      role.days = days;

      role.days = days;
      role.originalDays = days;
      role.hasBudgetDays = true;

      role.total = role.required ? (role.days + prepDays) * role.dailyRate : 0;

      department.total = department.crew.reduce((a, b) => {
        return a + b.total;
      }, 0);

      budget.crewTotal = budget.departments.reduce((a, b) => {
        return a + b.total;
      }, 0);

      recalculateCateringBudget(budget);
      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_CREW_DAILY_RATE": {
      const departmentId = action.meta.departmentId;
      const roleId = action.meta.roleId;
      const dailyRate = action.meta.dailyRate;

      let budget = { ...state.budget };

      var department = findById(budget, departmentId);
      var role = findById(budget, roleId);
      role.dailyRate = dailyRate;
      const prepDays = role.prepDays ?? 0;
      role.total = role.dailyRate * (role.days + prepDays);

      department.total = department.crew.reduce((a, b) => {
        return a + b.total;
      }, 0);

      budget.crewTotal = budget.departments.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_EQUIPMENT_REQUIRED": {
      const equipmentId = action.meta.equipmentId;
      const required = action.meta.required;
      const categoryId = action.meta.categoryId;

      let budget = { ...state.budget };

      var category = findById(budget, categoryId);
      var equipment = findById(budget, equipmentId);
      equipment.required = required;
      calculateEquipmentCost(equipment);

      category.total = category.equipment.reduce((a, b) => {
        return a + b.total;
      }, 0);

      budget.equipmentTotal = budget.equipments.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_EQUIPMENT_DAYS": {
      const equipmentId = action.meta.equipmentId;
      const days = action.meta.days;
      const categoryId = action.meta.categoryId;

      let budget = { ...state.budget };

      var category = findById(budget, categoryId);
      var equipment = findById(budget, equipmentId);
      equipment.days = days;

      equipment.days = days;
      equipment.originalDays = days;
      equipment.hasBudgetDays = true;

      calculateEquipmentCost(equipment);

      category.total = category.equipment.reduce((a, b) => {
        return a + b.total;
      }, 0);

      budget.equipmentTotal = budget.equipments.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_BUGET_EQUIPMENT_DAILY_RATE": {
      const categoryId = action.meta.categoryId;
      const equipmentId = action.meta.equipmentId;
      const dailyRate = action.meta.dailyRate;

      let budget = { ...state.budget };

      var category = findById(budget, categoryId);
      var equipment = findById(budget, equipmentId);

      if (equipment.acquisitionMethod == 3) {
        equipment.dailyRate = dailyRate;
      } else {
        equipment.buyBudget = dailyRate;
      }

      equipment.dailyRate = dailyRate;

      calculateEquipmentCost(equipment);

      category.total = category.equipment.reduce((a, b) => {
        return a + b.total;
      }, 0);

      budget.equipmentTotal = budget.equipments.reduce((a, b) => {
        return a + b.total;
      }, 0);

      calculateTotal(budget);

      return {
        ...state,
        budget,
        saved: false
      };
    }

    case "UPDATE_SINGLE_FILM_BUDGET_PENDING": {
      return {
        ...state,
        saving: true
      }
    }

    case "UPDATE_SINGLE_FILM_BUDGET_FULFILLED": {
      return {
        ...state,
        saving: false,
        saved: true
      }
    }

    case "UPDATE_SINGLE_FILM_BUDGET_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        saving: false
      }
    }

    case "BUGET_SHOW_HIDE_CHARACTERS": {
      return {
        ...state,
        charactersVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_SCENE_SETTINGS": {
      return {
        ...state,
        sceneSettingsVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_CREW": {
      return {
        ...state,
        crewVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_EQUIPMENT": {
      return {
        ...state,
        equipmentVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_PROPS": {
      return {
        ...state,
        propsVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_COSTUMES": {
      return {
        ...state,
        costumesVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_MAKEUPS": {
      return {
        ...state,
        makeupsVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_SET_DRESSINGS": {
      return {
        ...state,
        setDressingsVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_BACKGROUND_CHARACTERS": {
      return {
        ...state,
        backgroundCharactersVisible: action.meta.show,
        saved: false
      }
    }

    case "BUGET_SHOW_HIDE_CATERING": {
      return {
        ...state,
        cateringVisible: action.meta.show,
        saved: false
      }
    }

    case BUGET_SHOW_HIDE_VISUAL_EFFECTS: {
      return {
        ...state,
        visualEffectsVisible: action.meta.show,
        saved: false
      }
    }

    case BUGET_SHOW_HIDE_SOUND_EFFECTS: {
      return {
        ...state,
        soundEffectsVisible: action.meta.show,
        saved: false
      }
    }

    case "DOWNLOAD_BUDGET_PENDING": {
      return {
        ...state,
        loading: false,
        progress: { progress: null, message: 'Queued for generating PDF' }
      };
    }

    case "DOWNLOAD_BUDGET_FULFILLED": {
      return state;
    }

    case "DOWNLOAD_BUDGET_COMPLETE": {
      return {
        ...state,
        loading: false,
        progress: null,
        download: { url: action.meta.url, fileName: action.meta.fileName, downloaded: false }
      };
    }

    case "CLEAR_DOWNLOAD_BUDGET": {
      var download = { ...state.download, downloaded: true }
      return {
        ...state,
        download: download
      };
    }

    case "DOWNLOAD_BUDGET_PROGRESS": {
      return {
        ...state,
        progress: action.meta.progress
      };
    }

    case "DOWNLOAD_BUDGET_ERRORED": {
      return {
        ...state,
        progress: null,
        errors: action.meta.errors
      };
    }

    default:
      return state;
  }
};

const calculateEquipmentCost = (equipment: any) => {
  if (!equipment.required || equipment.acquisitionMethod == 4) { // Own
    equipment.total = 0;
    return;
  }

  if (equipment.acquisitionMethod == 1) { // Buy
    equipment.total = equipment.buyBudget;
    return;
  }

  if (equipment.days < 3) {
    equipment.total = equipment.dailyRate * equipment.days;
  } else if (equipment.days <= 7) {
    if (equipment.threeDayRate) {
      equipment.total = equipment.threeDayRate;
    }
    else {
      equipment.total = equipment.dailyRate * equipment.days;
    }
  } else if (equipment.days <= 14) {
    if (equipment.weeklyRate) {
      equipment.total = equipment.weeklyRate;
    }
    else if (equipment.threeDayRate) {
      var threeDayMultiple = Math.floor(equipment.days / 3);
      equipment.total = equipment.threeDayRate * threeDayMultiple;
    }
    else {
      equipment.Total = equipment.DailyRate * equipment.Days;
    }
  } else {
    if (equipment.twoWeeklyRate) {
      var weeks = Math.floor(equipment.days / 7);
      var twoWeeks = Math.floor(weeks / 2);
      equipment.total = equipment.twoWeeklyRate * twoWeeks;
    }
    else if (equipment.eeeklyRate) {
      var weeks = Math.floor(equipment.days / 7);
      equipment.total = equipment.weeklyRate * weeks;
    }
    else if (equipment.threeDayRate) {
      var threeDayMultiple = Math.floor(equipment.days / 3);
      equipment.total = equipment.threeDayRate * threeDayMultiple;
    }
    else {
      equipment.total = equipment.dailyRate * equipment.days;
    }
  }

  if (equipment.deliveryAndCollectionCost) {
    equipment.total += equipment.deliveryAndCollectionCost;
  }
};

const recalculateCateringBudget = (budget) => {
  const catering = budget.catering;
  if (!budget.hasShootingDays) {
    let mealDays = 0;
    budget.departments.forEach((department: any) => {
      department.crew.forEach(crew => {
        if (crew.required) {
          mealDays += crew.days;
        }
      });
    });

    budget.characters.forEach((character) => {
      mealDays += character.days;
    });

    budget.backgroundCharacters.forEach((backgroundCharacter) => {
      mealDays += backgroundCharacter.quantity * backgroundCharacter.days;
    });

    catering.mealDays = mealDays;
  }

  catering.total = catering.budget * catering.mealDays;
  budget.cateringTotal = catering.total;
};

const calculateTotal = (budget) => {
  const total = budget.crewTotal + budget.charactersTotal + budget.propsTotal + budget.setDressingsTotal + budget.costumesTotal + budget.sceneSettingsTotal + budget.makeupsTotal + budget.backgroundCharactersTotal + (budget.catering?.total ?? 0) + budget.equipmentTotal + budget.visualEffectsTotal + budget.soundEffectsTotal;
  budget.totalExcludingContingency = total;
  budget.totalBudget = total + (Math.round(total * budget.contingencyPercentage) / 100);
};

function findById(obj, id) {
  var result;
  for (var p in obj) {
    if (obj.id === id) {
      return obj;
    } else {
      if (typeof obj[p] === 'object') {
        result = findById(obj[p], id);
        if (result) {
          return result;
        }
      }
    }
  }
  return result;
}

export default reducer;
