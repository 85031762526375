import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./../../../shared/loader";
import { fetchVisualEffectsInScene } from "./ducks/actions";
import List from "./list";
import AddIcon from "../../icons/add";
import { Link } from "react-router-dom";
import { stat } from "fs";

function ListPropsContainer(props) {
  const { filmId, sceneId } = props.match.params;
  const { fetchVisualEffectsInScene, loading, sceneVisualEffects, errors, readOnly } = props;

  useEffect(() => {
    fetchVisualEffectsInScene(filmId, sceneId);
  }, [filmId, sceneId]);

  function renderLoading() {
    return <Loader />;
  }

  function renderVisualEffects(visualEffects, filmId, sceneId) {
    return (
      <div className="blade-menu">
        <List visualEffects={visualEffects} filmId={filmId} sceneId={sceneId} />
        {!readOnly && !window.location.pathname.endsWith("add") && <Link
          to={`/films/${filmId}/scenes/${sceneId}/visual-effects/add`}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Assign Existing
        </Link>}
        {!readOnly && <Link
          to={{ pathname: `/films/${filmId}/visual-effects/add`, state: { sceneId, returnUrl: `/films/${filmId}/scenes/${sceneId}/visual-effects` } }}
          className="button is-transparent is-add">
          <AddIcon className="white-icon" />
          Add New
        </Link>}
      </div>
    );
  }

  return loading
    ? renderLoading()
    : renderVisualEffects(sceneVisualEffects, filmId, sceneId);
}

function mapStateToProps(state) {
  const { visualEffectState } = state.scenesState;
  return {
    sceneVisualEffects: visualEffectState.visualEffects,
    loading: visualEffectState.loading,
    errors: visualEffectState.errors,
    readOnly: visualEffectState.readOnly
  };
}

export default connect(mapStateToProps, {
  fetchVisualEffectsInScene
})(ListPropsContainer);
